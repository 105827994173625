import { getClient, getResponseData } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function postInvite(workspace_id, email, role_ids) {
  const res = await client.post(`/${workspace_id}/invites/`, {
    email,
    role_ids
  })
  return getResponseData(res)
}

export async function getInvites(workspace_id) {
  const res = await client.get(`/${workspace_id}/invites/`)
  return getResponseData(res)
}

export async function deleteInvite(workspace_id, inviteId) {
  await client.delete(`/${workspace_id}/invites/${inviteId}/`)
}
