var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "members" },
    [
      _c(
        "p",
        { staticClass: "members-header" },
        [
          _c("span", { staticClass: "members-header-title" }, [
            _vm._v(" Members ")
          ]),
          _c("Button", {
            attrs: { text: "Invite members" },
            on: { click: _vm.openInviteModal }
          })
        ],
        1
      ),
      _c("div", { staticClass: "members-search-wrapper" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.memberQuery,
              expression: "memberQuery"
            }
          ],
          staticClass: "members-search",
          attrs: { type: "text", placeholder: "Search..." },
          domProps: { value: _vm.memberQuery },
          on: {
            keyup: function($event) {
              return _vm.handleSearch()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.memberQuery = $event.target.value
            }
          }
        }),
        _vm.memberQuery
          ? _c("img", {
              staticClass: "members-search-clear",
              attrs: { src: require("@/assets/icons/close.svg"), alt: "" },
              on: { click: _vm.handleClear }
            })
          : _vm._e()
      ]),
      _c(
        "b-tabs",
        {
          staticClass: "members-tabs",
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        _vm._l(_vm.tabs, function(tab) {
          return _c(
            "b-tab-item",
            {
              key: tab.key,
              attrs: { value: tab.key },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "members-tabs-tab-label" }, [
                          _vm._v(
                            " " +
                              _vm._s(tab.name) +
                              " (" +
                              _vm._s(_vm.filteredCountsByTab[tab.key]) +
                              ") "
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _c(
                "div",
                { staticClass: "members-tabs-tab" },
                [
                  tab.key === "active"
                    ? _c("MembersTable", {
                        attrs: {
                          "amount-of-members": _vm.totalCountsByTab[tab.key],
                          data: _vm.membersByTab[tab.key],
                          loading: _vm.loadingMembers,
                          workspace: _vm.currentWorkspace
                        },
                        on: {
                          loadMore: _vm.handleLoadMore,
                          removeMember: _vm.handleRemoveMember,
                          updateRoles: _vm.updateRoles,
                          filter: _vm.handleFilter
                        }
                      })
                    : _c("PendingEmailInvites", {
                        attrs: { invites: _vm.membersByTab[tab.key] },
                        on: {
                          updateInvite: _vm.updateInvite,
                          deleteInvite: _vm.deleteInvite
                        }
                      })
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.inviteModalOpen,
            callback: function($$v) {
              _vm.inviteModalOpen = $$v
            },
            expression: "inviteModalOpen"
          }
        },
        [
          _c("WorkspaceMemberInviteModal", {
            on: { close: _vm.closeInviteModal, sendInvites: _vm.sendInvites }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }