var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.template
    ? _c(
        "b-modal",
        {
          attrs: { active: "" },
          on: {
            close: function() {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { staticClass: "template-inspect" }, [
            _c(
              "div",
              { staticClass: "template-inspect-header" },
              [
                _c("p", { staticClass: "template-inspect-header-title" }, [
                  _vm._v(_vm._s(_vm.template.name))
                ]),
                _c("Button", {
                  attrs: { icon: "close", size: "xs", type: "grey" },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "template-inspect-content" },
              [
                _vm.template.description
                  ? _c(
                      "p",
                      { staticClass: "template-inspect-content-description" },
                      [_vm._v(" " + _vm._s(_vm.template.description) + " ")]
                    )
                  : _vm._e(),
                _vm.hasContent && _vm.fullTemplate
                  ? _c("TextArea", {
                      attrs: {
                        value: _vm.fullTemplate.content,
                        disabled: true,
                        "min-height": "15vh",
                        "max-height": "40vh"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasContent
                  ? _c(
                      "div",
                      { staticClass: "template-inspect-presentation" },
                      [_vm._v(" No prompt available for a presentation. ")]
                    )
                  : _vm._e(),
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "template-inspect-loading" },
                      [
                        _c("b-loading", {
                          attrs: { active: _vm.loading, "is-full-page": false }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "template-inspect-footer" },
              [
                _c("Button", {
                  attrs: { text: "Close", type: "white" },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _vm.isCore
                  ? _c("Button", {
                      attrs: {
                        text: "Duplicate",
                        icon: "copy-medium",
                        disabled: !_vm.hasContent,
                        "icon-left": true
                      },
                      on: {
                        click: function() {
                          return _vm.$emit("duplicate")
                        }
                      }
                    })
                  : _c("Button", {
                      attrs: { text: "Edit", icon: "edit", "icon-left": true },
                      on: {
                        click: function() {
                          return _vm.$emit("edit")
                        }
                      }
                    })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }