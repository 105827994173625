var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-actions" },
    [
      !_vm.loading
        ? _c("Dropdown", {
            attrs: { items: _vm.dropdownItems, "position-fixed": true },
            scopedSlots: _vm._u(
              [
                {
                  key: "trigger",
                  fn: function() {
                    return [
                      _c("Button", {
                        attrs: { icon: "kebab", type: "grey", size: "xs" }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              645845969
            )
          })
        : _c("div", { staticClass: "template-actions-loading" }, [
            _c("img", {
              staticClass: "template-actions-loading-icon",
              attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
            })
          ]),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.modal === "delete" },
          on: {
            close: function() {
              return (_vm.modal = "")
            }
          }
        },
        [
          _c("div", { staticClass: "confirm-remove" }, [
            _c("div", { staticClass: "confirm-remove-header" }, [
              _vm._v("Remove template")
            ]),
            _c("div", { staticClass: "confirm-remove-body" }, [
              _c("p", { staticClass: "confirm-remove-body-header" }, [
                _vm._v(' Remove "' + _vm._s(_vm.template.name) + '"? ')
              ]),
              _c(
                "div",
                { staticClass: "confirm-remove-body-text-wrapper" },
                [
                  _c("p", { staticClass: "confirm-remove-body-text" }, [
                    _vm._v(
                      " Are you sure you want to remove this template? This action cannot be undone. "
                    )
                  ]),
                  _vm.published
                    ? _c(
                        "p",
                        { staticClass: "confirm-remove-body-text warning" },
                        [
                          _vm._v(
                            " Removing this template will also remove the content it has generated in your company portfolio. "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.published
                    ? _c("p", { staticClass: "confirm-remove-body-text" }, [
                        _vm._v(
                          " To confirm this action, please type the name of the template below. "
                        )
                      ])
                    : _vm._e(),
                  _vm.published
                    ? _c("TextInput", {
                        attrs: {
                          placeholder:
                            "Type the template name to confirm removal"
                        },
                        model: {
                          value: _vm.confirmRemove,
                          callback: function($$v) {
                            _vm.confirmRemove = $$v
                          },
                          expression: "confirmRemove"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "confirm-remove-btns" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function() {
                      return (_vm.modal = "")
                    }
                  }
                }),
                _c("Button", {
                  attrs: {
                    text: "Remove template",
                    loading: _vm.loading,
                    disabled: _vm.confirmDisabled
                  },
                  on: { click: _vm.removeTemplate }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }