<template>
  <div class="settings-wrapper">
    <div
      class="settings"
      :class="{ padded: !$route.meta.noscroll, 'sidebar-visible': showSidebar }"
    >
      <div v-if="showSidebar" class="settings-sidebar">
        <div class="settings-sidebar-btns">
          <router-link
            v-for="tab in tabs"
            :key="tab.key"
            :to="tabRoute(tab.key)"
          >
            <p
              class="settings-sidebar-btns-btn"
              :class="{ active: currentTab === tab.key }"
            >
              <img
                :src="require(`@/assets/icons/${tab.icon}.svg`)"
                alt=""
                class="settings-sidebar-btns-btn-icon"
              />
              <span class="settings-sidebar-btns-btn-text">{{ tab.name }}</span>
            </p>
          </router-link>
        </div>
      </div>
      <div
        ref="settingscontent"
        class="settings-content"
        :class="{ overflows: showSidebar }"
        :style="showSidebar ? maxHeightStyle : {}"
      >
        <component :is="settingsComponent" />
      </div>
    </div>
    <ResourceModal>
      <template #subHeader="{ resource }">
        <PortfolioResourceModalHeader :resource="resource" />
      </template>
    </ResourceModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WorkspaceInfoSettings from './settings/WorkspaceInfoSettings.vue'
import WorkspaceMemberSettings from './settings/WorkspaceMemberSettings.vue'
import WorkspaceIntegrationSettings from './settings/WorkspaceIntegrationSettings.vue'
import WorkspaceProspectingSettings from './settings/WorkspaceProspectingSettings.vue'
import WorkspaceTemplates from './settings/WorkspaceTemplates.vue'
import WorkspaceSalesKits from './settings/WorkspaceSalesKits.vue'
import WorkspacePortfolioTemplates from './settings/WorkspacePortfolioTemplates.vue'
import ResourceModal from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceModal.vue'
import PortfolioResourceModalHeader from '@/views-v2/portfolio/PortfolioResourceModalHeader.vue'
import { setRoutePadding } from '@/util'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'WorkspaceSettings',
  components: {
    ResourceModal,
    PortfolioResourceModalHeader
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    currentTab: 'general'
  }),
  computed: {
    ...mapGetters(['settingsTabs']),
    tabs() {
      const tabInfo = {
        general: {
          name: 'General settings',
          key: 'general',
          icon: 'globe'
        },
        members: {
          name: 'Members',
          key: 'members',
          icon: 'users'
        },
        apps: {
          name: 'Apps',
          key: 'apps',
          icon: 'apps'
        },
        prospecting: {
          name: 'Prospecting',
          key: 'prospecting',
          icon: 'prospect'
        },
        portfolio_templates: {
          name: 'Portfolio templates',
          key: 'portfolio_templates',
          icon: 'compass'
        },
        meeting_templates: {
          name: 'Meeting templates',
          key: 'meeting_templates',
          icon: 'template'
        },
        deal_stages: {
          name: 'Deal stages',
          key: 'deal_stages',
          icon: 'briefcase'
        }
      }
      return this.settingsTabs.map((tab) => tabInfo[tab])
    },
    routeTabs() {
      let tabs = this.$route.params.tab
      return Array.isArray(tabs) ? tabs : tabs?.split('/') || []
    },
    showSidebar() {
      if (
        !['portfolio_templates', 'meeting_templates'].includes(this.currentTab)
      )
        return true
      return this.routeTabs.length < 3
    },
    settingsComponent() {
      return {
        general: WorkspaceInfoSettings,
        members: WorkspaceMemberSettings,
        apps: WorkspaceIntegrationSettings,
        prospecting: WorkspaceProspectingSettings,
        portfolio_templates: WorkspacePortfolioTemplates,
        meeting_templates: WorkspaceTemplates,
        deal_stages: WorkspaceSalesKits
      }[this.currentTab]
    }
  },
  watch: {
    '$route.params.tab'() {
      this.checkRouteTab()
    }
  },
  created() {
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
    this.checkRouteTab()
    setRoutePadding(this.$router, this.$route, true)
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.settingscontent
    },
    checkRouteTab() {
      if (this.tabs.length === 0) {
        this.$router.push({
          name: 'home',
          params: {
            workspace_id: this.$route.params.workspace_id
          }
        })
      }
      if (this.settingsTabs.includes(this.routeTabs[0])) {
        this.currentTab = this.routeTabs[0]
      } else {
        this.$router.push({
          name: 'ws-settings',
          params: {
            workspace_id: this.$route.params.workspace_id,
            tab: [this.tabs[0].key]
          }
        })
      }
    },
    tabRoute(tab) {
      return {
        name: 'ws-settings',
        params: {
          workspace_id: this.$route.params.workspace_id,
          tab: [tab]
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.settings {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  &.padded {
    padding-top: 2rem;
  }

  &.sidebar-visible {
    grid-template-columns: 1fr 4fr;
  }

  &-sidebar {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-header {
      font-size: 1.2rem;
      font-weight: 700;
      color: #303032;
    }

    &-btns {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;

      &-btn {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        width: 100%;
        border-radius: 4px;
        cursor: pointer;

        &.active {
          background: #ebf1ff;

          & > .settings-sidebar-btns-btn-icon {
            filter: invert(23%) sepia(77%) saturate(3306%) hue-rotate(218deg)
              brightness(99%) contrast(98%);
          }

          & > .settings-sidebar-btns-btn-text {
            color: #115efb;
          }
        }

        &-icon {
          height: 1.2rem;
        }

        &-text {
          color: #60666b;
          user-select: none;
        }
      }
    }
  }

  &-content {
    &.overflows {
      padding: 0 2.5rem;
      overflow-y: auto;
    }
  }
}
</style>
