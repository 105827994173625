var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "templates" },
    [
      !_vm.isEdit
        ? _c(
            "div",
            { staticClass: "templates-header" },
            [
              _c("p", { staticClass: "templates-header-title" }, [
                _vm._v("Meeting templates")
              ]),
              _c("Button", {
                attrs: { text: "Add template" },
                on: { click: _vm.createTemplate }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isEdit
        ? _c("div", { staticClass: "templates-content" }, [
            _c(
              "div",
              { staticClass: "templates-content-top" },
              [
                _c("TextInput", {
                  staticClass: "templates-content-search",
                  attrs: {
                    "left-icon": "search",
                    placeholder: "Search...",
                    disabled: !(_vm.templates && _vm.templates.length)
                  },
                  model: {
                    value: _vm.query,
                    callback: function($$v) {
                      _vm.query = $$v
                    },
                    expression: "query"
                  }
                }),
                _vm._l(_vm.filterOptions, function(filter) {
                  return _c("Button", {
                    key: filter.value,
                    attrs: {
                      text: filter.text,
                      disabled: filter.disabled,
                      type:
                        _vm.statusFilter === filter.value
                          ? "light-solid"
                          : "white"
                    },
                    on: {
                      click: function($event) {
                        _vm.statusFilter = filter.value
                      }
                    }
                  })
                })
              ],
              2
            ),
            _c(
              "div",
              {
                ref: "templateslist",
                staticClass: "templates-content-list",
                style: _vm.maxHeightStyle
              },
              [
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "templates-content-loading" },
                      [
                        _c("b-loading", {
                          attrs: { active: "", "is-full-page": false }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "templates-content-list-grid" },
                  _vm._l(_vm.filteredTemplates, function(template) {
                    return _c("TemplateCard", {
                      key: template.uuid,
                      attrs: { template: template, "show-status": true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "action",
                            fn: function() {
                              return [
                                _c("WorkspaceTemplateActions", {
                                  staticClass: "templates-table-item-actions",
                                  attrs: {
                                    template: template,
                                    "is-core": template.provider === "core"
                                  },
                                  on: {
                                    duplicate: _vm.handleDuplicateTemplate,
                                    delete: _vm.handleDeleteTemplate,
                                    publish: _vm.handlePublishTemplate,
                                    edit: _vm.handleEditTemplate
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  1
                )
              ]
            )
          ])
        : _c("EditPitchTemplate"),
      _vm.modal === "create"
        ? _c("CreateTemplate", {
            attrs: { visible: true, type: "story" },
            on: { close: _vm.closeModal, created: _vm.handleCreated }
          })
        : _vm._e(),
      _vm.inspect
        ? _c("WorkspaceTemplateInspect", {
            attrs: { template: _vm.inspect },
            on: {
              close: function($event) {
                _vm.inspect = undefined
              },
              duplicate: function($event) {
                return _vm.actionFromInspect("duplicate")
              },
              edit: function($event) {
                return _vm.actionFromInspect("edit")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }