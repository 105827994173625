var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.header.id === "name"
    ? _c("div", { staticClass: "template-item-name" }, [
        _c("img", {
          staticClass: "template-item-name-icon",
          attrs: {
            src: require("@/assets/file_type/" +
              (_vm.template.file_type === "pptx" ? "powerpoint" : "word") +
              ".svg"),
            alt: ""
          }
        }),
        _vm._v(" " + _vm._s(_vm.header.key(_vm.template)) + " ")
      ])
    : _vm.header.id === "status"
    ? _c("StatusOrb", {
        attrs: {
          type:
            _vm.header.key(_vm.template) === "published" ? "green" : "orange",
          text: _vm.header.key(_vm.template).capitalize(),
          "show-text": true
        }
      })
    : _vm.header.id === "content"
    ? _c(
        "div",
        { staticClass: "template-item-content" },
        [
          _vm.template.status === "published"
            ? _c("Button", {
                attrs: {
                  text: _vm.template.files_count + " " + _vm.oriTypeName + "s",
                  icon: "arrow-right",
                  type: "light-solid"
                },
                on: {
                  click: function() {
                    return _vm.$emit("content", _vm.template)
                  }
                }
              })
            : _c("span", [_vm._v("-")])
        ],
        1
      )
    : _vm.tab.key !== "default" && _vm.header.id === "actions"
    ? _c(
        "PortfolioTemplateActions",
        _vm._g(
          {
            staticClass: "template-item-actions",
            attrs: { template: _vm.template }
          },
          _vm.$listeners
        )
      )
    : _c("p", { staticClass: "template-item-default" }, [
        _vm._v(" " + _vm._s(_vm.header.key(_vm.template)) + " ")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }