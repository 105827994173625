var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "template-slides-add",
      on: {
        click: function() {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "template-slides-add-line" }),
      _vm._m(0),
      _c("div", { staticClass: "template-slides-add-line" })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "template-slides-add-icon" }, [
      _c("img", {
        staticClass: "template-slides-add-icon-img",
        attrs: { src: require("@/assets/icons/plus-medium.svg"), alt: "" }
      }),
      _c("p", { staticClass: "template-slides-add-icon-text" }, [
        _vm._v("Insert portfolio templates")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }