<template>
  <div class="story-template">
    <EditPresentationTemplate
      :template-id="templateUuid"
      :is-edit="isEdit"
      @update="(t) => $emit('update', t)"
    />
  </div>
</template>

<script>
import { setRoutePadding } from '@/util'
import EditPresentationTemplate from '../templates/EditPresentationTemplate.vue'

export default {
  name: 'EditPortfolioTemplate',
  components: {
    EditPresentationTemplate
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    templateUuid() {
      let tabs = this.$route.params.tab
      tabs = Array.isArray(tabs) ? tabs : tabs?.split('/') || []
      return tabs[1] || undefined
    }
  },
  created() {
    setRoutePadding(this.$router, this.$route, false)
  },
  beforeDestroy() {
    setRoutePadding(this.$router, this.$route, true)
  }
}
</script>

<style lang="scss" scoped></style>
