<template>
  <div class="member-table-wrapper">
    <div class="member-table-filters">
      <Button
        v-for="filter in roleFilters"
        :key="filter.uuid"
        :text="filter.name"
        :type="activeFilter === filter.uuid ? 'light-solid' : 'white'"
        @click="() => selectFilter(filter)"
      >
        <template #right>
          <Tag :text="`${filter.member_count}`" type="light-solid" size="xxs" />
        </template>
      </Button>
    </div>
    <b-table
      v-infinite-scroll="() => $emit('loadMore')"
      class="member-table"
      :data="data"
      hoverable
      :loading="loading"
      :total="amountOfMembers"
      sortable
      scrollable
      sort-icon="chevron-up"
      :mobile-cards="false"
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="1000"
    >
      <b-table-column
        field="member"
        cell-class="vertical-centered"
        header-class="member-table-header-wrapper"
      >
        <template #header>
          <p class="member-table-header">Name</p>
        </template>
        <template v-slot="props">
          <UserAvatarWithText
            class="full-width"
            :username="$umodel.full_name(props.row.user)"
            :avatar="props.row.user.avatar"
          />
        </template>
      </b-table-column>
      <b-table-column
        field="email"
        cell-class="vertical-centered"
        header-class="member-table-header-wrapper"
      >
        <template #header>
          <p class="member-table-header">Email</p>
        </template>
        <template v-slot="props">
          <p>
            {{ $umodel.email(props.row.user) }}
          </p>
        </template>
      </b-table-column>
      <b-table-column
        field="role"
        cell-class="vertical-centered"
        header-class="member-table-header-wrapper"
      >
        <template #header>
          <p class="member-table-header">Roles</p>
        </template>
        <template v-slot="props">
          <RoleSelect
            :value="props.row.roles"
            fixed-width="12.5rem"
            @submit="
              (roles) => $emit('updateRoles', { member: props.row, roles })
            "
          />
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="impersonate"
        cell-class="vertical-centered"
        header-class="member-table-header-wrapper"
      >
        <b-button
          v-show="
            isUman &&
            !isCurrentUser(props.row) &&
            !isImpersonatedMember(props.row)
          "
          size="is-small"
          type="is-primary"
          @click="() => impersonate(props.row)"
          >Impersonate</b-button
        >
        <b-button
          v-show="isUman && isImpersonatedMember(props.row)"
          size="is-small"
          type="is-danger"
          @click="() => impersonate(undefined)"
        >
          Stop impersonation
        </b-button>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="kebab"
        cell-class="vertical-centered"
        header-class="member-table-header-wrapper"
      >
        <Dropdown
          v-show="!isCurrentUser(props.row)"
          :items="[
            {
              text: 'Remove member',
              icon: 'bin',
              filter: 'red',
              callback: () => $emit('removeMember', props.row)
            }
          ]"
          :position-fixed="true"
        />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import {
  goHome,
  removeImpersonatedMember,
  setImpersonatedMember
} from '@c/util'
import { mapGetters } from 'vuex'
import UserAvatarWithText from '../../../shared/molecules/object-visualisations/user/UserAvatarWithText.vue'
import RoleSelect from '@c/library/RoleSelect.vue'
import Dropdown from '@c/library/Dropdown.vue'
import Button from '@c/library/Button.vue'
import Tag from '@c/library/Tag.vue'

export default {
  name: 'MembersTable',
  components: { UserAvatarWithText, RoleSelect, Dropdown, Button, Tag },
  props: {
    workspace: {
      type: Object,
      required: true
    },
    amountOfMembers: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeFilter: ''
  }),
  computed: {
    ...mapGetters([
      'roles',
      'isUman',
      'rolesWorkspace',
      'currentWorkspaceMember'
    ]),
    roleFilters() {
      if (this.rolesWorkspace !== this.$route.params.workspace_id) return []
      const filters = this.roles.filter((r) => r.member_count)
      filters.sort((a, b) => b.member_count - a.member_count)
      return filters
    },
    isHomePage() {
      return this.$route.name === 'home'
    },
    isCuration() {
      return this.$route.name === 'curation'
    },
    isWorkspaceSettings() {
      return this.$route.name === 'ws-settings'
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickAway)
  },
  unmounted() {
    window.removeEventListener('click', this.handleClickAway)
  },
  methods: {
    impersonate(row) {
      if (row) setImpersonatedMember(row, this.$route, this.$router)
      else removeImpersonatedMember(this.$route, this.$router)
      if (!(this.isHomePage || this.isCuration || this.isWorkspaceSettings))
        goHome(this.$route, this.$router, this.$store)
    },
    isCurrentUser(row) {
      return row.uuid === this.currentWorkspaceMember.uuid
    },
    isImpersonatedMember(row) {
      return this.$route.query.act_as === row.uuid
    },
    selectFilter(filter) {
      this.activeFilter = filter.uuid === this.activeFilter ? '' : filter.uuid
      this.$emit('filter', this.activeFilter)
    }
  }
}
</script>

<style scoped lang="scss">
.member-table {
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  &-filters {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &-kebab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 999rem;
    position: relative;
    cursor: pointer;

    &:hover {
      background: rgba(#000, 8%);
    }

    &-icon {
      height: 1rem;
    }

    &-dropdown {
      position: absolute;
      z-index: 99;
      top: calc(100% + 0.5rem);
      right: 0;
      background: white;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgba(#000, 8%);
      padding: 0.5rem 0;
      min-width: 10rem;
      opacity: 0;
      pointer-events: none;

      &.is-active {
        opacity: 1;
        pointer-events: all;
      }

      &-item {
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: background 0.2s ease;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        min-width: max-content;

        &.is-danger {
          color: #fc0d1b;
        }

        &:hover {
          background: rgba(#000, 8%);
        }

        &-icon {
          height: 1rem;

          &.is-danger {
            filter: brightness(0) saturate(100%) invert(12%) sepia(86%)
              saturate(6157%) hue-rotate(352deg) brightness(103%) contrast(98%);
          }
        }
      }
    }
  }
}

::v-deep .table-container {
  overflow: visible !important;
}

::v-deep .has-text-danger {
  padding: 5px 10px;
  background-color: rgba(#f00, 0);
  transition: background-color 0.2s ease;
  border-radius: 1000px;
  &:hover {
    background-color: rgba(#f00, 0.1);
  }
}

.member-table-header {
  color: #60666b;
  font-size: 1rem;
  font-weight: 500;
}

::v-deep .member-table-header-wrapper {
  border-bottom-color: #ebebeb !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
}
</style>
