var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Dropdown", {
    ref: "publishfilterdropdown",
    on: {
      hide: function() {
        return (_vm.step = -1)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function() {
          return [
            _c("Button", {
              attrs: {
                text: "Add filter",
                icon: "plus-medium",
                type: "white",
                disabled: _vm.disabled
              },
              on: { click: _vm.toggleDropdown }
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("div", { staticClass: "publish-filter" }, [
              _c(
                "div",
                {
                  staticClass: "publish-filter-step publish-filter-categories"
                },
                _vm._l(_vm.filterCategories, function(category) {
                  return _c(
                    "div",
                    {
                      key: category.key,
                      staticClass: "publish-filter-categories-option",
                      on: {
                        click: function() {
                          return _vm.selectCategory(category)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(category.label) + " "),
                      _c("img", {
                        staticClass: "publish-filter-categories-option-icon",
                        attrs: {
                          src: require("@/assets/icons/chevron-right-medium.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "publish-filter-step publish-filter-values",
                  class: { visible: _vm.step === 1 }
                },
                [
                  _c(
                    "div",
                    { staticClass: "publish-filter-values-list" },
                    [
                      _c(
                        "div",
                        { staticClass: "publish-filter-values-list-header" },
                        [
                          _c("Button", {
                            attrs: {
                              text: "Back",
                              icon: "arrow-left",
                              "icon-left": true,
                              type: "grey",
                              size: "xs"
                            },
                            on: { click: _vm.back }
                          })
                        ],
                        1
                      ),
                      _vm._l(_vm.categoryOptions, function(option) {
                        return _c(
                          "div",
                          {
                            key: option.uuid,
                            staticClass: "publish-filter-values-list-option",
                            class: { "is-header": option.is_header },
                            on: {
                              click: function() {
                                return _vm.selectValue(option)
                              }
                            }
                          },
                          [
                            !option.is_header
                              ? _c("Checkbox", {
                                  staticClass:
                                    "publish-filter-values-list-option-check",
                                  attrs: { value: _vm.isSelected(option) }
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(option.value) + " ")
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "publish-filter-values-footer" },
                    [
                      _c("Button", {
                        attrs: { text: "Save", size: "xs" },
                        on: { click: _vm.submit }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }