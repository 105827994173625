var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTrigger
        ? _c("Button", {
            attrs: { text: "Publish", loading: _vm.loading },
            on: { click: _vm.startPublish }
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: { "can-cancel": ["escape", "outside"] },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "publish-modal" }, [
            _c("div", { staticClass: "publish-modal-header" }, [
              _vm._v("Publish " + _vm._s(_vm.typeName) + " template")
            ]),
            _c("div", { staticClass: "publish-modal-content" }, [
              _c("div", { staticClass: "publish-modal-content-review" }, [
                _c(
                  "div",
                  { staticClass: "publish-modal-content-review-input" },
                  [
                    _c("NumberInput", {
                      attrs: {
                        type: "number",
                        min: 0,
                        max: 30,
                        background: "white"
                      },
                      model: {
                        value: _vm.reviewPeriod,
                        callback: function($$v) {
                          _vm.reviewPeriod = $$v
                        },
                        expression: "reviewPeriod"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "publish-modal-content-review-input-text"
                      },
                      [_vm._v("days")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "publish-modal-content-header" }, [
                  _c(
                    "p",
                    { staticClass: "publish-modal-content-header-title" },
                    [
                      _vm._v(
                        " Set the review period for the newly generated content. "
                      )
                    ]
                  ),
                  _c("p", { staticClass: "publish-modal-content-header-sub" }, [
                    _vm._v(
                      " Select a review period between 0 and 30 days. The " +
                        _vm._s(_vm.typeName) +
                        " owners will have until then to review the content before it is published. "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "publish-modal-content-header" }, [
                _c("p", { staticClass: "publish-modal-content-header-title" }, [
                  _vm._v(
                    " Select for which " +
                      _vm._s(_vm.typeName) +
                      "s you want to publish this template "
                  )
                ]),
                _c("p", { staticClass: "publish-modal-content-header-sub" }, [
                  _vm._v(
                    " Add filters to the group below to select the " +
                      _vm._s(_vm.typeName) +
                      "s you want. All future " +
                      _vm._s(_vm.typeName) +
                      "s that match the filters will use this template. "
                  )
                ])
              ]),
              _c("div", { staticClass: "publish-modal-content-list" }, [
                _c(
                  "div",
                  { staticClass: "publish-modal-content-list-filters" },
                  [
                    !_vm.selectedFilters.length
                      ? _c("Tag", {
                          attrs: {
                            text: "All " + _vm.typeName + "s",
                            type: "light"
                          }
                        })
                      : _vm._e(),
                    _vm._l(_vm.selectedFilters, function(filter, idx) {
                      return _c("Tag", {
                        key: "portfolio-publish-" + idx,
                        staticClass: "publish-modal-content-list-filters-tag",
                        class: { hidden: !_vm.filterText(filter) },
                        attrs: { text: _vm.filterText(filter), type: "light" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("Button", {
                                    attrs: {
                                      icon: "close",
                                      type: "grey",
                                      size: "xxs"
                                    },
                                    on: {
                                      click: function() {
                                        return _vm.removeFilter(idx)
                                      }
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    _c("TemplatePublishFilter", {
                      attrs: {
                        aggregations: _vm.selectionAggregations,
                        selected: _vm.selectedFilters,
                        disabled: _vm.loadingItems
                      },
                      on: { submit: _vm.setFilters }
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "publish-modal-content-list-items" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "publish-modal-content-list-items-search"
                      },
                      [
                        _c("TextInput", {
                          attrs: {
                            "left-icon": "search",
                            background: "white",
                            disabled:
                              _vm.loadingItems || !_vm.selectionItems.length,
                            placeholder:
                              "Search" +
                              (_vm.selectionCount > 0
                                ? " " + _vm.selectionCount
                                : "") +
                              " " +
                              _vm.typeName +
                              "s"
                          },
                          model: {
                            value: _vm.selectionQuery,
                            callback: function($$v) {
                              _vm.selectionQuery = $$v
                            },
                            expression: "selectionQuery"
                          }
                        })
                      ],
                      1
                    ),
                    _c("Table", {
                      staticStyle: { margin: "0" },
                      attrs: {
                        items: _vm.filteredItems,
                        headers: _vm.selectionHeaders,
                        "max-height": _vm.tableHeight,
                        "default-sort-by": "name",
                        background: "#f1f2f3"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function(ref) {
                            var header = ref.header
                            var item = ref.item
                            return [
                              header.id === "name"
                                ? _c("PortfolioItemName", {
                                    attrs: {
                                      item: item,
                                      type: _vm.oriType,
                                      clickable: false
                                    }
                                  })
                                : header.id === "owners"
                                ? _c("PortfolioItemOwners", {
                                    attrs: { item: item, type: _vm.oriType }
                                  })
                                : _c("div", [
                                    _vm._v(" " + _vm._s(header.key(item)) + " ")
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _vm.loadingItems
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "publish-modal-content-list-items-loading"
                          },
                          [
                            _c("img", {
                              staticClass:
                                "publish-modal-content-list-items-loading-icon",
                              attrs: {
                                src: require("@/assets/icons/spinner.svg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    !_vm.loadingItems && !_vm.selectionItems.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "publish-modal-content-list-items-empty"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "publish-modal-content-list-items-empty-title"
                              },
                              [
                                _vm._v(
                                  " No " + _vm._s(_vm.typeName) + "s found "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "publish-modal-content-list-items-empty-sub"
                              },
                              [
                                _vm._v(
                                  " You could still publish this template with these filters. All future " +
                                    _vm._s(_vm.typeName) +
                                    "s that these filters apply to, will have content generated by this template. "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "publish-modal-footer" },
              [
                _c("Button", {
                  attrs: {
                    type: "white",
                    text: "Cancel",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function() {
                      return (_vm.showModal = false)
                    }
                  }
                }),
                _c("Button", {
                  attrs: { text: "Publish", loading: _vm.loading },
                  on: { click: _vm.handlePublish }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }