<template>
  <div class="templates">
    <div v-if="!isEdit" class="templates-header">
      <p class="templates-header-title">Meeting templates</p>
      <Button text="Add template" @click="createTemplate" />
    </div>
    <div v-if="!isEdit" class="templates-content">
      <div class="templates-content-top">
        <TextInput
          v-model="query"
          left-icon="search"
          placeholder="Search..."
          :disabled="!(templates && templates.length)"
          class="templates-content-search"
        />
        <Button
          v-for="filter in filterOptions"
          :key="filter.value"
          :text="filter.text"
          :disabled="filter.disabled"
          :type="statusFilter === filter.value ? 'light-solid' : 'white'"
          @click="statusFilter = filter.value"
        />
      </div>
      <div
        ref="templateslist"
        :style="maxHeightStyle"
        class="templates-content-list"
      >
        <div v-if="loading" class="templates-content-loading">
          <b-loading active :is-full-page="false"></b-loading>
        </div>
        <div class="templates-content-list-grid">
          <TemplateCard
            v-for="template in filteredTemplates"
            :key="template.uuid"
            :template="template"
            :show-status="true"
          >
            <template #action>
              <WorkspaceTemplateActions
                :template="template"
                :is-core="template.provider === 'core'"
                class="templates-table-item-actions"
                @duplicate="handleDuplicateTemplate"
                @delete="handleDeleteTemplate"
                @publish="handlePublishTemplate"
                @edit="handleEditTemplate"
              />
            </template>
          </TemplateCard>
        </div>
      </div>
    </div>
    <EditPitchTemplate v-else />
    <CreateTemplate
      v-if="modal === 'create'"
      :visible="true"
      type="story"
      @close="closeModal"
      @created="handleCreated"
    />
    <WorkspaceTemplateInspect
      v-if="inspect"
      :template="inspect"
      @close="inspect = undefined"
      @duplicate="actionFromInspect('duplicate')"
      @edit="actionFromInspect('edit')"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import CreateTemplate from './templates/CreateTemplate.vue'
import { mapActions } from 'vuex'
import WorkspaceTemplateInspect from './templates/WorkspaceTemplateInspect.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import TemplateCard from '../templates/TemplateCard.vue'
import WorkspaceTemplateActions from './templates/WorkspaceTemplateActions.vue'
import EditPitchTemplate from './templates/EditPitchTemplate.vue'

export default {
  components: {
    TemplateCard,
    WorkspaceTemplateActions,
    TextInput,
    Button,
    CreateTemplate,
    WorkspaceTemplateInspect,
    EditPitchTemplate
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    templates: [],
    loading: false,
    query: '',
    statusFilter: 'all',
    tableKey: 0,
    modal: '',
    modalTemplate: undefined,
    inspect: undefined
  }),
  computed: {
    headers() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: (template) => template.name,
          sortAsc: (a, b) => a.name.localeCompare(b.name),
          sortDesc: (a, b) => b.name.localeCompare(a.name)
        },
        {
          id: 'creator',
          title: 'Author',
          key: (template) => this.mapCreator(template.provider),
          sortAsc: (a, b) =>
            this.mapCreator(a.provider).localeCompare(
              this.mapCreator(b.provider)
            ),
          sortDesc: (a, b) =>
            this.mapCreator(b.provider).localeCompare(
              this.mapCreator(a.provider)
            )
        },
        {
          id: 'status',
          title: 'Status',
          key: (template) => template.status,
          sortAsc: (a, b) => a.status.localeCompare(b.status),
          sortDesc: (a, b) => b.status.localeCompare(a.status)
        },
        {
          id: 'buttons',
          title: '',
          key: () => '',
          sortAsc: () => 0,
          sortDesc: () => 0
        }
      ]
    },
    filterOptions() {
      return [
        {
          value: 'all',
          text: 'Show all',
          disabled: (this.templates?.length || 0) === 0
        },
        {
          value: 'visible',
          text: 'Enabled',
          disabled: !this.templates?.some((t) => t.status === 'visible')
        },
        {
          value: 'hidden',
          text: 'Disabled',
          disabled: !this.templates?.some((t) => t.status === 'hidden')
        }
      ]
    },
    filteredTemplates() {
      return this.templates.filter((template) => {
        const queryMatch =
          !this.query ||
          template.name.toLowerCase().includes(this.query.toLowerCase()) ||
          template.description
            ?.toLowerCase()
            .includes(this.query.toLowerCase()) ||
          this.mapCreator(template.provider)
            .toLowerCase()
            .includes(this.query.toLowerCase())
        const statusMatch =
          this.statusFilter === 'all' || template.status === this.statusFilter
        return queryMatch && statusMatch
      })
    },
    routeTabs() {
      let tabs = this.$route.params.tab
      return Array.isArray(tabs) ? tabs : tabs?.split('/') || []
    },
    isEdit() {
      return this.routeTabs.length > 1 && this.routeTabs[2] === 'edit'
    },
    editUuid() {
      return this.isEdit ? this.routeTabs[1] : undefined
    }
  },
  created() {
    this.loadTemplates()
  },
  methods: {
    ...mapActions(['getTemplates']),
    getMaxHeightElement() {
      return this.$refs.templateslist
    },
    mapCreator(provider) {
      return {
        core: 'uman',
        custom: 'Admin'
      }[provider]
    },
    async loadTemplates() {
      try {
        this.loading = true
        this.templates = await this.getTemplates({
          workspace_id: this.$route.params.workspace_id
        })
      } catch (error) {
        this.$console.debug('Error loading templates', error)
        this.$toast.error(error, 'loading the templates')
      } finally {
        this.loading = false
      }
    },
    closeModal() {
      this.modal = ''
      this.modalTemplate = undefined
    },
    createTemplate() {
      this.modal = 'create'
    },
    handleCreated(template) {
      this.tableKey++
      this.handleEditTemplate(template)
    },
    templateIndex(template) {
      return this.templates.findIndex((t) => t.uuid === template.uuid)
    },
    handleEditTemplate(template) {
      this.$router.push({
        name: 'ws-settings',
        params: {
          workspace_id: this.$route.params.workspace_id,
          tab: ['meeting_templates', template.uuid, 'edit']
        }
      })
    },
    handleDeleteTemplate(template) {
      this.templates.splice(this.templateIndex(template), 1)
      this.tableKey++
    },
    handleDuplicateTemplate() {
      this.tableKey++
    },
    handlePublishTemplate(template) {
      this.templates.splice(this.templateIndex(template), 1, template)
      this.tableKey++
    },
    handleViewTemplate(template) {
      this.inspect = template
    },
    actionFromInspect(action) {
      if (action === 'edit') {
        this.handleEditTemplate(this.inspect)
      } else if (action === 'duplicate') {
        const actions = Array.from(
          document.getElementsByClassName('templates-table-item-actions')
        ).map((a) => a.__vue__)
        const index = actions.findIndex(
          (a) => a.template.uuid === this.inspect.uuid
        )
        actions[index].handleDuplicate()
      }
      this.inspect = undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.templates {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.75rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &-title {
      font-size: 2.25rem;
      font-weight: 700;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.75rem;

    &-search {
      width: min(30rem, 100%);
      margin-right: auto;
    }

    &-top {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-loading {
      position: relative;
      min-height: 5rem;
    }

    &-list {
      padding-bottom: 2.5rem;

      &-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: 1rem;
        padding-bottom: 4rem;
      }
    }
  }

  &-table-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    padding: 0.25rem 0; // makes it easier to click on the item

    &.clickable {
      cursor: pointer;
    }

    &.padded {
      padding: 0.75rem 0; // makes it easier to click on the item
    }

    &-image {
      height: 3rem;
    }

    &-content {
      &-description {
        color: #60666b;
      }
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
