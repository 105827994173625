<template>
  <div class="template-sidebar">
    <div class="template-sidebar-section">
      <p class="template-sidebar-section-title">Elements</p>
      <div class="template-sidebar-section-list">
        <div
          v-for="(element, index) in sortedElements"
          :key="index"
          class="template-sidebar-section-list-item"
          :class="{
            selected: element.uuid === selected,
            hovered: element.uuid === hovered
          }"
          @click="() => $emit('select', element.uuid)"
          @mouseenter="() => $emit('hover', element.uuid)"
          @mouseleave="() => $emit('hover', '')"
        >
          <div class="template-sidebar-section-list-item-header">
            <p class="template-sidebar-section-list-item-name">
              {{ element.content_type.capitalize() }}
            </p>
            <b-tooltip
              v-if="element.prompt"
              label="Test prompt"
              type="is-dark"
              position="is-left"
            >
              <Button
                icon="snippet-preview"
                type="light-solid"
                @click.native.stop="() => $emit('test', element)"
              />
            </b-tooltip>
          </div>
          <p
            v-if="!!element.prompt"
            class="template-sidebar-section-list-item-text"
          >
            {{ element.prompt }}
          </p>
        </div>
      </div>
    </div>
    <div class="template-sidebar-section">
      <p class="template-sidebar-section-title">Template info</p>
      <TextInput
        v-model="currentName"
        title="Name"
        placeholder="Template name"
        :disabled="!isEdit"
        :max-length="50"
      />
      <TextInput
        v-model="currentDescription"
        title="Description"
        placeholder="Describe your template"
        :disabled="!isEdit"
      />
      <div v-if="isEdit" class="template-sidebar-section-btns">
        <Button
          text="Save"
          :loading="infoLoading"
          :disabled="currentName === name && currentDescription === description"
          @click="() => $emit('updateInfo', currentName, currentDescription)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'

export default {
  name: 'TemplateEditSidebar',
  components: { TextInput, Button },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ''
    },
    hovered: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    infoLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    currentName: '',
    currentDescription: ''
  }),
  computed: {
    sortedElements() {
      let list = [...this.elements]
      list.sort(
        (a, b) =>
          Math.round(a.y * 100) - Math.round(b.y * 100) ||
          Math.round(a.x * 100) - Math.round(b.x * 100)
      )
      return list
    }
  },
  watch: {
    name(val) {
      this.currentName = val
    },
    description(val) {
      this.currentDescription = val
    },
    selected(val) {
      if (val) {
        this.$nextTick(() => {
          const el = document.querySelector(
            '.template-sidebar-section-list-item.selected'
          )
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        })
      }
    }
  },
  created() {
    this.currentName = this.name
    this.currentDescription = this.description
  }
}
</script>

<style lang="scss" scoped>
.template-sidebar {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  background: white;
  border-left: 1px solid rgba(#000, 0.08);
  border-right: 1px solid rgba(#000, 0.08);

  &-section {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    flex: 1;
    padding: 1.5rem;
    gap: 1rem;

    &-title {
      font-weight: 700;
      font-size: 1.25rem;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      overflow: auto;

      &-item {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        border-radius: 6px;
        border: 1px solid rgba(#000, 0.08);
        padding: 1rem;
        cursor: pointer;

        &.selected {
          border: 1px solid $primary;
          background: rgba($primary, 0.04);

          &:hover,
          &.hovered {
            background: rgba($primary, 0.06);
          }
        }

        &:hover,
        &.hovered {
          background: rgba(#000, 0.04);
        }

        &.is-image {
          flex-flow: row nowrap;
          align-items: center;
          gap: 1rem;
        }

        &-header {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
        }

        &-name {
          font-weight: 600;
        }

        &-text {
          font-size: 0.9rem;
          color: #60666b;
        }

        &-link {
          display: flex;
          flex-flow: row wrap;
          gap: 0.25rem;

          &-text {
            color: #60666b;
            font-weight: 600;
            word-break: break-all;
          }

          &-url {
            color: $primary;
            word-break: break-all;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &-image {
          height: 100%;
          max-width: 6rem;
          object-fit: contain;
          display: block;

          &-wrapper {
            display: flex;
            flex-flow: row nowrap;
            gap: 0.5rem;
            align-items: center;
            height: 2rem;
            min-height: 2rem;
            max-height: 2rem;
          }

          &-name {
            font-size: 0.9rem;
            color: #60666b;
          }
        }
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      align-self: flex-end;
    }
  }
}
</style>
