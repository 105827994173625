<template>
  <Dropdown ref="publishfilterdropdown" @hide="() => (step = -1)">
    <template #trigger>
      <Button
        text="Add filter"
        icon="plus-medium"
        type="white"
        :disabled="disabled"
        @click="toggleDropdown"
      />
    </template>
    <template #content>
      <div class="publish-filter">
        <div class="publish-filter-step publish-filter-categories">
          <div
            v-for="category in filterCategories"
            :key="category.key"
            class="publish-filter-categories-option"
            @click="() => selectCategory(category)"
          >
            {{ category.label }}
            <img
              src="@/assets/icons/chevron-right-medium.svg"
              alt=""
              class="publish-filter-categories-option-icon"
            />
          </div>
        </div>
        <div
          class="publish-filter-step publish-filter-values"
          :class="{ visible: step === 1 }"
        >
          <div class="publish-filter-values-list">
            <div class="publish-filter-values-list-header">
              <Button
                text="Back"
                icon="arrow-left"
                :icon-left="true"
                type="grey"
                size="xs"
                @click="back"
              />
            </div>
            <div
              v-for="option in categoryOptions"
              :key="option.uuid"
              class="publish-filter-values-list-option"
              :class="{ 'is-header': option.is_header }"
              @click="() => selectValue(option)"
            >
              <Checkbox
                v-if="!option.is_header"
                :value="isSelected(option)"
                class="publish-filter-values-list-option-check"
              />
              {{ option.value }}
            </div>
          </div>
          <div class="publish-filter-values-footer">
            <Button text="Save" size="xs" @click="submit" />
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Button from '@c/library/Button.vue'
import Dropdown from '@c/library/Dropdown.vue'
import Checkbox from '@c/library/Checkbox.vue'

const aggregationKeyMap = {
  attributes: 'Labels',
  offerings: 'Subofferings of',
  reference_confidentiality: 'Confidentiality'
}

const aggregationFilterMap = {
  attributes: 'attribute_value',
  offerings: 'offering',
  reference_confidentiality: 'reference_confidentiality'
}

export default {
  name: 'TemplatePublishFilter',
  components: { Button, Dropdown, Checkbox },
  props: {
    aggregations: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    step: -1,
    category: undefined,
    tempSelected: []
  }),
  computed: {
    filterCategories() {
      return Object.keys(this.aggregations).map((key) => ({
        key,
        label: aggregationKeyMap[key],
        filterKey: aggregationFilterMap[key]
      }))
    },
    categoryOptions() {
      if (!this.category) return []
      let values = this.aggregations[this.category.key]
      values = {
        attributes: () =>
          values.reduce((acc, val) => {
            const { values: attValues, ...parent } = val
            return [
              ...acc,
              { ...parent, value: parent.name, is_header: true },
              ...attValues
            ]
          }, []),
        offerings: () => values.map((val) => ({ ...val, value: val.name })),
        reference_confidentiality: () =>
          values.map((val) => ({ uuid: val, value: val.capitalize() }))
      }[this.category.key]()
      return values
    }
  },
  methods: {
    toggleDropdown() {
      this.step = this.step > -1 ? -1 : 0
      if (this.step > -1) this.tempSelected = [...this.selected]
    },
    selectCategory(category) {
      this.category = category
      this.step = 1
    },
    back() {
      this.category = undefined
      this.step = 0
    },
    selectValue(option) {
      if (option.is_header) return
      const index = this.tempSelected.findIndex(
        (sel) => sel[this.category.filterKey] === option.uuid
      )
      if (index > -1) {
        this.tempSelected.splice(index, 1)
      } else {
        this.tempSelected.push({ [this.category.filterKey]: option.uuid })
      }
    },
    isSelected(option) {
      return this.tempSelected.some(
        (sel) => sel[this.category.filterKey] === option.uuid
      )
    },
    submit() {
      this.category = undefined
      this.$refs.publishfilterdropdown.hideOptions()
      this.$emit('submit', this.tempSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.publish-filter {
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(#000, 0.08);
  font-size: 0.85rem;

  &-step {
    height: 35vh;
    max-height: 35vh;
    width: 20rem;
    overflow-y: auto;
  }

  &-categories {
    padding: 0.5rem 0;

    &-option {
      cursor: pointer;
      padding: 0.5rem 1rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: 0.5rem;
      align-items: center;

      &:hover {
        background: rgba(#000, 0.04);

        & .publish-filter-categories-option-icon {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &-icon {
        height: 1.2rem;
        opacity: 0;
        transform: translateX(-1rem);
        transition: opacity 0.2s ease, transform 0.2s ease;
      }
    }
  }

  &-values {
    position: absolute;
    top: 0;
    left: 20rem;
    bottom: 0;
    width: 20rem;
    transition: left 0.2s ease;
    display: flex;
    flex-flow: column nowrap;
    background: white;

    &.visible {
      left: 0;
    }

    &-list {
      flex: 1;
      overflow-y: auto;
      padding-bottom: 0.5rem;

      &-header {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid rgba(#000, 0.08);
      }

      &-option {
        padding: 0.5rem 1rem;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
        align-items: center;

        &.is-header {
          font-weight: 600;
          background: rgba(#000, 0.04);
        }

        &:not(.is-header) {
          cursor: pointer;

          &:hover {
            background: rgba(#000, 0.08);
          }
        }

        &-check {
          pointer-events: none;
        }
      }
    }

    &-footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      padding: 0.5rem 1rem;
      border-top: 1px solid rgba(#000, 0.08);
    }
  }
}
</style>
