<template>
  <div class="template-preview">
    <div v-if="!loading" ref="templatepreview" class="template-preview-content">
      <img :src="slide.preview" alt="" class="template-preview-content-img" />
      <div
        v-for="(element, idx) in elements"
        :key="`preview-element-${idx}`"
        class="template-preview-content-element"
        :class="{
          selected: element.uuid === selected,
          hovered: element.uuid === hovered
        }"
        :style="computedStyle(element)"
        @click.stop="() => $emit('select', element.uuid)"
        @mouseenter="() => $emit('hover', element.uuid)"
        @mouseleave="() => $emit('hover', '')"
      ></div>
    </div>
    <b-skeleton v-else />
  </div>
</template>

<script>
export default {
  name: 'TemplateEditPreview',
  props: {
    slide: {
      type: Object,
      default: () => ({})
    },
    elements: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ''
    },
    hovered: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    computedStyle(element) {
      const { x, y, width, height } = element
      return {
        left: `${x * 100}%`,
        top: `${y * 100}%`,
        width: `${width * 100}%`,
        height: `${height * 100}%`,
        transform: `rotate(${element.rotation}deg)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-preview {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1.5rem;

  &-content {
    position: relative;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    box-sizing: content-box;

    &-img {
      width: 100%;
      object-fit: contain;
      display: block;
      border-radius: 4px;
    }

    &-element {
      position: absolute;
      border: 2px dashed rgba($primary, 0.5);
      border-radius: 2px;
      cursor: pointer;

      &:hover,
      &.hovered {
        border: 2px dashed $primary;
        background: rgba($primary, 0.08);
      }

      &.selected {
        border: 2px solid $primary;
        background: rgba($primary, 0.08);

        &:hover,
        &.hovered {
          border: 2px solid $primary;
          background: rgba($primary, 0.08);
        }
      }
    }
  }
}

::v-deep .b-skeleton {
  aspect-ratio: 16 / 9;
  margin-top: 0;
  min-width: 100%;
  width: 100%;

  &-item {
    height: 100%;
  }
}
</style>
