<template>
  <b-modal :active="visible" :can-cancel="['escape', 'outside']" @close="close">
    <div class="portfolio-resources">
      <div class="portfolio-resources-header">
        <p class="portfolio-resources-header-title">
          Generated content for {{ template.name }}
        </p>
        <Button icon="close" size="xs" type="grey" @click="close" />
      </div>
      <div class="portfolio-resources-content">
        <div v-if="!loading" class="portfolio-resources-content-filters">
          Applied filters
          <Tag
            v-if="!selectedFilters.length"
            :text="`All ${oriTypeName}s`"
            type="light"
          />
          <Tag
            v-for="(filter, idx) in selectedFilters"
            :key="`portfolio-publish-${idx}`"
            :text="filterText(filter)"
            class="portfolio-resources-content-filters-tag"
            :class="{ hidden: !filterText(filter) }"
            type="light"
          />
        </div>
        <Table
          v-if="!loading && !error && resources.length"
          :items="resources"
          :headers="headers"
          max-height="45vh"
        >
          <template #item="{ item, header, index }">
            <div
              v-if="header.id === 'name'"
              class="portfolio-resources-content-item resource-name"
            >
              <img
                v-if="slidePreview(item)"
                :src="slidePreview(item)"
                alt=""
                class="resource-name-preview"
                @click="() => openPreview(item, index)"
              />
              <div v-else class="resource-name-generating">
                <img
                  src="@/assets/icons/ai.svg"
                  alt=""
                  class="resource-name-generating-icon"
                />
                Generating...
              </div>
              <a :href="itemUrl(item)" target="_blank">
                <p class="resource-name-text">{{ header.key(item) }}</p>
              </a>
            </div>
            <StatusOrb
              v-else-if="header.id === 'status'"
              :type="header.key(item) === 'published' ? 'green' : 'orange'"
              :text="header.key(item).capitalize()"
              :show-text="true"
            />
            <div
              v-else-if="header.id === 'reviewer'"
              class="portfolio-resources-content-item resource-reviewer"
            >
              <Avatar :user="item.reviewer" />
              <p class="resource-reviewer-name">{{ header.key(item) }}</p>
            </div>
            <div
              v-else
              class="portfolio-resources-content-item resource-default"
            >
              {{ header.key(item) }}
            </div>
          </template>
        </Table>
        <div v-if="loading" class="portfolio-resources-content-loading">
          <b-loading active :is-full-page="false" />
        </div>
        <div v-else-if="error" class="portfolio-resources-content-error">
          Something went wrong while loading the content, please try again later
          <Button text="Retry" icon="refresh" type="white" @click="initState" />
        </div>
        <div
          v-else-if="!resources.length"
          class="portfolio-resources-content-error"
        >
          No content available
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import Table from '@c/library/Table.vue'
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import StatusOrb from '@c/library/StatusOrb.vue'

import { mapGetters, mapActions } from 'vuex'
import {
  getKeyContentTemplateResources,
  getKeyContentTemplatePublishORIs
} from '@/services/keyContentService'
import { formatDate } from '@/util'

export default {
  name: 'PortfolioTemplateContentModal',
  components: {
    Tag,
    Table,
    Avatar,
    Button,
    StatusOrb
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    template: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'offering'
    }
  },
  data: () => ({
    loading: false,
    error: null,
    resources: [],
    selectedFilters: [],
    aggregations: undefined,
    bc: null
  }),
  computed: {
    ...mapGetters(['currentWorkspaceMember', 'canWriteOri']),
    oriTypeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspiration'
      }[this.type]
    },
    headers() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: (res) => this.resourceName(res),
          sortAsc: (a, b) =>
            this.resourceName(a).localeCompare(this.resourceName(b)),
          sortDesc: (a, b) =>
            this.resourceName(b).localeCompare(this.resourceName(a))
        },
        {
          id: 'status',
          title: 'Status',
          key: (res) => res.status,
          sortAsc: (a, b) => a.status.localeCompare(b.status),
          sortDesc: (a, b) => b.status.localeCompare(a.status)
        },
        {
          id: 'date_created',
          title: 'Created',
          key: (res) => formatDate(res.date_created),
          sortAsc: (a, b) =>
            new Date(a.date_created) - new Date(b.date_created),
          sortDesc: (a, b) =>
            new Date(b.date_created) - new Date(a.date_created)
        },
        {
          id: 'date_published',
          title: 'Published',
          key: (res) => this.itemPublished(res),
          sortAsc: (a, b) =>
            this.itemPublished(a) === '-'
              ? 1
              : this.itemPublished(b) === '-'
              ? -1
              : new Date(a.status_date) - new Date(b.status_date),
          sortDesc: (a, b) =>
            this.itemPublished(b) === '-'
              ? 1
              : this.itemPublished(a) === '-'
              ? -1
              : new Date(b.status_date) - new Date(a.status_date)
        }
      ]
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.initState()
        this.bc = new BroadcastChannel('uman_template_resource_publish')
        this.bc.onmessage = this.handleModalResponse
      } else {
        this.bc.close()
      }
    }
  },
  methods: {
    ...mapActions(['setResourceModal']),
    close() {
      this.$emit('close')
    },
    resourceName(item) {
      let name = item.resources?.[0]?.title || ''
      name = name.includes('.') ? name.split('.').slice(0, -1).join('.') : name
      if (name) return name
      return item[this.type].name || ''
    },
    slidePreview(item) {
      return item.resources?.[0]?.subresources?.[0]?.preview || ''
    },
    itemUrl(item) {
      const route = this.$router.resolve({
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_id: item[this.type].uuid,
          ori_type: `${this.type}s`
        }
      })
      return route.href
    },
    itemPublished(item) {
      return item.status === 'published' ? formatDate(item.status_date) : '-'
    },
    async initState() {
      this.loading = false
      this.error = null
      this.resources = []
      this.selectedFilters = []
      this.aggregations = undefined
      this.loading = true
      await Promise.all([this.loadContent(), this.loadFilters()])
      this.loading = false
    },
    async loadContent() {
      try {
        this.error = null
        this.resources = []
        this.resources = await getKeyContentTemplateResources({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid
        })
      } catch (e) {
        this.error = e
        this.$console.debug('Error loading portfolio template resources', e)
        this.$toast.error(e, `loading ${this.oriTypeName} template content`)
      }
    },
    async loadFilters() {
      try {
        const res = await getKeyContentTemplatePublishORIs({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid,
          ori_type: `${this.type}s`
        })
        this.aggregations = res.aggregations
        this.selectedFilters = res.selected_filters
      } catch (e) {
        this.$console.debug('Error loading filters', e)
        this.$toast.error(e, 'loading the filters')
      }
    },
    filterText(filter) {
      if (!this.aggregations) return ''
      const possibleKeys = [
        'attribute_value',
        'offering',
        'reference_confidentiality'
      ]
      const key = possibleKeys.find((k) => !!filter[k])
      let name =
        {
          attribute_value: 'Label',
          offering: 'Parent offering',
          reference_confidentiality: 'Confidentiality'
        }[key] || ''
      const aggregationKey =
        {
          attribute_value: 'attributes',
          offering: 'offerings',
          reference_confidentiality: 'reference_confidentiality'
        }[key] || ''
      const values = this.aggregations[aggregationKey] || []
      const value = {
        attribute_value: () =>
          values.reduce((acc, val) => {
            const label = val?.values?.find(
              (v) => v?.uuid === filter?.attribute_value
            )
            if (label) name = val.name
            return label ? label?.value || '' : acc
          }, ''),
        offering: () =>
          values.find((v) => v?.uuid === filter?.offering)?.name || '',
        reference_confidentiality: () =>
          filter?.reference_confidentiality?.capitalize()
      }[key]()
      return name && value ? `${name}: ${value}` : ''
    },
    openPreview(item, index) {
      const resource = item.resources?.[0]
      this.setResourceModal({
        resource_id: resource.uuid,
        resource_level: 'resource',
        resource_modal_props: {
          workspaceId: this.$route.params.workspace_id,
          initialPage: 1,
          selectable: false,
          scrollTo: '',
          similarPages: [],
          rank: index + 1,
          resourceType: resource.type,
          resourceTraceId: resource.trace_id
        },
        buefy_modal_props: {},
        root_props: {
          needsReview: resource.status === 'draft' && this.canWriteOri,
          templateId: this.template.uuid,
          oriType: `${this.type}s`,
          oriId: item[this.type].uuid,
          canEdit: this.canWriteOri
        }
      })
    },
    handleModalResponse(message) {
      const { event, result } = message.data
      if (event === 'published')
        this.resources = this.resources.map((res) =>
          res[this.type].uuid === result[this.type].uuid ? result : res
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-resources {
  width: min(65rem, 95vw);
  background: white;
  border-radius: 6px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  &-content {
    padding: 1.5rem 1.5rem 0.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-loading {
      position: relative;
      min-height: 5rem;
      padding-bottom: 1rem;
    }

    &-error {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.15rem;
      padding-bottom: 1rem;
      color: #60666b;
    }

    &-filters {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      align-items: center;

      &-tag {
        &.hidden {
          display: none;
        }
      }
    }
  }
}

.resource {
  &-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.75rem;

    &-preview {
      width: 5rem;
      min-width: 5rem;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.08);
      display: block;
      cursor: zoom-in;
    }

    &-generating {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      min-width: 5rem;
      min-height: 2.8rem;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.08);
      background: #f1f2f3;
      font-size: 0.85rem;
      font-weight: 600;
      color: #60666b;
      padding: 0.25rem;
      user-select: none;

      &-icon {
        height: 1rem;
        opacity: 0.85;
      }
    }

    &-text {
      font-weight: 600;
    }
  }

  &-reviewer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.75rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
