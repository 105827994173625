<template>
  <div class="template-slides">
    <div
      v-for="(slide, idx) in slides"
      :key="`template-slide-${idx}`"
      class="template-slides-slide-wrapper"
    >
      <img
        :src="slide.preview"
        alt=""
        class="template-slides-slide"
        :class="{ selected: slide.uuid === selected }"
        @click="() => $emit('select', slide.uuid)"
      />
      <div v-if="showAdd" class="template-slides-slide-blocks">
        <div
          v-for="(block, position) in slideBlocks[idx] || []"
          :key="`template-slide-block-${idx}-${position}`"
          class="template-slides-slide-blocks-block"
        >
          <TemplateSlideBlockAdd v-if="isEdit" @click="() => $emit('add', idx, position)" />
          <TemplateSlideBlockPill
            :block="block"
            :template-id="templateId"
            @edit="() => $emit('edit', idx, position)"
            @remove="(block) => $emit('remove', block)"
          />
        </div>
        <TemplateSlideBlockAdd
          v-if="isEdit"
          @click="
            () =>
              $emit(
                'add',
                idx,
                slideBlocks[idx].length ? slideBlocks[idx].length + 1 : 0
              )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateSlideBlockAdd from './blocks/TemplateSlideBlockAdd.vue'
import TemplateSlideBlockPill from './blocks/TemplateSlideBlockPill.vue'

export default {
  name: 'TemplateEditSlides',
  components: { TemplateSlideBlockAdd, TemplateSlideBlockPill },
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    blocks: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ''
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    templateId: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    slideBlocks() {
      return this.slides.map((_, idx) => {
        let blocks = this.blocks.filter(
          (block) => block.slide_index - 1 === idx
        )
        blocks = blocks.sort((a, b) => a.position - b.position)
        return blocks
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template-slides {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1rem;
  border-left: 1px solid rgba(#000, 0.08);
  border-right: 1px solid rgba(#000, 0.08);

  &-slide {
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    cursor: pointer;
    &.selected {
      border: 1px solid $primary;

      &:hover {
        border: 1px solid $primary;
      }
    }

    &:hover {
      border: 1px solid rgba(#000, 0.16);
    }

    &-blocks {
      display: contents;

      &-block {
        display: contents;
      }
    }
  }

  &-section {
    border-radius: 999rem;
    background: $primary;
    color: white;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &-icon {
      height: 1.2rem;
      filter: brightness(0) invert(1);
    }
  }
}
</style>
