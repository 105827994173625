<template>
  <b-modal :active="visible" @close="() => $emit('close')">
    <div class="template-create">
      <div class="template-create-header">
        New template
        <Button
          icon="close"
          type="grey"
          size="xs"
          @click="() => $emit('close')"
        />
      </div>
      <div v-if="!step" class="template-create-content">
        <TextInput
          v-model="name"
          title="Name"
          placeholder="Template name"
          :max-length="50"
        />
        <TextInput
          v-model="description"
          title="Description"
          placeholder="Describe your new template"
        />
        <div
          v-if="type === 'portfolio'"
          class="template-create-content-section"
        >
          <p class="template-create-content-section-header">Content type</p>
          <div class="template-create-content-section-options">
            <div
              v-for="t in ori_types"
              :key="t.key"
              class="template-create-content-section-options-option"
              :class="{ selected: t.key === ori_type }"
              @click="() => (ori_type = t.key)"
            >
              <Checkbox
                :value="t.key === ori_type"
                type="radio"
                class="template-create-content-section-options-option-radio"
                @click.native.stop
              />
              <div class="template-create-content-section-options-option-info">
                <p class="template-create-content-section-options-option-title">
                  {{ t.title }}
                </p>
                <p
                  class="
                    template-create-content-section-options-option-description
                  "
                >
                  {{ t.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="template-create-content-section">
          <p class="template-create-content-section-header">Content format</p>
          <div class="template-create-content-section-options">
            <div
              v-for="f in formats"
              :key="f.key"
              class="template-create-content-section-options-option"
              :class="{ selected: f.key === format }"
              @click="() => (format = f.key)"
            >
              <Checkbox
                :value="f.key === format"
                type="radio"
                class="template-create-content-section-options-option-radio"
                @click.native.stop
              />
              <p class="template-create-content-section-options-option-title">
                {{ f.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="template-create-content">
        <UploadDropzone
          v-if="!showPrompt"
          :max-count="1"
          :filetypes="['ppt', 'pptx']"
          :show-drive="false"
          :loading="loading"
          @upload="handleCreate"
        />
        <TextArea v-else v-model="prompt" title="Prompt" min-height="20rem" />
      </div>
      <div class="template-create-footer">
        <Button
          :text="step ? 'Back' : 'Cancel'"
          type="white"
          @click="handleBack"
        />
        <Button
          v-if="!step || showPrompt"
          :text="step ? 'Create template' : 'Next'"
          :disabled="step ? !prompt : !name"
          @click="handleNext"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createKeyContentTemplate } from '@/services/keyContentService'
import Button from '@c/library/Button.vue'
import Checkbox from '@c/library/Checkbox.vue'
import TextInput from '@c/library/TextInput.vue'
import TextArea from '@c/library/TextArea.vue'
import UploadDropzone from '@c/library/UploadDropzone.vue'
import { mapActions } from 'vuex'

export default {
  name: 'CreateTemplate',
  components: {
    Button,
    TextInput,
    TextArea,
    Checkbox,
    UploadDropzone
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'portfolio',
      validator: (v) => ['portfolio', 'story'].includes(v)
    },
    oriType: {
      type: String,
      default: 'offering',
      validator: (v) => ['offering', 'reference', 'inspiration'].includes(v)
    }
  },
  data: () => ({
    step: 0,
    name: '',
    description: '',
    prompt: '',
    ori_type: 'offering',
    format: 'presentation',
    loading: false,
    ori_types: [
      {
        key: 'offering',
        title: 'Offering asset',
        description:
          'Documents to describe your portfolio of products and services'
      },
      {
        key: 'reference',
        title: 'Case asset',
        description:
          'Assets to tell the story of your customers and how you helped them'
      },
      {
        key: 'inspiration',
        title: 'Inspirational asset',
        description:
          'Assets to share your ideas and insights with your customers'
      }
    ],
    formats: [
      {
        key: 'presentation',
        title: 'Presentation'
      },
      {
        key: 'document',
        title: 'Document'
      }
    ]
  }),
  computed: {
    showPrompt() {
      return !(this.type === 'portfolio' || this.format === 'presentation')
    }
  },
  watch: {
    visible(val) {
      if (val) this.initState()
    }
  },
  methods: {
    ...mapActions(['createTemplate']),
    initState() {
      this.step = 0
      this.name = ''
      this.description = ''
      this.ori_type = this.oriType || 'offering'
      this.format = 'presentation'
    },
    handleBack() {
      if (this.step) this.step = 0
      else this.$emit('close')
    },
    handleNext() {
      if (this.step) this.handleCreate()
      else this.step = 1
    },
    async handleCreate(files) {
      try {
        this.loading = true
        let template = undefined
        if (this.type === 'portfolio') {
          template = await this.handleCreateTemplate(files[0])
        } else {
          if (this.format === 'document') {
            // Create tool
            template = await this.handleCreateTool()
          } else {
            // Create template, then create tool with template
            const t = await this.handleCreateTemplate(files[0])
            template = await this.handleCreateTool(t.uuid)
          }
        }
        this.$emit('created', template)
        this.$emit('close')
      } catch (e) {
        this.$console.debug('Error creating template', e)
        this.$toast.error(e, 'creating your template')
      } finally {
        this.loading = false
      }
    },
    async handleCreateTool(template = undefined) {
      const res = await this.createTemplate({
        workspace_id: this.$route.params.workspace_id,
        status: 'hidden',
        name: this.name,
        description: this.description,
        ...(this.format === 'presentation'
          ? { template_id: template }
          : { prompt: this.prompt })
      })
      return res
    },
    async handleCreateTemplate(file) {
      let template = undefined
      const t = await createKeyContentTemplate({
        workspace_id: this.$route.params.workspace_id,
        name: this.name,
        description: this.description,
        mimetype: file.type,
        content_length: file.size,
        output_type: this.type === 'portfolio' ? this.ori_type : 'story',
        ...(this.type === 'portfolio'
          ? { output_type: this.ori_type }
          : { output_type: 'story', status: 'published' })
      })
      await this.uploadFile(file, t.upload_url)
      template = t
      return template
    },
    async uploadFile(file, url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('PUT', url, true)
        xhr.onload = (e) => {
          if (xhr.status === 200) {
            resolve()
          } else {
            reject(e)
          }
        }
        xhr.onerror = (e) => {
          reject(e)
        }
        xhr.setRequestHeader('Content-Type', file.type)
        xhr.send(file)
        this.progressKey++
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template-create {
  background: white;
  border-radius: 8px;
  width: min(40rem, 95vw);

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.75rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    font-size: 1.25rem;
    font-weight: 700;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1.5rem 1.75rem;
    max-height: 60vh;
    overflow-y: auto;

    &-section {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-header {
        font-weight: 600;
      }

      &-options {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;

        &-option {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 1rem;
          padding: 1rem;
          border-radius: 6px;
          border: 1px solid rgba(#000, 0.08);
          width: 100%;
          cursor: pointer;

          &-info {
            display: flex;
            flex-flow: column nowrap;
            gap: 0.25rem;
          }

          &:hover {
            background: rgba(#000, 0.04);
          }

          &.selected {
            border: 1px solid $primary;

            &:hover {
              background: rgba($primary, 0.04);
            }
          }

          &-radio {
            pointer-events: none;
          }

          &-title {
            font-weight: 600;
          }

          &-description {
            color: #60666b;
            font-size: 0.9rem;
          }
        }
      }

      &-trigger {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
        border-radius: 4px;
        border: 1px solid rgba(#000, 0.08);
        cursor: pointer;

        &:hover {
          background: rgba(#000, 0.04);
        }

        &-icon {
          margin-left: auto;
          height: 1.2rem;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1.5rem 1.75rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
