var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-edit" },
    [
      _c(
        "div",
        { staticClass: "template-edit-header" },
        [
          _c("Button", {
            attrs: { icon: "arrow-left", type: "grey" },
            on: { click: _vm.goBack }
          }),
          _c("div", { staticClass: "template-edit-header-info" }, [
            _c("p", { staticClass: "template-edit-header-info-name" }, [
              _vm._v(_vm._s(_vm.name))
            ]),
            _c("p", { staticClass: "template-edit-header-info-description" }, [
              _vm._v(_vm._s(_vm.description))
            ])
          ]),
          _c(
            "div",
            { staticClass: "template-edit-header-btns" },
            [
              _vm.isEdit && _vm.template && _vm.outputType
                ? _c(
                    "div",
                    [
                      !_vm.tool
                        ? _c("PortfolioTemplatePublish", {
                            attrs: {
                              template: _vm.template,
                              type: _vm.outputType,
                              tool: _vm.tool
                            },
                            on: { publish: _vm.handlePublish }
                          })
                        : _c("PitchTemplatePublish", {
                            attrs: { template: _vm.tool },
                            on: { publish: _vm.handlePublish }
                          })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.outputType
                ? _c("Button", {
                    attrs: {
                      text: _vm.testButtonText,
                      disabled: _vm.testButtonDisabled
                    },
                    on: { click: _vm.handleTestSlide }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "templatecreatecontent",
          staticClass: "template-edit-content",
          style: _vm.maxHeightStyle
        },
        [
          !_vm.loading
            ? _c("TemplateEditSlides", {
                staticClass: "template-edit-content-slides",
                attrs: {
                  slides: _vm.slides,
                  blocks: _vm.blocks,
                  selected: _vm.selectedSlide,
                  "show-add": !!_vm.tool,
                  "is-edit": _vm.isEdit,
                  "template-id": _vm.templateId
                },
                on: {
                  select: _vm.handleSelectSlide,
                  add: _vm.handleAddBlock,
                  edit: _vm.handleEditBlock,
                  remove: _vm.removeBlock
                }
              })
            : _c("TemplateEditSlidesLoading", {
                staticClass: "template-edit-content-slides"
              }),
          _c(
            "div",
            { staticClass: "template-edit-content-center" },
            [
              _c("TemplateEditPreview", {
                attrs: {
                  slide: _vm.currentSlide,
                  elements: _vm.currentSlide.shapes,
                  selected: _vm.selectedElement,
                  hovered: _vm.hoveredElement,
                  loading: _vm.loading
                },
                on: {
                  select: function(id) {
                    return (_vm.selectedElement = id)
                  },
                  hover: function(id) {
                    return (_vm.hoveredElement = id)
                  }
                }
              }),
              _c("TemplateElementEdit", {
                staticClass: "template-edit-element-edit",
                class: { active: !!_vm.selectedElement },
                attrs: {
                  element: _vm.currentElement,
                  uuid: _vm.selectedElement,
                  loading: _vm.loadingElementEdit,
                  "output-type": _vm.outputType,
                  "is-edit": _vm.isEdit
                },
                on: {
                  close: function() {
                    return (_vm.selectedElement = "")
                  },
                  input: _vm.handleSaveElement
                }
              })
            ],
            1
          ),
          !_vm.loading
            ? _c("TemplateEditSidebar", {
                key: _vm.selectedSlide + "-" + _vm.elementsKey,
                staticClass: "template-edit-content-sidebar",
                attrs: {
                  elements: _vm.currentSlideElements,
                  selected: _vm.selectedElement,
                  hovered: _vm.hoveredElement,
                  name: _vm.name,
                  description: _vm.description,
                  "info-loading": _vm.loadingInfoEdit,
                  "is-edit": _vm.isEdit
                },
                on: {
                  updateInfo: _vm.handleSaveInfo,
                  hover: function(id) {
                    return (_vm.hoveredElement = id)
                  },
                  select: function(id) {
                    return (_vm.selectedElement = id)
                  },
                  test: _vm.handleTestShape
                }
              })
            : _c("TemplateEditSidebarLoading", {
                staticClass: "template-edit-content-sidebar",
                attrs: { name: _vm.name, description: _vm.description }
              })
        ],
        1
      ),
      !!_vm.showTestModal
        ? _c("TemplateEditTest", {
            attrs: {
              "output-type": _vm.outputType,
              "template-id": _vm.templateId,
              "slide-id": _vm.selectedSlide,
              "shape-id":
                _vm.showTestModal === "shape" ? _vm.testElement.uuid : ""
            },
            on: {
              close: function() {
                return (_vm.showTestModal = "")
              }
            }
          })
        : _vm._e(),
      _vm.addBlockModal
        ? _c("TemplateEditAdd", {
            on: {
              close: function() {
                return (_vm.addBlockModal = "")
              },
              submit: _vm.addBlock
            }
          })
        : _vm._e(),
      _vm.editBlockModal
        ? _c("TemplateEditAdd", {
            attrs: { "current-block": _vm.editBlockModal },
            on: {
              close: function() {
                return (_vm.editBlockModal = undefined)
              },
              submit: _vm.editBlock
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }