<template>
  <div class="template-edit">
    <div class="template-edit-header">
      <Button icon="arrow-left" type="grey" @click="goBack" />
      <div class="template-edit-header-info">
        <p class="template-edit-header-info-name">{{ name }}</p>
        <p class="template-edit-header-info-description">{{ description }}</p>
      </div>
      <div class="template-edit-header-btns">
        <div v-if="isEdit && template && outputType">
          <PortfolioTemplatePublish
            v-if="!tool"
            :template="template"
            :type="outputType"
            :tool="tool"
            @publish="handlePublish"
          />
          <PitchTemplatePublish
            v-else
            :template="tool"
            @publish="handlePublish"
          />
        </div>
        <Button
          v-if="outputType"
          :text="testButtonText"
          :disabled="testButtonDisabled"
          @click="handleTestSlide"
        />
      </div>
    </div>
    <div
      ref="templatecreatecontent"
      class="template-edit-content"
      :style="maxHeightStyle"
    >
      <TemplateEditSlides
        v-if="!loading"
        :slides="slides"
        :blocks="blocks"
        :selected="selectedSlide"
        :show-add="!!tool"
        :is-edit="isEdit"
        :template-id="templateId"
        class="template-edit-content-slides"
        @select="handleSelectSlide"
        @add="handleAddBlock"
        @edit="handleEditBlock"
        @remove="removeBlock"
      />
      <TemplateEditSlidesLoading v-else class="template-edit-content-slides" />
      <div class="template-edit-content-center">
        <TemplateEditPreview
          :slide="currentSlide"
          :elements="currentSlide.shapes"
          :selected="selectedElement"
          :hovered="hoveredElement"
          :loading="loading"
          @select="(id) => (selectedElement = id)"
          @hover="(id) => (hoveredElement = id)"
        />
        <TemplateElementEdit
          :element="currentElement"
          :uuid="selectedElement"
          :loading="loadingElementEdit"
          :output-type="outputType"
          :is-edit="isEdit"
          class="template-edit-element-edit"
          :class="{ active: !!selectedElement }"
          @close="() => (selectedElement = '')"
          @input="handleSaveElement"
        />
      </div>
      <TemplateEditSidebar
        v-if="!loading"
        :key="`${selectedSlide}-${elementsKey}`"
        :elements="currentSlideElements"
        :selected="selectedElement"
        :hovered="hoveredElement"
        :name="name"
        :description="description"
        :info-loading="loadingInfoEdit"
        :is-edit="isEdit"
        class="template-edit-content-sidebar"
        @updateInfo="handleSaveInfo"
        @hover="(id) => (hoveredElement = id)"
        @select="(id) => (selectedElement = id)"
        @test="handleTestShape"
      />
      <TemplateEditSidebarLoading
        v-else
        :name="name"
        :description="description"
        class="template-edit-content-sidebar"
      />
    </div>
    <TemplateEditTest
      v-if="!!showTestModal"
      :output-type="outputType"
      :template-id="templateId"
      :slide-id="selectedSlide"
      :shape-id="showTestModal === 'shape' ? testElement.uuid : ''"
      @close="() => (showTestModal = '')"
    />
    <TemplateEditAdd
      v-if="addBlockModal"
      @close="() => (addBlockModal = '')"
      @submit="addBlock"
    />
    <TemplateEditAdd
      v-if="editBlockModal"
      :current-block="editBlockModal"
      @close="() => (editBlockModal = undefined)"
      @submit="editBlock"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TemplateEditAdd from './edit/TemplateEditAdd.vue'
import TemplateEditTest from './edit/TemplateEditTest.vue'
import TemplateEditSlides from './edit/TemplateEditSlides.vue'
import TemplateEditSidebar from './edit/TemplateEditSidebar.vue'
import TemplateElementEdit from './edit/TemplateElementEdit.vue'
import TemplateEditPreview from './edit/TemplateEditPreview.vue'
import PitchTemplatePublish from './PitchTemplatePublish.vue'
import PortfolioTemplatePublish from '../portfolio_templates/PortfolioTemplatePublish.vue'
import TemplateEditSlidesLoading from './edit/TemplateEditSlidesLoading.vue'
import TemplateEditSidebarLoading from './edit/TemplateEditSidebarLoading.vue'
import { mapGetters } from 'vuex'
import {
  createKeyContentTemplateBlock,
  editKeyContentTemplate,
  editKeyContentTemplateBlock,
  editKeyContentTemplateShape,
  parseKeyContentTemplateInput
} from '@/services/keyContentService'
import { mapActions } from 'vuex'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'EditPresentationTemplate',
  components: {
    Button,
    TemplateEditAdd,
    TemplateEditTest,
    TemplateEditSlides,
    TemplateEditSidebar,
    TemplateElementEdit,
    TemplateEditPreview,
    PitchTemplatePublish,
    PortfolioTemplatePublish,
    TemplateEditSlidesLoading,
    TemplateEditSidebarLoading
  },
  mixins: [MaxHeightMixin],
  props: {
    templateId: {
      type: String,
      default: undefined
    },
    tool: {
      type: Object,
      default: () => undefined
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    name: '',
    description: '',
    outputType: '',
    loading: false,
    loadingInfoEdit: false,
    loadingElementEdit: false,
    slides: [],
    blocks: [],
    selectedSlide: '',
    testElement: '',
    selectedElement: '',
    hoveredElement: '',
    showTestModal: '',
    template: undefined,
    elementsKey: 0,
    addBlockModal: '',
    editBlockModal: undefined
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    currentSlide() {
      return (
        this.slides.find((slide) => slide.uuid === this.selectedSlide) || {}
      )
    },
    currentSlideElements() {
      return this.currentSlide.shapes || []
    },
    currentElement() {
      return this.currentSlideElements.find(
        (element) => element.uuid === this.selectedElement
      )
    },
    routePadded() {
      return this.$route.meta.noscroll
    },
    testButtonText() {
      const type =
        {
          offering: 'offering',
          reference: 'case',
          inspiration: 'inspiration',
          story: 'meeting'
        }[this.outputType] || ''
      return `Test on existing ${type}`
    },
    testButtonDisabled() {
      this.elementsKey
      return !this.slides.some((s) =>
        (s?.shapes || []).some((e) => !!e.prompt || !!e.image)
      )
    }
  },
  watch: {
    routePadded() {
      this.checkMaxHeight()
    }
  },
  created() {
    this.initTemplate()
  },
  methods: {
    ...mapActions(['editTemplate']),
    getMaxHeightElement() {
      return this.$refs.templatecreatecontent
    },
    async initTemplate() {
      try {
        this.loading = true
        this.template = await parseKeyContentTemplateInput({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.templateId
        })
        const { name, description, slides, output_type, blocks } = this.template
        this.name = name
        this.description = description
        this.slides = slides
        this.outputType = output_type
        this.selectedSlide = slides[0].uuid
        this.blocks = blocks || []
      } catch (e) {
        this.$console.debug('Error initialising key content template', e)
        this.$toast.error(e, 'loading the template')
      } finally {
        this.loading = false
      }
    },
    handleSelectSlide(uuid) {
      this.selectedSlide = uuid
      this.selectedElement = ''
    },
    async handleSaveInfo(name, description) {
      try {
        this.loadingInfoEdit = true
        const changes = {
          ...(name === this.name ? {} : { name }),
          ...(description === this.description ? {} : { description })
        }
        await Promise.all([
          editKeyContentTemplate({
            workspace_id: this.currentWorkspace.uuid,
            template_id: this.templateId,
            ...changes
          }),
          ...(this.tool
            ? [
                this.editTemplate({
                  workspace_id: this.currentWorkspace.uuid,
                  template_id: this.tool.uuid,
                  ...changes
                })
              ]
            : [])
        ])
        this.name = name
        this.description = description
        this.$toast.success('Template info saved')
        this.$emit('update', {
          ...changes,
          uuid: this.templateId,
          ...(this.tool ? { tool_id: this.tool.uuid } : {})
        })
      } catch (e) {
        this.$console.debug('Error saving template info', e)
        this.$toast.error(e, 'saving the template')
      } finally {
        this.loadingInfoEdit = false
      }
    },
    async handleSaveElement(el) {
      let newShape = undefined
      const selectedSlideIdx = this.slides.findIndex(
        (slide) => slide.uuid === this.selectedSlide
      )
      if (selectedSlideIdx === -1) return
      const selectedElementIdx = (
        this.slides[selectedSlideIdx].shapes || []
      ).findIndex((element) => element.uuid === this.selectedElement)
      if (selectedElementIdx === -1) return
      try {
        this.loadingElementEdit = true
        newShape = await editKeyContentTemplateShape({
          workspace_id: this.currentWorkspace.uuid,
          template_id: this.templateId,
          slide_id: this.selectedSlide,
          shape_id: this.selectedElement,
          ...el
        })
        this.slides[selectedSlideIdx].shapes[selectedElementIdx] = newShape
        this.$toast.success('Element edited')
        this.selectedElement = ''
        this.elementsKey++
      } catch (e) {
        this.$console.debug('Error saving template element', e)
        this.$toast.error(e, 'saving the element')
      } finally {
        this.loadingElementEdit = false
      }
    },
    handlePublish(template) {
      if (this.tool) {
        this.$emit('publish', template)
        return
      }
      this.$emit('update', template)
      this.goBack()
    },
    handleTestSlide() {
      this.showTestModal = 'slide'
    },
    handleTestShape(element) {
      this.testElement = element
      this.showTestModal = 'shape'
    },
    goBack() {
      this.$router.go(-1)
    },
    handleAddBlock(idx, position) {
      this.addBlockModal = `${idx}-${position}`
    },
    async addBlock({ templates, type }) {
      try {
        const [slide_index, position] = this.addBlockModal.split('-')
        const block = await createKeyContentTemplateBlock({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.templateId,
          slide_index: new Number(slide_index) + 1,
          position,
          output_type: type,
          selections: templates.map((t, idx) => ({
            template_id: t.uuid,
            position: idx
          }))
        })
        this.blocks.map((b) =>
          b.slide_index === slide_index + 1 && b.position >= position
            ? { ...b, position: b.position++ }
            : b
        )
        this.blocks.push(block)
      } catch (e) {
        this.$console.debug('Error adding block', e)
        this.$toast.error(e, 'adding the block')
      } finally {
        this.addBlockModal = ''
      }
    },
    handleEditBlock(idx, position) {
      const block = this.blocks.find(
        (b) => b.slide_index - 1 === idx && b.position === position
      )
      this.editBlockModal = block
    },
    async editBlock({ templates, type }) {
      try {
        const block = this.editBlockModal
        const newBlock = await editKeyContentTemplateBlock({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.templateId,
          block_id: block.uuid,
          ...(type === block.output_type ? {} : { output_type: type }),
          selections: templates.map((t, idx) => ({
            template_id: t.uuid,
            position: idx
          }))
        })
        this.blocks = this.blocks.map((b) =>
          b.uuid === newBlock.uuid ? newBlock : b
        )
      } catch (e) {
        this.$console.debug('Error editing block', e)
        this.$toast.error(e, 'editing the block')
      } finally {
        this.editBlockModal = undefined
      }
    },
    editSection(templates) {
      this.slides[this.editSectionModal.idx].templates = templates
      this.editSectionModal = undefined
    },
    removeBlock(block) {
      this.blocks = this.blocks.filter((b) => b.uuid !== block.uuid)
    }
  }
}
</script>

<style lang="scss" scoped>
.template-edit {
  background: #f9f9fa;

  &-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1.75rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    background: white;

    &-info {
      &-name {
        font-weight: 700;
      }

      &-description {
        color: #60666b;
        max-width: 50vw;
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      margin-left: auto;
      color: #60666b;
    }
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;

    &-slides {
      flex: 2;
    }

    &-center {
      flex: 5;
      height: 100%;
      width: 100%;
      position: relative;
    }

    &-sidebar {
      flex: 2;
    }
  }

  &-element-edit {
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    transition: bottom 0.3s ease;

    &.active {
      bottom: 0;
    }
  }

  &-upload {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    height: 100%;

    &-text {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 0.5rem;

      &-title {
        font-size: 2rem;
        font-weight: 700;
      }

      &-subtitle {
        color: #60666b;
      }
    }

    &-dropzone {
      height: 50vh;
      width: min(50rem, 50vw);
      background: rgba(#000, 0.04);
    }
  }
}
</style>
