<template>
  <div class="template-sidebar">
    <div class="template-sidebar-section">
      <p class="template-sidebar-section-title">Elements</p>
      <div class="template-sidebar-section-list">
        <b-skeleton
          v-for="idx in 4"
          :key="`template-list-loading-${idx}`"
          height="4rem"
          width="100%"
        />
      </div>
    </div>
    <div class="template-sidebar-section">
      <p class="template-sidebar-section-title">Template info</p>
      <TextInput
        v-model="currentName"
        title="Name"
        :disabled="true"
        placeholder="Template name"
      />
      <TextInput
        v-model="currentDescription"
        title="Description"
        :disabled="true"
        placeholder="Describe your template"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'

export default {
  name: 'TemplateEditSidebarLoading',
  components: { TextInput },
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    currentName: '',
    currentDescription: ''
  }),
  created() {
    this.currentName = this.name
    this.currentDescription = this.description
  }
}
</script>

<style lang="scss" scoped>
.template-sidebar {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  gap: 1rem;
  padding: 1.5rem;
  background: white;
  border-left: 1px solid rgba(#000, 0.08);
  border-right: 1px solid rgba(#000, 0.08);

  &-section {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-title {
      font-weight: 600;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
    }
  }
}
</style>
