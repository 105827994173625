<template>
  <div class="integrations">
    <img src="@/assets/icons/settings.svg" alt="" class="integrations-icon" />
    <p class="integrations-header">
      We're working on a brand new look for this page
    </p>
    <p class="integrations-subheader">
      In the meantime, please use the modal to change your integration settings
    </p>
    <p class="integrations-button" @click="openIntegrationModal">Open modal</p>
  </div>
</template>

<script>
import {
  crmIntegrations,
  filestorageIntegrations,
  messagingIntegrations
} from '@c/integrations'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WorkspaceIntegrationSettings',
  data: () => ({
    active: []
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    integrations() {
      return Object.values({
        ...crmIntegrations,
        ...filestorageIntegrations,
        ...messagingIntegrations
      })
    },
    activeIntegrations() {
      return this.integrations.filter((integration) =>
        this.active.some((active) => active.type === integration.key)
      )
    },
    inactiveIntegrations() {
      return this.integrations.filter(
        (integration) =>
          !this.active.some((active) => active.type === integration.key)
      )
    }
  },
  async created() {
    // this.active = await this.getIntegrations({
    //   workspace_id: this.$route.params.workspace_id
    // })
  },
  methods: {
    ...mapActions(['getIntegrations']),
    openIntegrationModal() {
      this.$modals.workspace.openSettings(this, this.currentWorkspace)
    }
  }
}
</script>

<style scoped lang="scss">
.integrations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40vh;

  &-icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
    animation: spinning 2s linear infinite;
  }

  &-header {
    font-size: 1.5rem;
  }

  &-subheader {
    font-size: 16px;
    color: #60666b;
  }

  &-button {
    margin-top: 2rem;
    font-weight: 600;
    color: white;
    background-color: $primary;
    border-radius: 999rem;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: darken($primary, 10%);
    }
  }
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
