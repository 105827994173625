<template>
  <div v-if="error" class="key-content-status">
    <div class="key-content-status-img">
      <img
        src="@/assets/icons/check-warning.svg"
        alt=""
        class="key-content-status-img-icon"
      />
    </div>
    <div class="key-content-status-content">
      <span>Something went wrong while loading your key content templates</span>
      <span>Please try again later or contact support</span>
    </div>
    <Button text="Retry" @click="() => $emit('retry')" />
  </div>
  <div v-else-if="empty" class="key-content-status">
    <div class="key-content-status-img">
      <img
        src="@/assets/icons/key.svg"
        alt=""
        class="key-content-status-img-icon"
      />
    </div>
    <div class="key-content-status-content">
      <span
        >No templates for {{ tab.name.toLowerCase() }} key content have been
        added yet</span
      >
      <span
        >We will generate the content specified here for all
        {{ tab.name.toLowerCase() }}</span
      >
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'

export default {
  name: 'PortfolioTemplateStatus',
  components: {
    Button
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },
    tab: {
      type: Object,
      default: () => ({ content: [] })
    }
  }
}
</script>

<style lang="scss" scoped>
.key-content-status {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;
  color: #60666b;
  padding: 2rem;

  &-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    gap: 0.25rem;
  }

  &-img {
    padding: 0.5rem;
    border-radius: 6px;
    background: rgba(#000, 0.3);

    &-icon {
      width: 2rem;
      height: 2rem;
      filter: brightness(0) invert(1);
      display: block;
    }
  }
}
</style>
