var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "sidebar-meetings" }, [
      _c(
        "div",
        {
          staticClass: "sidebar-meetings-header",
          class: { expanded: _vm.meetingsExpanded },
          on: {
            click: function() {
              return (_vm.meetingsExpanded = !_vm.meetingsExpanded)
            }
          }
        },
        [
          _c("img", {
            staticClass: "sidebar-meetings-header-icon",
            attrs: {
              src: require("@/assets/icons/chevron-down-medium.svg"),
              alt: ""
            }
          }),
          _vm.meetingsLoading
            ? _c("div", { staticClass: "sidebar-meetings-header-info" }, [
                _c("p", { staticClass: "sidebar-meetings-header-title" }, [
                  _vm._v("Meetings loading...")
                ]),
                !_vm.meetingsExpanded
                  ? _c("img", {
                      staticClass: "sidebar-meetings-header-loading",
                      attrs: { src: "", alt: "" }
                    })
                  : _vm._e()
              ])
            : _c(
                "div",
                { staticClass: "sidebar-meetings-header-info" },
                [
                  _c("Avatar", {
                    attrs: {
                      user: { avatar: _vm.getImage(_vm.selectedMeeting) },
                      size: "xs",
                      "fallback-image": "company"
                    }
                  }),
                  _vm._v(" " + _vm._s(_vm.selectedMeeting.title) + " ")
                ],
                1
              ),
          _c("p", { staticClass: "sidebar-meetings-header-name" }, [
            _vm._v("Test meeting")
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "sidebar-meetings-list",
          class: { expanded: _vm.meetingsExpanded }
        },
        [
          _vm.meetingsLoading
            ? _c("div", { staticClass: "sidebar-meetings-list-loading" }, [
                _c("img", {
                  staticClass: "sidebar-meetings-list-loading-icon",
                  attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
                }),
                _vm._v(" Loading meetings... ")
              ])
            : _vm._e(),
          _vm._l(_vm.meetings, function(meeting) {
            return _c(
              "div",
              {
                key: meeting.uuid,
                staticClass: "sidebar-meetings-list-item",
                class: { selected: meeting.uuid === _vm.selectedMeeting.uuid },
                on: {
                  click: function() {
                    return _vm.selectMeeting(meeting)
                  }
                }
              },
              [
                _c("Avatar", {
                  attrs: {
                    user: { avatar: _vm.getImage(meeting) },
                    "fallback-icon": "company",
                    size: "xs"
                  }
                }),
                _c("p", { staticClass: "sidebar-meetings-list-item-title" }, [
                  _vm._v(_vm._s(meeting.title))
                ])
              ],
              1
            )
          })
        ],
        2
      )
    ]),
    _c("p", { staticClass: "sidebar-header" }, [_vm._v("Prompt")]),
    _c(
      "div",
      { staticClass: "sidebar-content" },
      [
        _c("TextArea", {
          attrs: {
            disabled: _vm.disabled,
            "max-height": "50vh",
            placeholder: "Enter prompt"
          },
          model: {
            value: _vm.prompt,
            callback: function($$v) {
              _vm.prompt = $$v
            },
            expression: "prompt"
          }
        }),
        _vm._v(" "),
        _c("TextInput", {
          attrs: {
            title: "Name",
            placeholder: "Template name",
            "max-length": 50
          },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _c("TextArea", {
          attrs: {
            title: "Description",
            placeholder: "Template description",
            "max-length": 100
          },
          model: {
            value: _vm.description,
            callback: function($$v) {
              _vm.description = $$v
            },
            expression: "description"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "sidebar-footer" },
      [
        _c("Button", {
          attrs: {
            text: "Save & Generate",
            loading: _vm.loading,
            disabled: _vm.disableSave,
            "full-width": true
          },
          on: { click: _vm.submit }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }