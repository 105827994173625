var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ambassadors" }, [
    _vm._m(0),
    _vm.integration
      ? _c(
          "div",
          { staticClass: "ambassadors-add" },
          [
            _c("TextInput", {
              staticClass: "ambassadors-add-input",
              attrs: {
                title: "Add new ambassador",
                placeholder: "https://www.linkedin.com/in/...",
                disabled: _vm.ambassadorsLoading || !_vm.integration
              },
              on: { submit: _vm.addAmbassador },
              model: {
                value: _vm.linkedinUrl,
                callback: function($$v) {
                  _vm.linkedinUrl = $$v
                },
                expression: "linkedinUrl"
              }
            }),
            _c("Button", {
              attrs: {
                text: "Add ambassador",
                disabled:
                  !_vm.linkedinUrl ||
                  !_vm.integration ||
                  _vm.ambassadorsLoading,
                loading: _vm.addAmbassadorLoading
              },
              on: { click: _vm.addAmbassador }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "ambassadorlist",
        staticClass: "ambassadors-list",
        style: _vm.maxHeightStyle
      },
      [
        _vm._l(_vm.ambassadors, function(ambassador) {
          return _c(
            "div",
            { key: ambassador.uuid, staticClass: "ambassadors-list-item" },
            [
              _c("Avatar", {
                attrs: { user: ambassador, "fallback-icon": "user", size: "m" }
              }),
              _c("div", { staticClass: "ambassadors-list-item-info" }, [
                _vm._v(" " + _vm._s(_vm.$umodel.full_name(ambassador)) + " "),
                ambassador.role
                  ? _c(
                      "span",
                      { staticClass: "ambassadors-list-item-info-role" },
                      [_vm._v(_vm._s(ambassador.role))]
                    )
                  : _vm._e()
              ]),
              ambassador.url
                ? _c("img", {
                    staticClass: "ambassadors-list-item-linkedin",
                    attrs: {
                      src: require("@/assets/logo/linkedin.svg"),
                      alt: ""
                    },
                    on: {
                      click: function() {
                        return _vm.gotoLinkedIn(ambassador)
                      }
                    }
                  })
                : _vm._e(),
              ambassador.status !== "unsupported"
                ? _c("StatusOrb", {
                    attrs: {
                      type: _vm.statusColor(ambassador),
                      text: "Contact sync " + ambassador.status
                    }
                  })
                : _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label:
                          "This account is private, we can't get this person's contacts",
                        type: "is-dark",
                        position: _vm.tooltipAlignment,
                        multilined: ""
                      },
                      nativeOn: {
                        mouseover: function($event) {
                          return _vm.checkAlignment.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "ambassadors-list-item-warning",
                        attrs: {
                          src: require("@/assets/icons/check-warning.svg"),
                          alt: ""
                        }
                      })
                    ]
                  ),
              _c(
                "div",
                { staticClass: "ambassadors-list-item-actions" },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: "Remove",
                        position: "is-top",
                        type: "is-dark"
                      }
                    },
                    [
                      _c("Button", {
                        attrs: {
                          text: "Remove",
                          icon: "bin",
                          type: "white",
                          loading:
                            _vm.removeAmbassadorLoading === ambassador.uuid
                        },
                        on: {
                          click: function() {
                            return _vm.removeAmbassador(ambassador)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        !_vm.ambassadorsLoading && !_vm.ambassadors.length
          ? _c("div", { staticClass: "ambassadors-list-status" }, [
              !_vm.integration
                ? _c(
                    "div",
                    [
                      _vm._v(
                        " Please connect your LinkedIn account to uman before adding ambassadors. This helps us find all their valuable connections that can turn into warm introductions. "
                      ),
                      _c("Button", {
                        attrs: { text: "Connect LinkedIn" },
                        on: { click: _vm.openIntegrations }
                      })
                    ],
                    1
                  )
                : _vm.ambassadorsError
                ? _c(
                    "div",
                    [
                      _vm._v(
                        " Something went wrong while trying to load your ambassadors. Please try again later or contact support. "
                      ),
                      _c("Button", {
                        attrs: { text: "Retry" },
                        on: { click: _vm.loadAmbassadors }
                      })
                    ],
                    1
                  )
                : _c("div", [
                    _vm._v(
                      " No ambassadors have been added yet. Add ambassadors with their personal LinkedIn url to get warm introduction recommendations. "
                    )
                  ])
            ])
          : _vm.ambassadorsLoading
          ? _c(
              "div",
              { staticClass: "ambassadors-list-loading" },
              [
                _c("b-loading", {
                  attrs: { active: "", "is-full-page": false }
                })
              ],
              1
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ambassadors-header" }, [
      _c("p", { staticClass: "ambassadors-header-title" }, [
        _vm._v("Prospecting ambassadors")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }