var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-table-wrapper" },
    [
      _c(
        "div",
        { staticClass: "member-table-filters" },
        _vm._l(_vm.roleFilters, function(filter) {
          return _c("Button", {
            key: filter.uuid,
            attrs: {
              text: filter.name,
              type: _vm.activeFilter === filter.uuid ? "light-solid" : "white"
            },
            on: {
              click: function() {
                return _vm.selectFilter(filter)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function() {
                    return [
                      _c("Tag", {
                        attrs: {
                          text: "" + filter.member_count,
                          type: "light-solid",
                          size: "xxs"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        }),
        1
      ),
      _c(
        "b-table",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: function() {
                return _vm.$emit("loadMore")
              },
              expression: "() => $emit('loadMore')"
            }
          ],
          staticClass: "member-table",
          attrs: {
            data: _vm.data,
            hoverable: "",
            loading: _vm.loading,
            total: _vm.amountOfMembers,
            sortable: "",
            scrollable: "",
            "sort-icon": "chevron-up",
            "mobile-cards": false,
            "infinite-scroll-disabled": "loading",
            "infinite-scroll-distance": "1000"
          }
        },
        [
          _c("b-table-column", {
            attrs: {
              field: "member",
              "cell-class": "vertical-centered",
              "header-class": "member-table-header-wrapper"
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c("p", { staticClass: "member-table-header" }, [
                      _vm._v("Name")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("UserAvatarWithText", {
                      staticClass: "full-width",
                      attrs: {
                        username: _vm.$umodel.full_name(props.row.user),
                        avatar: props.row.user.avatar
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("b-table-column", {
            attrs: {
              field: "email",
              "cell-class": "vertical-centered",
              "header-class": "member-table-header-wrapper"
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c("p", { staticClass: "member-table-header" }, [
                      _vm._v("Email")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$umodel.email(props.row.user)) + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("b-table-column", {
            attrs: {
              field: "role",
              "cell-class": "vertical-centered",
              "header-class": "member-table-header-wrapper"
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c("p", { staticClass: "member-table-header" }, [
                      _vm._v("Roles")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("RoleSelect", {
                      attrs: {
                        value: props.row.roles,
                        "fixed-width": "12.5rem"
                      },
                      on: {
                        submit: function(roles) {
                          return _vm.$emit("updateRoles", {
                            member: props.row,
                            roles: roles
                          })
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("b-table-column", {
            attrs: {
              field: "impersonate",
              "cell-class": "vertical-centered",
              "header-class": "member-table-header-wrapper"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.isUman &&
                              !_vm.isCurrentUser(props.row) &&
                              !_vm.isImpersonatedMember(props.row),
                            expression:
                              "\n          isUman &&\n          !isCurrentUser(props.row) &&\n          !isImpersonatedMember(props.row)\n        "
                          }
                        ],
                        attrs: { size: "is-small", type: "is-primary" },
                        on: {
                          click: function() {
                            return _vm.impersonate(props.row)
                          }
                        }
                      },
                      [_vm._v("Impersonate")]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.isUman && _vm.isImpersonatedMember(props.row),
                            expression:
                              "isUman && isImpersonatedMember(props.row)"
                          }
                        ],
                        attrs: { size: "is-small", type: "is-danger" },
                        on: {
                          click: function() {
                            return _vm.impersonate(undefined)
                          }
                        }
                      },
                      [_vm._v(" Stop impersonation ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("b-table-column", {
            attrs: {
              field: "kebab",
              "cell-class": "vertical-centered",
              "header-class": "member-table-header-wrapper"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("Dropdown", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isCurrentUser(props.row),
                          expression: "!isCurrentUser(props.row)"
                        }
                      ],
                      attrs: {
                        items: [
                          {
                            text: "Remove member",
                            icon: "bin",
                            filter: "red",
                            callback: function() {
                              return _vm.$emit("removeMember", props.row)
                            }
                          }
                        ],
                        "position-fixed": true
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }