<template>
  <div class="sidebar">
    <div class="sidebar-meetings">
      <div
        class="sidebar-meetings-header"
        :class="{ expanded: meetingsExpanded }"
        @click="() => (meetingsExpanded = !meetingsExpanded)"
      >
        <img
          src="@/assets/icons/chevron-down-medium.svg"
          alt=""
          class="sidebar-meetings-header-icon"
        />
        <div v-if="meetingsLoading" class="sidebar-meetings-header-info">
          <p class="sidebar-meetings-header-title">Meetings loading...</p>
          <img
            v-if="!meetingsExpanded"
            src=""
            alt=""
            class="sidebar-meetings-header-loading"
          />
        </div>
        <div v-else class="sidebar-meetings-header-info">
          <Avatar
            :user="{ avatar: getImage(selectedMeeting) }"
            size="xs"
            fallback-image="company"
          />
          {{ selectedMeeting.title }}
        </div>
        <p class="sidebar-meetings-header-name">Test meeting</p>
      </div>
      <div
        class="sidebar-meetings-list"
        :class="{ expanded: meetingsExpanded }"
      >
        <div v-if="meetingsLoading" class="sidebar-meetings-list-loading">
          <img
            src="@/assets/icons/spinner.svg"
            alt=""
            class="sidebar-meetings-list-loading-icon"
          />
          Loading meetings...
        </div>
        <div
          v-for="meeting in meetings"
          :key="meeting.uuid"
          class="sidebar-meetings-list-item"
          :class="{ selected: meeting.uuid === selectedMeeting.uuid }"
          @click="() => selectMeeting(meeting)"
        >
          <Avatar
            :user="{ avatar: getImage(meeting) }"
            fallback-icon="company"
            size="xs"
          />
          <p class="sidebar-meetings-list-item-title">{{ meeting.title }}</p>
        </div>
      </div>
    </div>
    <p class="sidebar-header">Prompt</p>
    <div class="sidebar-content">
      <TextArea
        v-model="prompt"
        :disabled="disabled"
        max-height="50vh"
        placeholder="Enter prompt"
      />
      <TextInput
        v-model="name"
        title="Name"
        placeholder="Template name"
        :max-length="50"
      />
      <TextArea
        v-model="description"
        title="Description"
        placeholder="Template description"
        :max-length="100"
      />
    </div>
    <div class="sidebar-footer">
      <Button
        text="Save & Generate"
        :loading="loading"
        :disabled="disableSave"
        :full-width="true"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import TextArea from '@c/library/TextArea.vue'
import TextInput from '@c/library/TextInput.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Avatar,
    TextArea,
    TextInput,
    Button
  },
  props: {
    prevTemplate: {
      type: Object,
      default: () => undefined
    },
    meetings: {
      type: Array,
      default: () => []
    },
    meetingsLoading: {
      type: Boolean,
      default: false
    },
    selectedMeeting: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prompt: '',
      name: 'New template',
      description: '',
      template: undefined,
      loading: false,
      meetingsExpanded: false
    }
  },
  computed: {
    isEdit() {
      return !!this.template
    },
    disableSave() {
      return this.isEdit
        ? this.prompt === this.template.content &&
            this.name === this.template.name &&
            this.description === this.template.description
        : !(this.prompt && this.name)
    }
  },
  watch: {
    prevTemplate: {
      handler() {
        this.initState()
      },
      deep: true
    }
  },
  created() {
    this.initState()
  },
  methods: {
    ...mapActions(['createTemplate', 'editTemplate']),
    generate() {
      this.$emit('generate')
    },
    async submit() {
      const regenerate = await this.saveTemplate()
      if (regenerate) this.generate()
    },
    getImage(meeting) {
      const org = meeting.account?.organisation
      return org?.logo || org?.icon || ''
    },
    initState() {
      this.prompt = this.prevTemplate?.content || ''
      this.name = this.prevTemplate?.name || ''
      this.description = this.prevTemplate?.description || ''
      this.template = this.prevTemplate
    },
    async saveTemplate() {
      let res = false
      try {
        this.loading = true
        const saveFunction = this.isEdit
          ? this.editTemplate
          : this.createTemplate
        let props = {
          workspace_id: this.$route.params.workspace_id
        }
        ;['name', 'description', 'prompt'].forEach((prop) => {
          if (
            !this.isEdit ||
            this[prop] !== this.template[prop === 'prompt' ? 'content' : prop]
          ) {
            props[prop] = this[prop]
          }
        })
        if (this.isEdit) props.template_id = this.template.uuid
        const template = await saveFunction(props)
        this.template = template
        this.$toast.success('Template saved')
        res = Object.keys(props).includes('prompt')
      } catch (e) {
        this.$console.debug('Error while saving template', e)
        this.$toast.error(e, 'saving template')
      } finally {
        this.loading = false
      }
      return res
    },
    selectMeeting(meeting) {
      this.$emit('meeting', meeting)
      this.meetingsExpanded = false
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  position: relative;

  &-header {
    padding: 1.5rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &-content {
    padding: 0 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }

  &-footer {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;

    &-note {
      color: #60666b;
      margin-left: 1rem;
    }
  }

  &-meetings {
    border-bottom: 1px solid rgba(#000, 0.08);

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
      padding: 1rem 1.5rem;
      cursor: pointer;

      &.expanded {
        & .sidebar-meetings-header-icon {
          transform: rotate(180deg);
        }
      }

      &-icon {
        height: 1.5rem;
        transition: transform 0.2s;
      }

      &-info {
        display: contents;
      }

      &-name {
        color: #60666b;
        font-size: 0.875rem;
        margin-left: auto;
      }

      &-loading {
        height: 1.5rem;
      }
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;

      &.expanded {
        max-height: 20rem;
        overflow: auto;
      }

      &-loading {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.5rem;
        color: #60666b;

        &-icon {
          height: 1.2rem;
          animation: spin 1s linear infinite;
          opacity: 0.75rem;
        }
      }

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        cursor: pointer;

        &.selected {
          background: rgba(#000, 0.04);
        }

        &:hover {
          background: rgba(#000, 0.04);
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
