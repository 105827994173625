<template>
  <div class="invites-modal">
    <p class="invites-modal-header">Invite members</p>
    <div ref="invitetable" class="invites-modal-table-wrapper">
      <table class="invites-modal-table">
        <tr class="invites-modal-headers">
          <th class="invites-modal-headers-header">Email address</th>
          <th class="invites-modal-headers-header">Role</th>
        </tr>
        <tr v-for="(row, idx) in invites" :key="idx" class="invites-modal-row">
          <td class="invites-modal-row-email">
            <TextInput
              v-model="invites[idx].email"
              placeholder="john.doe@email.com"
              @input="() => handleInput(idx)"
            />
          </td>
          <td class="invites-modal-row-role">
            <RoleSelect
              :value="invites[idx].roles"
              :full-width="true"
              @submit="(r) => (invites[idx].roles = r)"
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="invites-modal-btns">
      <Button
        type="white"
        text="Cancel"
        :disabled="sendLoading"
        @click="$emit('close')"
      />
      <Button
        icon="mail"
        text="Send invites"
        :loading="sendLoading"
        @click="sendInvites"
      />
    </div>
  </div>
</template>

<script>
import { postInvite } from '@/services/inviteService'
import Button from '@c/library/Button.vue'
import RoleSelect from '@c/library/RoleSelect.vue'
import TextInput from '@c/library/TextInput.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkspaceMemberInviteModal',
  components: {
    TextInput,
    RoleSelect,
    Button
  },
  data: () => ({
    invites: [],
    sendLoading: false
  }),
  computed: {
    ...mapGetters(['roles'])
  },
  created() {
    this.addEmptyInvite()
  },
  methods: {
    addEmptyInvite() {
      this.invites.push({
        email: '',
        roles: this.roles.filter((r) => r.is_default)
      })
    },
    async sendInvites() {
      const invites = this.invites.filter((i) => i.email)
      if (!invites?.length) this.$emit('close')
      try {
        this.sendLoading = true
        this.$console.debug('Sending invites', invites)
        const uniqueInvites = invites.filter(
          (i, idx) => invites.findIndex((i2) => i2.email === i.email) === idx
        )
        const promises = uniqueInvites.map((invite) =>
          postInvite(
            this.$route.params.workspace_id,
            invite.email,
            invite.roles.map((r) => r.uuid)
          ).catch((e) =>
            e.email ? { email_error: invite.email } : { error: e }
          )
        )
        const createdInvites = await Promise.all(promises)
        if (createdInvites.some((i) => i.error || i.email_error)) {
          const errored = createdInvites.filter((i) => i.error || i.email_error)
          const existing = errored
            .filter((i) => i.email_error)
            .map((i) => i.email_error)
          const allErrored = errored.length === createdInvites.length
          this.$toast.danger(
            `${allErrored ? 'Your' : 'Some'} invites could not be sent`,
            existing.length
              ? `The people with these emails are already a user: ${existing.join(
                  ', '
                )}${
                  existing.length === errored.length && allErrored
                    ? '.'
                    : '. Your other invites were sent successfully.'
                }`
              : 'Something went wrong while sending your invites. Please try again later or contact support.'
          )
          const nonErrored = createdInvites.filter(
            (i) => !i.error && !i.email_error
          )
          if (nonErrored.length) this.$emit('sendInvites', nonErrored)
          return
        }
        this.$toast.success(
          'Invites sent',
          'All your invites have been sent to their recipients.'
        )
        this.$emit('sendInvites', createdInvites)
      } catch (e) {
        this.$console.debug('Error sending invites', e)
        this.$toast.error(e, 'sending invites')
      } finally {
        this.sendLoading = false
      }
    },
    handleInput(index) {
      if (index === this.invites.length - 1 && this.invites.length < 10) {
        this.addEmptyInvite()
        this.$nextTick(() =>
          this.$refs.invitetable.scrollTo({
            top: this.$refs.invitetable.scrollHeight,
            behavior: 'smooth'
          })
        )
      }
    }
  }
}
</script>

<style lang="scss">
.invites-modal {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0.5rem 1rem;
  }

  &-headers {
    border-bottom: 1px solid #e5e5e5;
    &-header {
      font-weight: 600;
      color: #303032;
    }
  }

  &-row {
    &-email {
      padding-right: 0.5rem;

      &-input {
        padding: 0.5rem;
        background: #f1f2f3;
        border-radius: 4px;
        border: 1px solid rgba(#000, 8%);
        width: 100%;

        &:focus,
        &:active,
        &:focus-visible,
        &:focus-within {
          border: 1px solid $primary;
          background: white;
        }
      }
    }

    &-role {
      width: 15rem;
      vertical-align: middle;
    }
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    &-cancel {
      font-weight: 600;
      color: #60666b;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 999rem;
      border: 1px solid rgba(#000, 8%);

      &:hover {
        border: 1px solid $primary;
        color: $primary;
      }
    }

    &-invite {
      background: $primary;
      color: white;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 999rem;

      &:hover {
        background: darken($primary, 10%);
      }
    }

    &-icon {
      height: 1rem;
      filter: brightness(0) invert(1);
    }
  }
}
</style>
