var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "integrations" }, [
    _c("img", {
      staticClass: "integrations-icon",
      attrs: { src: require("@/assets/icons/settings.svg"), alt: "" }
    }),
    _c("p", { staticClass: "integrations-header" }, [
      _vm._v(" We're working on a brand new look for this page ")
    ]),
    _c("p", { staticClass: "integrations-subheader" }, [
      _vm._v(
        " In the meantime, please use the modal to change your integration settings "
      )
    ]),
    _c(
      "p",
      {
        staticClass: "integrations-button",
        on: { click: _vm.openIntegrationModal }
      },
      [_vm._v("Open modal")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }