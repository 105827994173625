var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Button", {
    attrs: {
      text: (_vm.un + "publish template").capitalize(),
      loading: _vm.loading
    },
    on: { click: _vm.handlePublish }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }