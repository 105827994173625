<template>
  <div class="portfolio-templates">
    <div v-if="!(isEdit || isInspect)" class="portfolio-templates-header">
      <p class="portfolio-templates-header-title">
        Portfolio key content templates
      </p>
      <Button text="Add template" @click="() => (modal = 'create-init')" />
    </div>
    <div v-if="!(isEdit || isInspect)" class="portfolio-templates-tabs-wrapper">
      <Tabs
        :tabs="tabs"
        :selected="(tab) => tab.key === currentTab"
        @select="changeTab"
      >
        <template #tab="{ tab }">
          <div
            :id="`templatelistwrapper-${tab.key}`"
            class="portfolio-templates-list"
          >
            <Table
              v-if="tab.content.length"
              :items="tab.content"
              :headers="tableHeaders"
              :max-height="maxHeightString"
              default-sort-by="name"
              class="portfolio-templates-list-table"
            >
              <template #item="{ header, item }">
                <PortfolioTemplateItem
                  :header="header"
                  :template="item"
                  :tab="tab"
                  @content="() => openContentModal(item)"
                  @edit="() => gotoEdit(item.uuid)"
                  @inspect="() => gotoInspect(item.uuid)"
                  @publish="() => startPublish(item)"
                  @duplicate="duplicateTemplate"
                  @remove="() => removeTemplate(item.uuid)"
                />
              </template>
            </Table>
            <div v-if="loading" class="portfolio-templates-list-loading">
              <b-loading active :is-full-page="false" />
            </div>
            <PortfolioTemplateStatus
              v-else-if="error || !tab.content.length"
              :error="error"
              :empty="!tab.content.length"
              :tab="tab"
              @retry="initTemplates"
            />
          </div>
        </template>
      </Tabs>
    </div>
    <EditPortfolioTemplate
      v-else
      :is-edit="isEdit"
      @update="handleUpdateEdit"
    />
    <CreateTemplate
      :visible="modal === 'create-init'"
      type="portfolio"
      :ori-type="currentTab"
      @close="() => (modal = '')"
      @created="handleCreated"
    />
    <PortfolioTemplatePublish
      ref="templatepublish"
      :template="publishTemplate || {}"
      :type="currentTab"
      :show-trigger="false"
      @publish="handlePublish"
    />
    <PortfolioTemplateContentModal
      :visible="!!contentModal"
      :template="contentModal"
      :type="currentTab"
      @close="() => (contentModal = undefined)"
    />
  </div>
</template>

<script>
import PortfolioTemplateContentModal from './portfolio_templates/PortfolioTemplateContentModal.vue'
import PortfolioTemplatePublish from './portfolio_templates/PortfolioTemplatePublish.vue'
import PortfolioTemplateStatus from './portfolio_templates/PortfolioTemplateStatus.vue'
import PortfolioTemplateItem from './portfolio_templates/PortfolioTemplateItem.vue'
import EditPortfolioTemplate from './portfolio_templates/EditPortfolioTemplate.vue'
import CreateTemplate from './templates/CreateTemplate.vue'
import Button from '@c/library/Button.vue'
import Table from '@c/library/Table.vue'
import Tabs from '@c/library/Tabs.vue'
import { getKeyContentTemplates } from '@/services/keyContentService'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import { formatDate } from '@/util'

export default {
  name: 'WorkspacePortfolioTemplates',
  components: {
    PortfolioTemplateContentModal,
    PortfolioTemplatePublish,
    PortfolioTemplateStatus,
    PortfolioTemplateItem,
    EditPortfolioTemplate,
    CreateTemplate,
    Button,
    Table,
    Tabs
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    templates: [],
    currentTab: 'offering',
    loading: false,
    error: '',
    modal: '',
    publishTemplate: undefined,
    contentModal: undefined
  }),
  computed: {
    routeTabs() {
      let tabs = this.$route.params.tab
      return Array.isArray(tabs) ? tabs : tabs?.split('/') || []
    },
    isEdit() {
      return this.routeTabs.length > 1 && this.routeTabs[2] === 'edit'
    },
    isInspect() {
      return this.routeTabs.length > 1 && this.routeTabs[2] === 'inspect'
    },
    editUuid() {
      return this.isEdit ? this.routeTabs[1] : undefined
    },
    routeTypeTab() {
      return this.routeTabs.length == 2 ? this.routeTabs[1] : ''
    },
    offeringTemplates() {
      return this.templates.filter((t) => t.output_type === 'offering')
    },
    referenceTemplates() {
      return this.templates.filter((t) => t.output_type === 'reference')
    },
    inspirationTemplates() {
      return this.templates.filter((t) => t.output_type === 'inspiration')
    },
    tabs() {
      return [
        {
          key: 'offering',
          name: 'Offerings',
          content: this.offeringTemplates
        },
        {
          key: 'reference',
          name: 'Cases',
          content: this.referenceTemplates
        },
        {
          key: 'inspiration',
          name: 'Inspiration',
          content: this.inspirationTemplates
        },
        {
          key: 'default',
          name: 'Default',
          content: this.templates.filter((t) => !t.output_type)
        }
      ]
    },
    tableHeaders() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: (template) => template.name,
          sortAsc: (a, b) => a.name.localeCompare(b.name),
          sortDesc: (a, b) => b.name.localeCompare(a.name)
        },
        {
          id: 'status',
          title: 'Status',
          key: (template) => template.status,
          sortAsc: (a, b) => a.status.localeCompare(b.status),
          sortDesc: (a, b) => b.status.localeCompare(a.status)
        },
        {
          id: 'date_published',
          title: 'Published',
          key: (template) =>
            template.status === 'published'
              ? formatDate(template.status_date)
              : '-'
        },
        {
          id: 'review_deadline',
          title: 'Review deadline',
          key: (template) =>
            template.status === 'published'
              ? formatDate(
                  new Date(
                    new Date(template.status_date).setDate(
                      new Date(template.status_date).getDate() +
                        template.review_period
                    )
                  ).toISOString()
                )
              : '-'
        },
        {
          id: 'content',
          title: 'Applied for',
          key: () => ''
        },
        {
          id: 'actions',
          title: '',
          key: () => ''
        }
      ]
    }
  },
  watch: {
    isEdit(val) {
      if (!val) this.checkMaxHeight()
    }
  },
  async created() {
    await this.initTemplates()
    if (this.isEdit || this.isInspect) {
      const template = this.templates.find(t => t.uuid === this.editUuid)
      if (
        (this.isEdit && template.status === 'published') ||
        (this.isInspect && template.status !== 'published')
      ) {
        this.$router.replace({
          name: 'ws-settings',
          params: {
            workspace_id: this.$route.params.workspace_id,
            tab: ['portfolio_templates', this.currentTab]
          }
        })
      }
      return
    }
    if (this.routeTypeTab && this.currentTab !== this.routeTypeTab) {
      this.currentTab = this.routeTypeTab
    } else if (!this.routeTypeTab) {
      this.$router.replace({
        name: 'ws-settings',
        params: {
          workspace_id: this.$route.params.workspace_id,
          tab: ['portfolio_templates', this.currentTab]
        }
      })
    }
  },
  methods: {
    getMaxHeightElement() {
      return document.getElementById('templatelistwrapper-offering')
    },
    changeTab(tab) {
      this.currentTab = tab.key
      this.$router.push({
        name: 'ws-settings',
        params: {
          workspace_id: this.$route.params.workspace_id,
          tab: ['portfolio_templates', tab.key]
        }
      })
    },
    async initTemplates() {
      try {
        this.error = false
        this.loading = true
        this.templates = await getKeyContentTemplates({
          workspace_id: this.$route.params.workspace_id
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    duplicateTemplate(template) {
      this.templates.push(template)
    },
    removeTemplate(uuid) {
      this.templates = this.templates.filter((t) => t.uuid !== uuid)
    },
    status(template) {
      return template.status
        ? {
            text: template.status.capitalize(),
            type: { draft: 'orange', published: 'green' }[template.status]
          }
        : undefined
    },
    handleCreated(template) {
      this.templates.push(template)
      this.gotoEdit(template.uuid)
    },
    gotoEdit(uuid) {
      this.$router.push({
        name: 'ws-settings',
        params: {
          workspace_id: this.$route.params.workspace_id,
          tab: ['portfolio_templates', uuid, 'edit']
        }
      })
    },
    gotoInspect(uuid) {
      this.$router.push({
        name: 'ws-settings',
        params: {
          workspace_id: this.$route.params.workspace_id,
          tab: ['portfolio_templates', uuid, 'inspect']
        }
      })
    },
    openContentModal(template) {
      this.contentModal = template
    },
    startPublish(template) {
      this.publishTemplate = template
      this.$nextTick(this.$refs.templatepublish.startPublish)
    },
    handlePublish() {
      const status =
        this.publishTemplate.status === 'published' ? 'draft' : 'published'
      const idx = this.templates.findIndex(
        (t) => t.uuid === this.publishTemplate.uuid
      )
      if (idx !== -1) this.templates[idx].status = status
      this.publishTemplate = undefined
    },
    handleUpdateEdit(t) {
      this.templates = this.templates.map((template) =>
        template.uuid === t.uuid ? { ...template, ...t } : template
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-templates {
  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.25rem;

    &-title {
      font-weight: 700;
      font-size: 2.25rem;
      margin-right: auto;
    }
  }

  &-list {
    margin-top: 1rem;

    &-loading {
      position: relative;
      min-height: 10rem;
    }
  }
}
</style>
