var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "template-create" }, [
        _c(
          "div",
          { staticClass: "template-create-header" },
          [
            _vm._v(" New template "),
            _c("Button", {
              attrs: { icon: "close", type: "grey", size: "xs" },
              on: {
                click: function() {
                  return _vm.$emit("close")
                }
              }
            })
          ],
          1
        ),
        !_vm.step
          ? _c(
              "div",
              { staticClass: "template-create-content" },
              [
                _c("TextInput", {
                  attrs: {
                    title: "Name",
                    placeholder: "Template name",
                    "max-length": 50
                  },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                }),
                _c("TextInput", {
                  attrs: {
                    title: "Description",
                    placeholder: "Describe your new template"
                  },
                  model: {
                    value: _vm.description,
                    callback: function($$v) {
                      _vm.description = $$v
                    },
                    expression: "description"
                  }
                }),
                _vm.type === "portfolio"
                  ? _c(
                      "div",
                      { staticClass: "template-create-content-section" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-create-content-section-header"
                          },
                          [_vm._v("Content type")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "template-create-content-section-options"
                          },
                          _vm._l(_vm.ori_types, function(t) {
                            return _c(
                              "div",
                              {
                                key: t.key,
                                staticClass:
                                  "template-create-content-section-options-option",
                                class: { selected: t.key === _vm.ori_type },
                                on: {
                                  click: function() {
                                    return (_vm.ori_type = t.key)
                                  }
                                }
                              },
                              [
                                _c("Checkbox", {
                                  staticClass:
                                    "template-create-content-section-options-option-radio",
                                  attrs: {
                                    value: t.key === _vm.ori_type,
                                    type: "radio"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "template-create-content-section-options-option-info"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "template-create-content-section-options-option-title"
                                      },
                                      [_vm._v(" " + _vm._s(t.title) + " ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "\n                  template-create-content-section-options-option-description\n                "
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(t.description) + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "template-create-content-section" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-create-content-section-header"
                          },
                          [_vm._v("Content format")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "template-create-content-section-options"
                          },
                          _vm._l(_vm.formats, function(f) {
                            return _c(
                              "div",
                              {
                                key: f.key,
                                staticClass:
                                  "template-create-content-section-options-option",
                                class: { selected: f.key === _vm.format },
                                on: {
                                  click: function() {
                                    return (_vm.format = f.key)
                                  }
                                }
                              },
                              [
                                _c("Checkbox", {
                                  staticClass:
                                    "template-create-content-section-options-option-radio",
                                  attrs: {
                                    value: f.key === _vm.format,
                                    type: "radio"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "template-create-content-section-options-option-title"
                                  },
                                  [_vm._v(" " + _vm._s(f.title) + " ")]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    )
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "template-create-content" },
              [
                !_vm.showPrompt
                  ? _c("UploadDropzone", {
                      attrs: {
                        "max-count": 1,
                        filetypes: ["ppt", "pptx"],
                        "show-drive": false,
                        loading: _vm.loading
                      },
                      on: { upload: _vm.handleCreate }
                    })
                  : _c("TextArea", {
                      attrs: { title: "Prompt", "min-height": "20rem" },
                      model: {
                        value: _vm.prompt,
                        callback: function($$v) {
                          _vm.prompt = $$v
                        },
                        expression: "prompt"
                      }
                    })
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "template-create-footer" },
          [
            _c("Button", {
              attrs: { text: _vm.step ? "Back" : "Cancel", type: "white" },
              on: { click: _vm.handleBack }
            }),
            !_vm.step || _vm.showPrompt
              ? _c("Button", {
                  attrs: {
                    text: _vm.step ? "Create template" : "Next",
                    disabled: _vm.step ? !_vm.prompt : !_vm.name
                  },
                  on: { click: _vm.handleNext }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }