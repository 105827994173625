var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Button", {
    attrs: {
      text:
        _vm.block.selections.length +
        " " +
        _vm.typeName +
        " template" +
        (_vm.block.selections.length === 1 ? "" : "s"),
      icon: "slides",
      "icon-left": true,
      type: "light-solid",
      size: "xs",
      "full-width": true
    },
    on: {
      click: function() {
        return _vm.$emit("edit")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function() {
          return [
            _c("Button", {
              staticClass: "block-pill-button left",
              attrs: { icon: "close", size: "xxs" }
            })
          ]
        },
        proxy: true
      },
      {
        key: "right",
        fn: function() {
          return [
            _c("Button", {
              staticClass: "block-pill-button right",
              attrs: {
                icon: "close",
                type: "grey",
                size: "xxs",
                loading: _vm.removeLoading
              },
              nativeOn: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handleRemove.apply(null, arguments)
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }