<template>
  <div>
    <Button
      v-if="showTrigger"
      text="Publish"
      :loading="loading"
      @click="startPublish"
    />
    <b-modal v-model="showModal" :can-cancel="['escape', 'outside']">
      <div class="publish-modal">
        <div class="publish-modal-header">Publish {{ typeName }} template</div>
        <div class="publish-modal-content">
          <div class="publish-modal-content-review">
            <div class="publish-modal-content-review-input">
              <NumberInput
                v-model="reviewPeriod"
                type="number"
                :min="0"
                :max="30"
                background="white"
              />
              <p class="publish-modal-content-review-input-text">days</p>
            </div>
            <div class="publish-modal-content-header">
              <p class="publish-modal-content-header-title">
                Set the review period for the newly generated content.
              </p>
              <p class="publish-modal-content-header-sub">
                Select a review period between 0 and 30 days. The
                {{ typeName }} owners will have until then to review the content
                before it is published.
              </p>
            </div>
          </div>
          <div class="publish-modal-content-header">
            <p class="publish-modal-content-header-title">
              Select for which {{ typeName }}s you want to publish this template
            </p>
            <p class="publish-modal-content-header-sub">
              Add filters to the group below to select the {{ typeName }}s you
              want. All future {{ typeName }}s that match the filters will use
              this template.
            </p>
          </div>
          <div class="publish-modal-content-list">
            <div class="publish-modal-content-list-filters">
              <Tag
                v-if="!selectedFilters.length"
                :text="`All ${typeName}s`"
                type="light"
              />
              <Tag
                v-for="(filter, idx) in selectedFilters"
                :key="`portfolio-publish-${idx}`"
                :text="filterText(filter)"
                class="publish-modal-content-list-filters-tag"
                :class="{ hidden: !filterText(filter) }"
                type="light"
              >
                <template #right>
                  <Button
                    icon="close"
                    type="grey"
                    size="xxs"
                    @click="() => removeFilter(idx)"
                  />
                </template>
              </Tag>
              <TemplatePublishFilter
                :aggregations="selectionAggregations"
                :selected="selectedFilters"
                :disabled="loadingItems"
                @submit="setFilters"
              />
            </div>
            <div class="publish-modal-content-list-items">
              <div class="publish-modal-content-list-items-search">
                <TextInput
                  v-model="selectionQuery"
                  left-icon="search"
                  background="white"
                  :disabled="loadingItems || !selectionItems.length"
                  :placeholder="`Search${
                    selectionCount > 0 ? ` ${selectionCount}` : ''
                  } ${typeName}s`"
                />
              </div>
              <Table
                :items="filteredItems"
                :headers="selectionHeaders"
                :max-height="tableHeight"
                default-sort-by="name"
                background="#f1f2f3"
                style="margin: 0"
              >
                <template #item="{ header, item }">
                  <PortfolioItemName
                    v-if="header.id === 'name'"
                    :item="item"
                    :type="oriType"
                    :clickable="false"
                  />
                  <PortfolioItemOwners
                    v-else-if="header.id === 'owners'"
                    :item="item"
                    :type="oriType"
                  />
                  <div v-else>
                    {{ header.key(item) }}
                  </div>
                </template>
              </Table>
              <div
                v-if="loadingItems"
                class="publish-modal-content-list-items-loading"
              >
                <img
                  src="@/assets/icons/spinner.svg"
                  alt=""
                  class="publish-modal-content-list-items-loading-icon"
                />
              </div>
              <div
                v-if="!loadingItems && !selectionItems.length"
                class="publish-modal-content-list-items-empty"
              >
                <p class="publish-modal-content-list-items-empty-title">
                  No {{ typeName }}s found
                </p>
                <p class="publish-modal-content-list-items-empty-sub">
                  You could still publish this template with these filters. All
                  future {{ typeName }}s that these filters apply to, will have
                  content generated by this template.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="publish-modal-footer">
          <Button
            type="white"
            text="Cancel"
            :disabled="loading"
            @click="() => (showModal = false)"
          />
          <Button text="Publish" :loading="loading" @click="handlePublish" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import Table from '@c/library/Table.vue'
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import NumberInput from '@c/library/NumberInput.vue'
import PortfolioItemName from '@/views-v2/portfolio/PortfolioItemName.vue'
import PortfolioItemOwners from '@/views-v2/portfolio/PortfolioItemOwners.vue'
import { mapActions } from 'vuex'
import {
  editKeyContentTemplate,
  setKeyContentTemplatePublishORIs
} from '@/services/keyContentService'
import TemplatePublishFilter from './publish/TemplatePublishFilter.vue'

export default {
  name: 'PortfolioTemplatePublish',
  components: {
    Tag,
    Table,
    Button,
    TextInput,
    NumberInput,
    PortfolioItemName,
    PortfolioItemOwners,
    TemplatePublishFilter
  },
  props: {
    template: {
      type: Object,
      required: true
    },
    showTrigger: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showModal: false,
    loading: false,
    selectedFilters: [],
    selectionQuery: '',
    loadingItems: false,
    reviewPeriod: 30,
    selectionItems: [],
    selectionCount: -1,
    selectionAggregations: {},
    rootAggregations: undefined,
    selectionHeaders: [
      {
        id: 'name',
        title: 'Name',
        key: (item) => item.name,
        sortAsc: (a, b) => a.name.localeCompare(b.name),
        sortDesc: (a, b) => b.name.localeCompare(a.name)
      },
      {
        id: 'owners',
        title: 'Owners',
        key: () => '',
        sortAsc: (a, b) =>
          a.owners.length - b.owners.length ||
          (a.owners[0] || '').name.localeCompare((b.owners[0] || '').name)
      }
    ]
  }),
  computed: {
    oriType() {
      return `${this.template.output_type}s`
    },
    typeName() {
      return (
        {
          offerings: 'offering',
          references: 'case',
          inspirations: 'inspiration'
        }[this.oriType] || ''
      )
    },
    filteredItems() {
      return this.selectionItems.filter((item) =>
        item.name.toLowerCase().includes(this.selectionQuery.toLowerCase())
      )
    },
    tableHeight() {
      const breakpoints = {
        700: '20vh',
        800: '30vh',
        1100: '40vh',
        1300: '45vh'
      }
      const bp = Object.keys(breakpoints).find((bp) => window.innerHeight < bp)
      return breakpoints[bp] || '50vh'
    }
  },
  methods: {
    ...mapActions([
      'searchOfferingsExtended',
      'searchReferencesExtended',
      'searchInspirationsExtended'
    ]),
    startPublish() {
      this.selectedFilters = []
      this.selectionQuery = ''
      this.rootAggregations = undefined
      this.selectionAggregations = {}
      this.loadingItems = false
      this.selectionItems = []
      this.selectionCount = -1
      this.showModal = true
      this.loadItems()
    },
    async loadItems() {
      try {
        this.loadingItems = true
        const ori_type = `${this.template.output_type}s`
        this.selectionCount = -1
        this.selectionItems = []
        this.selectionAggregations = {}
        const res = await setKeyContentTemplatePublishORIs({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid,
          ori_type,
          filters: this.selectedFilters,
          page_size: 9999
        })
        this.selectionCount = res.count
        this.selectionItems = res.results
        this.selectionAggregations = { ...res.aggregations }
        if (!this.rootAggregations) {
          this.rootAggregations = { ...res.aggregations }
        }
      } catch (e) {
        this.$console.debug('Error while loading items', e)
        this.$toast.error(e, 'loading the items')
      } finally {
        this.loadingItems = false
      }
    },
    setFilters(filters) {
      this.selectedFilters = filters
      this.loadItems()
    },
    removeFilter(idx) {
      this.selectedFilters.splice(idx, 1)
      this.loadItems()
    },
    filterText(filter) {
      const possibleKeys = [
        'attribute_value',
        'offering',
        'reference_confidentiality'
      ]
      const key = possibleKeys.find((k) => !!filter[k])
      let name =
        {
          attribute_value: 'Label',
          offering: 'Parent offering',
          reference_confidentiality: 'Confidentiality'
        }[key] || ''
      const aggregationKey =
        {
          attribute_value: 'attributes',
          offering: 'offerings',
          reference_confidentiality: 'reference_confidentiality'
        }[key] || ''
      const values = this.rootAggregations[aggregationKey] || []
      const value = {
        attribute_value: () =>
          values.reduce((acc, val) => {
            const label = val?.values?.find(
              (v) => v?.uuid === filter?.attribute_value
            )
            if (label) name = val.name
            return label ? label?.value || '' : acc
          }, ''),
        offering: () =>
          values.find((v) => v?.uuid === filter?.offering)?.name || '',
        reference_confidentiality: () =>
          filter?.reference_confidentiality?.capitalize()
      }[key]()
      return name && value ? `${name}: ${value}` : ''
    },
    async handlePublish() {
      try {
        this.loading = true
        const res = await editKeyContentTemplate({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid,
          status: 'published',
          review_period: this.reviewPeriod
        })
        this.$toast.success(
          'Template published',
          'The template has been published successfully.'
        )
        this.$emit('publish', res)
      } catch (e) {
        this.$console.debug('Error while publishing template', e)
        this.$toast.error(e, 'publishing the template')
      } finally {
        this.loading = false
        this.showModal = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.publish-modal {
  background: white;
  border-radius: 6px;
  width: min(75rem, 95vw);

  &-header {
    padding: 1.5rem 1.75rem;
    font-size: 1.5rem;
    font-weight: 700;
    border-bottom: 1px solid rgba(#000, 0.08);
  }

  &-content {
    padding: 1.5rem 1.75rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5rem;

    &-header {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;

      &-title {
        font-weight: 600;
      }

      &-sub {
        color: #60666b;
      }
    }

    &-review {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;

      &-input {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.25rem;
        padding: 0.5rem;
        border-radius: 6px;
        background: #f1f2f3;

        &-text {
          color: #60666b;
          white-space: nowrap;
        }
      }
    }

    &-list {
      background: #f1f2f3;
      border-radius: 6px;
      padding-bottom: 6px;
      display: flex;
      flex-flow: column nowrap;
      width: 100%;

      &-filters {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;
        align-items: center;
        padding: 1rem;

        &-tag {
          &.hidden {
            display: none;
          }
        }
      }

      &-items {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        width: 100%;

        &-search {
          padding: 0.25rem 0.5rem;
        }

        &-loading {
          min-height: 10rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &-icon {
            height: 1.5rem;
            animation: spin 1s linear infinite;
          }
        }

        &-empty {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          gap: 0.5rem;
          padding: 1rem;

          &-title {
            font-size: 1.25rem;
            font-weight: 600;
          }

          &-sub {
            color: #60666b;
          }
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 1.5rem 1.75rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
