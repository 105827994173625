var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-slides" },
    _vm._l(_vm.slides, function(slide, idx) {
      return _c(
        "div",
        {
          key: "template-slide-" + idx,
          staticClass: "template-slides-slide-wrapper"
        },
        [
          _c("img", {
            staticClass: "template-slides-slide",
            class: { selected: slide.uuid === _vm.selected },
            attrs: { src: slide.preview, alt: "" },
            on: {
              click: function() {
                return _vm.$emit("select", slide.uuid)
              }
            }
          }),
          _vm.showAdd
            ? _c(
                "div",
                { staticClass: "template-slides-slide-blocks" },
                [
                  _vm._l(_vm.slideBlocks[idx] || [], function(block, position) {
                    return _c(
                      "div",
                      {
                        key: "template-slide-block-" + idx + "-" + position,
                        staticClass: "template-slides-slide-blocks-block"
                      },
                      [
                        _vm.isEdit
                          ? _c("TemplateSlideBlockAdd", {
                              on: {
                                click: function() {
                                  return _vm.$emit("add", idx, position)
                                }
                              }
                            })
                          : _vm._e(),
                        _c("TemplateSlideBlockPill", {
                          attrs: {
                            block: block,
                            "template-id": _vm.templateId
                          },
                          on: {
                            edit: function() {
                              return _vm.$emit("edit", idx, position)
                            },
                            remove: function(block) {
                              return _vm.$emit("remove", block)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm.isEdit
                    ? _c("TemplateSlideBlockAdd", {
                        on: {
                          click: function() {
                            return _vm.$emit(
                              "add",
                              idx,
                              _vm.slideBlocks[idx].length
                                ? _vm.slideBlocks[idx].length + 1
                                : 0
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }