var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: "", "can-cancel": ["escape", "outside"] },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "template-test" }, [
        _c(
          "div",
          { staticClass: "template-test-header" },
          [
            _c("span", { staticClass: "template-test-header-title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c("Button", {
              attrs: { icon: "close", type: "grey", size: "xs" },
              on: {
                click: function() {
                  return _vm.$emit("close")
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "template-test-content",
            class: { "is-text": !_vm.isPresentationTest }
          },
          [
            _c("div", { staticClass: "template-test-content-sidebar" }, [
              _vm.outputType !== "story"
                ? _c(
                    "div",
                    { staticClass: "template-test-content-sidebar-search" },
                    [
                      _c("TextInput", {
                        attrs: {
                          placeholder: "Find " + _vm.itemType + "s",
                          "button-icon": _vm.query ? "close" : "",
                          "submit-with-button": false
                        },
                        on: {
                          submit: _vm.loadItems,
                          "button-click": function() {
                            return _vm.loadItems("")
                          }
                        },
                        model: {
                          value: _vm.query,
                          callback: function($$v) {
                            _vm.query = $$v
                          },
                          expression: "query"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.items.length
                ? _c(
                    "div",
                    { staticClass: "template-test-content-sidebar-list" },
                    _vm._l(_vm.items, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.uuid,
                          staticClass: "template-test-content-sidebar-item",
                          class: {
                            selected:
                              _vm.selectedItem &&
                              _vm.selectedItem.uuid === item.uuid
                          },
                          on: {
                            click: function() {
                              return _vm.selectItem(item)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass:
                              "template-test-content-sidebar-item-image",
                            attrs: { src: _vm.getImage(item), alt: "" }
                          }),
                          _c(
                            "p",
                            {
                              staticClass:
                                "template-test-content-sidebar-item-name"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.name || item.title) + " "
                              )
                            ]
                          ),
                          _vm.outputType === "reference"
                            ? _c("Tag", {
                                staticClass:
                                  "template-test-content-sidebar-item-conf",
                                attrs: {
                                  text: item.confidentiality.capitalize(),
                                  type:
                                    item.confidentiality === "internal"
                                      ? "black"
                                      : "light-solid",
                                  icon:
                                    "eye-" +
                                    (item.confidentiality === "internal"
                                      ? "close"
                                      : "open"),
                                  "icon-left": true,
                                  size: "xxs"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.items.length
                ? _c(
                    "div",
                    { staticClass: "template-test-content-sidebar-status" },
                    [
                      _vm.loadingItems || _vm.itemsError
                        ? _c("img", {
                            staticClass:
                              "template-test-content-sidebar-status-icon",
                            class: { loading: _vm.loadingItems },
                            attrs: {
                              src: require("@/assets/icons/" +
                                (_vm.loadingItems
                                  ? "spinner"
                                  : "check-warning") +
                                ".svg"),
                              alt: ""
                            }
                          })
                        : _vm._e(),
                      _c(
                        "p",
                        {
                          staticClass:
                            "template-test-content-sidebar-status-text"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.loadingItems
                                  ? "Loading " + _vm.itemType + "s..."
                                  : _vm.itemsError
                                  ? "Error loading " + _vm.itemType + "s"
                                  : "No " +
                                    _vm.itemType +
                                    "s found, " +
                                    (_vm.outputType === "story" || !_vm.query
                                      ? "please"
                                      : "try searching for something else or") +
                                    " create a new " +
                                    _vm.itemType +
                                    " first."
                              ) +
                              " "
                          )
                        ]
                      ),
                      _vm.itemsError
                        ? _c("Button", {
                            attrs: {
                              text: "Retry",
                              icon: "refresh",
                              "icon-left": true,
                              type: "white"
                            },
                            on: { click: _vm.loadItems }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "template-test-content-preview" },
              [
                _vm.isPresentationTest
                  ? _c("PresentationOutputPreview", {
                      attrs: {
                        output: _vm.preview,
                        loading: !!_vm.loadingPreview || _vm.loadingItems,
                        "hide-regenerate": true
                      }
                    })
                  : _vm.preview &&
                    !_vm.loadingItems &&
                    !_vm.loadingPreview &&
                    !_vm.previewError
                  ? _c(
                      "div",
                      { staticClass: "template-test-content-preview-text" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-test-content-preview-text-title"
                          },
                          [_vm._v(" Element content preview ")]
                        ),
                        _c("MarkdownEdit", {
                          staticClass:
                            "template-test-content-preview-text-preview",
                          attrs: { value: _vm.preview }
                        })
                      ],
                      1
                    )
                  : !!_vm.loadingPreview || _vm.loadingItems
                  ? _c(
                      "div",
                      { staticClass: "template-test-content-preview-status" },
                      [
                        _c("img", {
                          staticClass:
                            "template-test-content-preview-status-icon",
                          attrs: {
                            src: require("@/assets/icons/" +
                              (_vm.isPresentationTest
                                ? "presentation"
                                : "snippet") +
                              ".svg"),
                            alt: ""
                          }
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-test-content-preview-status-text"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "Loading " +
                                    (_vm.isPresentationTest
                                      ? "presentation"
                                      : "element") +
                                    " preview" +
                                    (_vm.loadingItems ? "" : " for")
                                ) +
                                " "
                            )
                          ]
                        ),
                        !_vm.loadingItems
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "template-test-content-preview-status-text bold"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedItem.name ||
                                        _vm.selectedItem.title
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("LoadingDots", { attrs: { animation: "flicker" } })
                      ],
                      1
                    )
                  : _vm.previewError
                  ? _c(
                      "div",
                      { staticClass: "template-test-content-preview-status" },
                      [
                        _c("img", {
                          staticClass:
                            "template-test-content-preview-status-icon",
                          attrs: {
                            src: require("@/assets/icons/check-warning.svg"),
                            alt: ""
                          }
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-test-content-preview-status-text"
                          },
                          [_vm._v(" Error generating preview ")]
                        ),
                        _c("Button", {
                          attrs: {
                            text: "Retry",
                            icon: "refresh",
                            "icon-left": true,
                            type: "white"
                          },
                          on: { click: _vm.loadPreview }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }