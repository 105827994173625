<template>
  <div>
    <Dropdown v-if="!loading" :items="dropdownItems" :position-fixed="true">
      <template #trigger>
        <Button icon="kebab" type="grey" />
      </template>
    </Dropdown>
    <div v-else class="sales-kit-actions-loading">
      <img
        src="@/assets/icons/spinner.svg"
        alt=""
        class="sales-kit-actions-loading-icon"
      />
    </div>
    <b-modal :active="modal === 'delete'" @close="modal = ''">
      <div class="confirm-remove">
        <div class="confirm-remove-header">Remove sales kit</div>
        <div class="confirm-remove-body">
          <p class="confirm-remove-body-header">Remove "{{ saleskit.name }}"</p>
          <p class="confirm-remove-body-text">
            Are you sure you want to remove this sales kit? This action cannot
            be undone.
          </p>
        </div>
        <div class="confirm-remove-btns">
          <Button text="Cancel" type="white" @click="modal = ''" />
          <Button text="Remove sales kit" @click="handleDelete" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Dropdown from '@c/library/Dropdown.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'WorkspaceSalesKitActions',
  components: {
    Dropdown,
    Button
  },
  props: {
    saleskit: {
      type: Object,
      default: () => ({})
    },
    isCore: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    modal: '',
    loading: ''
  }),
  computed: {
    dropdownItems() {
      return [
        ...(!this.isCore
          ? [
              {
                text: 'Edit',
                icon: 'edit',
                callback: this.handleEdit
              }
            ]
          : []),
        {
          text: 'Duplicate',
          icon: 'copy-medium',
          callback: this.handleDuplicate
        },
        {
          text: this.saleskit?.status === 'visible' ? 'Disable' : 'Enable',
          icon:
            this.saleskit?.status === 'visible'
              ? 'status_grey'
              : 'status_green',
          callback: this.handlePublish
        },
        ...(!this.isCore
          ? [
              {
                text: 'Remove',
                icon: 'bin',
                callback: () => (this.modal = 'delete'),
                filter: 'red'
              }
            ]
          : [])
      ]
    }
  },
  methods: {
    ...mapActions(['deleteSalesKit', 'duplicateSalesKit', 'editSalesKit']),
    handleEdit() {
      this.$emit('edit', this.saleskit)
    },
    handleView() {
      this.$emit('view', this.saleskit)
    },
    async handleDuplicate() {
      try {
        this.loading = 'duplicate'
        const saleskit = await this.duplicateSalesKit({
          workspace_id: this.$route.params.workspace_id,
          sales_kit_id: this.saleskit.uuid
        })
        this.$emit('duplicate', saleskit)
      } catch (e) {
        this.$console.debug('Error duplicating sales kit', e)
        this.$toast.error(e, 'duplicating the sales kit')
      } finally {
        this.loading = ''
      }
    },
    async handleDelete() {
      try {
        this.loading = 'delete'
        await this.deleteSalesKit({
          workspace_id: this.$route.params.workspace_id,
          sales_kit_id: this.saleskit.uuid
        })
        this.$emit('delete', this.saleskit)
      } catch (error) {
        this.$console.debug('Error deleting sales kit', error)
        this.$toast.error(error, 'deleting the sales kit')
      } finally {
        this.loading = ''
        this.modal = ''
      }
    },
    async handlePublish() {
      try {
        this.loading = 'publish'
        const saleskit = await this.editSalesKit({
          workspace_id: this.$route.params.workspace_id,
          sales_kit_id: this.saleskit.uuid,
          status: this.saleskit.status === 'visible' ? 'hidden' : 'visible'
        })
        this.$emit('publish', saleskit)
      } catch (error) {
        this.$console.debug('Error publishing sales kit', error)
        this.$toast.error(
          error,
          this.saleskit.status === 'visible'
            ? 'hiding sales kit'
            : 'making sales kit visible'
        )
      } finally {
        this.loading = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sales-kit-actions {
  &-loading {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 1.2rem;
      height: 1.2rem;
      animation: spin 1s linear infinite;
    }
  }
}
.confirm-remove {
  background: white;
  border: 1px solid rgba(#000, 8%);
  border-radius: 4px;
  width: min(30rem, 90vw);

  &-header {
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(#000, 8%);
    font-size: 1.2rem;
    font-weight: 700;
    color: #fc0d1b;
  }

  &-body {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1rem 2rem;

    &-header {
      font-size: 1.2rem;
      font-weight: 700;
    }

    &-text {
      color: #9098ae;
    }
  }

  &-btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem 2rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
