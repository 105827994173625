var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-templates" },
    [
      !(_vm.isEdit || _vm.isInspect)
        ? _c(
            "div",
            { staticClass: "portfolio-templates-header" },
            [
              _c("p", { staticClass: "portfolio-templates-header-title" }, [
                _vm._v(" Portfolio key content templates ")
              ]),
              _c("Button", {
                attrs: { text: "Add template" },
                on: {
                  click: function() {
                    return (_vm.modal = "create-init")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      !(_vm.isEdit || _vm.isInspect)
        ? _c(
            "div",
            { staticClass: "portfolio-templates-tabs-wrapper" },
            [
              _c("Tabs", {
                attrs: {
                  tabs: _vm.tabs,
                  selected: function(tab) {
                    return tab.key === _vm.currentTab
                  }
                },
                on: { select: _vm.changeTab },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "tab",
                      fn: function(ref) {
                        var tab = ref.tab
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "portfolio-templates-list",
                              attrs: { id: "templatelistwrapper-" + tab.key }
                            },
                            [
                              tab.content.length
                                ? _c("Table", {
                                    staticClass:
                                      "portfolio-templates-list-table",
                                    attrs: {
                                      items: tab.content,
                                      headers: _vm.tableHeaders,
                                      "max-height": _vm.maxHeightString,
                                      "default-sort-by": "name"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var header = ref.header
                                            var item = ref.item
                                            return [
                                              _c("PortfolioTemplateItem", {
                                                attrs: {
                                                  header: header,
                                                  template: item,
                                                  tab: tab
                                                },
                                                on: {
                                                  content: function() {
                                                    return _vm.openContentModal(
                                                      item
                                                    )
                                                  },
                                                  edit: function() {
                                                    return _vm.gotoEdit(
                                                      item.uuid
                                                    )
                                                  },
                                                  inspect: function() {
                                                    return _vm.gotoInspect(
                                                      item.uuid
                                                    )
                                                  },
                                                  publish: function() {
                                                    return _vm.startPublish(
                                                      item
                                                    )
                                                  },
                                                  duplicate:
                                                    _vm.duplicateTemplate,
                                                  remove: function() {
                                                    return _vm.removeTemplate(
                                                      item.uuid
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                : _vm._e(),
                              _vm.loading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "portfolio-templates-list-loading"
                                    },
                                    [
                                      _c("b-loading", {
                                        attrs: {
                                          active: "",
                                          "is-full-page": false
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.error || !tab.content.length
                                ? _c("PortfolioTemplateStatus", {
                                    attrs: {
                                      error: _vm.error,
                                      empty: !tab.content.length,
                                      tab: tab
                                    },
                                    on: { retry: _vm.initTemplates }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3326446004
                )
              })
            ],
            1
          )
        : _c("EditPortfolioTemplate", {
            attrs: { "is-edit": _vm.isEdit },
            on: { update: _vm.handleUpdateEdit }
          }),
      _c("CreateTemplate", {
        attrs: {
          visible: _vm.modal === "create-init",
          type: "portfolio",
          "ori-type": _vm.currentTab
        },
        on: {
          close: function() {
            return (_vm.modal = "")
          },
          created: _vm.handleCreated
        }
      }),
      _c("PortfolioTemplatePublish", {
        ref: "templatepublish",
        attrs: {
          template: _vm.publishTemplate || {},
          type: _vm.currentTab,
          "show-trigger": false
        },
        on: { publish: _vm.handlePublish }
      }),
      _c("PortfolioTemplateContentModal", {
        attrs: {
          visible: !!_vm.contentModal,
          template: _vm.contentModal,
          type: _vm.currentTab
        },
        on: {
          close: function() {
            return (_vm.contentModal = undefined)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }