var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invites-modal" }, [
    _c("p", { staticClass: "invites-modal-header" }, [
      _vm._v("Invite members")
    ]),
    _c(
      "div",
      { ref: "invitetable", staticClass: "invites-modal-table-wrapper" },
      [
        _c(
          "table",
          { staticClass: "invites-modal-table" },
          [
            _vm._m(0),
            _vm._l(_vm.invites, function(row, idx) {
              return _c("tr", { key: idx, staticClass: "invites-modal-row" }, [
                _c(
                  "td",
                  { staticClass: "invites-modal-row-email" },
                  [
                    _c("TextInput", {
                      attrs: { placeholder: "john.doe@email.com" },
                      on: {
                        input: function() {
                          return _vm.handleInput(idx)
                        }
                      },
                      model: {
                        value: _vm.invites[idx].email,
                        callback: function($$v) {
                          _vm.$set(_vm.invites[idx], "email", $$v)
                        },
                        expression: "invites[idx].email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "invites-modal-row-role" },
                  [
                    _c("RoleSelect", {
                      attrs: {
                        value: _vm.invites[idx].roles,
                        "full-width": true
                      },
                      on: {
                        submit: function(r) {
                          return (_vm.invites[idx].roles = r)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            })
          ],
          2
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "invites-modal-btns" },
      [
        _c("Button", {
          attrs: { type: "white", text: "Cancel", disabled: _vm.sendLoading },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        }),
        _c("Button", {
          attrs: {
            icon: "mail",
            text: "Send invites",
            loading: _vm.sendLoading
          },
          on: { click: _vm.sendInvites }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "invites-modal-headers" }, [
      _c("th", { staticClass: "invites-modal-headers-header" }, [
        _vm._v("Email address")
      ]),
      _c("th", { staticClass: "invites-modal-headers-header" }, [
        _vm._v("Role")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }