<template>
  <b-modal v-if="visible" active @close="() => $emit('close')">
    <div class="sales-kit-modal">
      <div class="sales-kit-modal-header">
        <p class="sales-kit-modal-header-title">{{ title }}</p>
        <Button
          icon="close"
          size="xs"
          type="grey"
          @click="() => $emit('close')"
        />
      </div>
      <div class="sales-kit-modal-content">
        <TextInput v-model="name" title="Name" placeholder="Deal stage name" />
        <div class="sales-kit-modal-content-wrapper">
          <div class="sales-kit-modal-content-header">
            <p class="sales-kit-modal-content-header-title">
              Add sales templates to this deal stage
            </p>
            <TextInput
              v-model="query"
              placeholder="Search..."
              left-icon="search"
              :button-icon="query ? 'close' : ''"
              @submit="() => (query = '')"
            />
          </div>
          <div class="sales-kit-modal-content-filters">
            <Button
              v-for="f in filterOptions"
              :key="f.value"
              :text="f.label"
              :type="filter === f.value ? 'light-solid' : 'white'"
              @click="() => (filter = f.value)"
            />
          </div>
          <div class="sales-kit-modal-content-list">
            <div
              v-for="template in filteredTemplates"
              :key="template.uuid"
              class="sales-kit-modal-content-list-item"
              :class="{ selected: selected.includes(template.uuid) }"
              @click="() => handleSelect(template)"
            >
              <Checkbox
                :value="selected.includes(template.uuid)"
                class="sales-kit-modal-content-list-item-checkbox"
                @input="() => handleSelect(template)"
              />
              <div class="sales-kit-modal-content-list-item-content">
                <p class="sales-kit-modal-content-list-item-name">
                  {{ template.name }}
                </p>
                <p class="sales-kit-modal-content-list-item-description">
                  {{ template.description }}
                </p>
              </div>
            </div>
            <div
              v-if="templatesLoading"
              class="sales-kit-modal-content-loading"
            >
              <b-loading active :is-full-page="false" />
            </div>
          </div>
        </div>
      </div>
      <div class="sales-kit-modal-footer">
        <Button
          text="Cancel"
          type="white"
          :loading="loading"
          @click="() => $emit('close')"
        />
        <Button
          text="Save"
          :disabled="!name"
          :loading="loading"
          @click="save"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Checkbox from '@c/library/Checkbox.vue'
import TextInput from '@c/library/TextInput.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WorkspaceSalesKitModal',
  components: { TextInput, Button, Checkbox },
  props: {
    saleskit: {
      type: Object,
      default: undefined
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    templatesLoading: false,
    loading: false,
    name: '',
    templates: [],
    selected: [],
    query: '',
    filter: 'all'
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    isEdit() {
      return !!this.saleskit
    },
    title() {
      return this.isEdit ? 'Edit deal stage' : 'New deal stage'
    },
    selectedIds() {
      return this.templates.map((template) => template.uuid)
    },
    saleskitTemplateIds() {
      return this.saleskit?.tools.map((template) => template.uuid)
    },
    selectionChanged() {
      return (
        (this.selectedIds.join() || '') !==
        (this.saleskitTemplateIds?.join() || '')
      )
    },
    filteredTemplates() {
      if (!this.query && this.filter === 'all') return this.templates
      return this.templates.filter((t) => {
        return (
          (this.filter === 'all' || this.filter === t.provider) &&
          (!this.query ||
            t.name.toLowerCase().includes(this.query.toLowerCase()) ||
            t.description.toLowerCase().includes(this.query.toLowerCase()))
        )
      })
    },
    filterOptions() {
      return [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'uman',
          value: 'core'
        },
        ...(this.templates.some((t) => t.provider === 'custom')
          ? [
              {
                label: this.currentWorkspace?.name || 'Workspace',
                value: 'custom'
              }
            ]
          : [])
      ]
    }
  },
  watch: {
    visible(val) {
      if (val) this.initTemplates()
    }
  },
  methods: {
    ...mapActions(['getTemplates', 'createSalesKit', 'editSalesKit']),
    async initTemplates() {
      try {
        this.templatesLoading = true
        const templates = await this.getTemplates({
          workspace_id: this.$route.params.workspace_id
        })
        this.templates = templates
        this.name = this.saleskit?.name || ''
        this.selected = this.saleskitTemplateIds || []
      } catch (e) {
        this.$console.debug('Error getting templates', e)
        this.$toast.error(e, 'getting the templates')
      } finally {
        this.templatesLoading = false
      }
    },
    handleSelect(template) {
      if (this.selected.includes(template.uuid)) {
        this.selected = this.selected.filter((id) => id !== template.uuid)
      } else {
        this.selected = [...this.selected, template.uuid]
      }
    },
    async save() {
      try {
        this.loading = true
        const editFunction = this.isEdit
          ? this.editSalesKit
          : this.createSalesKit
        const saleskit = await editFunction({
          ...(this.isEdit
            ? { sales_kit_id: this.saleskit?.uuid }
            : { status: 'visible' }),
          ...(this.name !== this.saleskit?.name ? { name: this.name } : {}),
          template_ids: this.selected,
          workspace_id: this.$route.params.workspace_id
        })
        this.$emit('save', saleskit)
      } catch (e) {
        this.$console.debug(
          `Error ${this.isEdit ? 'editing' : 'creating'} sales kit`,
          e
        )
        this.$toast.error(
          e,
          `${this.isEdit ? 'editing' : 'creating'} sales kit`
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sales-kit-modal {
  width: min(45rem, 90vw);
  display: flex;
  flex-flow: column nowrap;
  background: white;
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: solid 1px rgba(#000, 0.08);
    padding: 1.75rem 1.75rem 1rem;

    &-title {
      font-size: 1.75rem;
      font-weight: 700;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    padding: 1.75rem;
    gap: 1.75rem;

    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: 1rem;

      &-title {
        font-weight: 600;
      }
    }

    &-filters {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      max-height: 35vh;
      overflow-y: auto;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background: rgba(#000, 0.08);
        }

        &.selected {
          background: #ebf1ff;
        }

        &-name {
          font-weight: 500;
        }

        &-description {
          color: #60666b;
        }
      }
    }

    &-loading {
      position: relative;
      min-height: 5rem;
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    border-top: solid 1px rgba(#000, 0.08);
    padding: 1.75rem;
    gap: 0.5rem;
  }
}
</style>
