import { getClient, getResponseData } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function getProfiles({ workspace_id, integration_id }) {
  const res = await client.get(
    `/${workspace_id}/integrations/${integration_id}/profiles/`
  )
  return getResponseData(res)
}

export async function createProfile({
  workspace_id,
  integration_id,
  identifier
}) {
  const res = await client.post(
    `/${workspace_id}/integrations/${integration_id}/profiles/`,
    {
      identifier
    }
  )
  return getResponseData(res)
}

export async function deleteProfile({
  workspace_id,
  integration_id,
  profile_id
}) {
  await client.delete(
    `/${workspace_id}/integrations/${integration_id}/profiles/${profile_id}/`
  )
}
