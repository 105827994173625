<template>
  <div class="results-wrapper">
    <div class="results-header">
      <Button icon="arrow-left" type="grey" @click="handleBackClick" />
      <PitchTemplatePublish
        :template="template"
        @publish="() => $emit('publish')"
      />
    </div>
    <div ref="resultcontent" class="results" :style="maxHeightStyle">
      <div class="results-content">
        <MeetingContentLoading
          v-if="loading && !error"
          :custom-stages="['Finishing up your results']"
          class="results-loading"
        />
        <MeetingContentError
          v-else-if="error"
          custom-message="Something went wrong while generating output. Please try again."
          @retry="handleRetry"
        />
        <div v-else class="results-block">
          <div class="results-block-text">
            <HTMLRenderer :value="content" />
          </div>
        </div>
      </div>
      <CustomResultSidebar
        :prev-template="template"
        :meetings="meetings"
        :meetings-loading="meetingsLoading"
        :selected-meeting="selectedMeeting"
        :disabled="loading"
        @meeting="selectMeeting"
        @generate="loadResults"
      />
    </div>
    <ResourceModal />
  </div>
</template>

<script>
import { getMeetings, createMeetingOutput } from '@/services/meetingService'
import MeetingContentLoading from '../../meeting/content/MeetingContentLoading.vue'
import MeetingContentError from '../../meeting/content/MeetingContentError.vue'
import CustomResultSidebar from '../../meeting/results/CustomResultSidebar.vue'
import Button from '@c/library/Button.vue'
import ResourceModal from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceModal.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import HTMLRenderer from '@c/library/HTMLRenderer.vue'
import PitchTemplatePublish from './PitchTemplatePublish.vue'

export default {
  name: 'EditDocumentTemplate',
  components: {
    Button,
    ResourceModal,
    MeetingContentLoading,
    MeetingContentError,
    HTMLRenderer,
    PitchTemplatePublish,
    CustomResultSidebar
  },
  mixins: [MaxHeightMixin],
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    meetings: [],
    selectedMeeting: {},
    meetingsLoading: false,
    contentHeight: 0,
    content: '',
    loading: false,
    error: false
  }),
  computed: {
    routePadded() {
      return this.$route.meta.noscroll
    }
  },
  watch: {
    routePadded() {
      this.checkMaxHeight()
    }
  },
  async created() {
    await this.loadStories()
    this.loadResults()
  },
  methods: {
    getMaxHeightElement() {
      return this.$refs.resultcontent
    },
    handleBackClick() {
      this.$router.go(-1)
    },
    async loadStories() {
      try {
        this.loading = true
        this.meetingsLoading = true
        const stories = await getMeetings({
          workspace_id: this.$route.params.workspace_id
        })
        this.meetings = stories
        if (this.meetings.length) {
          this.selectedMeeting = stories[0]
        } else {
          this.$toast.danger(
            'No existing meetings found',
            'Please create a meeting first to test or edit your document template. Without a meeting, you cannot generate example outputs.'
          )
          this.$router.go(-1)
        }
      } catch (e) {
        this.$console.debug('Error while loading meetings for testing', e)
        this.$toast.error(e, 'loading meetings for testing')
      } finally {
        this.meetingsLoading = false
      }
    },
    selectMeeting(meeting) {
      if (this.selectedMeeting.uuid === meeting.uuid) return
      this.selectedMeeting = meeting
      this.loadResults()
    },
    async loadResults() {
      if (!this.meetings.length) return
      this.error = false
      try {
        this.loading = true
        const results = await createMeetingOutput({
          workspace_id: this.$route.params.workspace_id,
          story_id: this.selectedMeeting.uuid,
          tool_id: this.template.uuid,
          test: true
        })
        this.content = results?.document?.text || ''
      } catch (e) {
        this.$console.debug('Error while generating output', e)
        this.$toast.error(e, 'generating the preview for this template')
        this.error = true
        this.loading = false
        return
      } finally {
        this.loading = false
      }
    },
    handleRetry() {
      this.loadResults()
    }
  }
}
</script>

<style lang="scss" scoped>
.results {
  padding: 0 0;
  display: grid;
  grid-template-columns: 2fr 1fr;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.75rem;
    border-bottom: 1px solid rgba(#000, 0.08);
  }

  &-content {
    flex: 1;
    background: #f9f9fa;
    padding: 2rem 10rem;
    overflow-y: auto;
  }

  &-block {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    height: 100%;
    min-height: 100vh;

    &-header {
      font-size: 1.25rem;
      font-weight: 700;
      color: #303032;
    }

    &-text {
      background: white;
      padding: 5.75rem;
      border-radius: 2px;
      border: 1px solid rgba(#000, 0.08);
      flex: 1;
    }
  }

  &-prompt {
    margin-bottom: 1rem;
  }

  &-loading {
    background: rgba(#000, 0.04);
  }
}

.results {
  &,
  &-wrapper,
  &-content,
  &-sidebar {
    height: 100%;
    max-height: 100%;
  }
}
</style>
