<template>
  <div class="sales-kits">
    <div class="sales-kits-header">
      <p class="sales-kits-header-title">Deal stages</p>
      <Button text="New deal stage" @click="createSalesKit" />
    </div>
    <div class="sales-kits-content">
      <TextInput
        v-model="query"
        left-icon="search"
        placeholder="Search..."
        :disabled="!(saleskits && saleskits.length)"
        class="sales-kits-content-search"
      />
      <div class="sales-kits-content-filters">
        <Button
          v-for="filter in filterOptions"
          :key="filter.value"
          :text="filter.text"
          :disabled="filter.disabled"
          :type="statusFilter === filter.value ? 'light-solid' : 'white'"
          @click="statusFilter = filter.value"
        />
      </div>
      <Table
        :key="tableKey"
        ref="saleskitstable"
        :items="filteredSalesKits"
        :headers="headers"
        :max-height="maxHeight"
        :clickable="['name']"
        default-sort-by="name"
        @select="(item) => handleViewSalesKit(item)"
      >
        <template #item="{ item, header }">
          <div
            v-if="header.id === 'name'"
            class="sales-kits-table-item"
            :class="{ padded: !item.description }"
          >
            <img
              v-if="item.image"
              :src="item.image"
              alt=""
              class="sales-kits-table-item-image"
            />
            <div class="sales-kits-table-item-content">
              <p class="sales-kits-table-item-content-name">
                {{ item.name }}
              </p>
              <p
                v-if="item.description"
                class="sales-kits-table-item-content-description"
              >
                {{ item.description }}
              </p>
            </div>
          </div>
          <StatusOrb
            v-else-if="header.id === 'status'"
            :type="item.status === 'visible' ? 'green' : 'grey'"
            :text="item.status === 'visible' ? 'Enabled' : 'Disabled'"
            :show-text="true"
          />
          <WorkspaceSalesKitActions
            v-else-if="header.id === 'buttons'"
            class="sales-kits-table-item-actions"
            :saleskit="item"
            :is-core="item.provider === 'core'"
            @duplicate="handleDuplicateSalesKit"
            @delete="handleDeleteSalesKit"
            @publish="handlePublishSalesKit"
            @edit="handleEditSalesKit"
          />
        </template>
      </Table>
      <div v-if="loading" class="sales-kits-content-loading">
        <b-loading active :is-full-page="false"></b-loading>
      </div>
    </div>
    <WorkspaceSalesKitModal
      :visible="modal !== ''"
      :saleskit="modal === 'edit' ? modalSalesKit : undefined"
      @save="(saleskit) => handleSaveSalesKit(saleskit)"
      @close="closeModal"
    />
    <WorkspaceSalesKitInspect
      v-if="inspect"
      :saleskit="inspect"
      @close="inspect = undefined"
      @duplicate="actionFromInspect('duplicate')"
      @edit="actionFromInspect('edit')"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import StatusOrb from '@c/library/StatusOrb.vue'
import Table from '@c/library/Table.vue'
import TextInput from '@c/library/TextInput.vue'
import WorkspaceSalesKitActions from './saleskits/WorkspaceSalesKitActions.vue'
import WorkspaceSalesKitModal from './saleskits/WorkspaceSalesKitModal.vue'
import { mapActions } from 'vuex'
import WorkspaceSalesKitInspect from './saleskits/WorkspaceSalesKitInspect.vue'

export default {
  components: {
    Table,
    TextInput,
    Button,
    StatusOrb,
    WorkspaceSalesKitActions,
    WorkspaceSalesKitModal,
    WorkspaceSalesKitInspect
  },
  data: () => ({
    saleskits: [],
    loading: false,
    query: '',
    statusFilter: 'all',
    tableKey: 0,
    modal: '',
    modalSalesKit: undefined,
    inspect: undefined,
    maxHeight: ''
  }),
  computed: {
    headers() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: (saleskit) => saleskit.name,
          sortAsc: (a, b) => a.name.localeCompare(b.name),
          sortDesc: (a, b) => b.name.localeCompare(a.name)
        },
        {
          id: 'creator',
          title: 'Author',
          key: (saleskit) => this.mapCreator(saleskit.provider),
          sortAsc: (a, b) =>
            this.mapCreator(a.provider).localeCompare(
              this.mapCreator(b.provider)
            ),
          sortDesc: (a, b) =>
            this.mapCreator(b.provider).localeCompare(
              this.mapCreator(a.provider)
            )
        },
        {
          id: 'status',
          title: 'Status',
          key: (saleskit) => saleskit.status,
          sortAsc: (a, b) => a.status.localeCompare(b.status),
          sortDesc: (a, b) => b.status.localeCompare(a.status)
        },
        {
          id: 'buttons',
          title: '',
          key: () => '',
          sortAsc: () => 0,
          sortDesc: () => 0
        }
      ]
    },
    filterOptions() {
      return [
        {
          value: 'all',
          text: 'Show all',
          disabled: (this.saleskits?.length || 0) === 0
        },
        {
          value: 'visible',
          text: 'Enabled',
          disabled: !this.saleskits?.some((t) => t.status === 'visible')
        },
        {
          value: 'hidden',
          text: 'Disabled',
          disabled: !this.saleskits?.some((t) => t.status === 'hidden')
        }
      ]
    },
    filteredSalesKits() {
      return this.saleskits.filter((saleskit) => {
        const queryMatch =
          !this.query ||
          saleskit.name.toLowerCase().includes(this.query.toLowerCase()) ||
          saleskit.description
            ?.toLowerCase()
            .includes(this.query.toLowerCase()) ||
          this.mapCreator(saleskit.provider)
            .toLowerCase()
            .includes(this.query.toLowerCase())
        const statusMatch =
          this.statusFilter === 'all' || saleskit.status === this.statusFilter
        return queryMatch && statusMatch
      })
    }
  },
  created() {
    this.loadSalesKits()
  },
  mounted() {
    this.calculateMaxHeight()
    window.addEventListener('resize', this.calculateMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateMaxHeight)
  },
  methods: {
    ...mapActions(['getSalesKits', 'getSalesKit']),
    calculateMaxHeight() {
      const el = this.$refs.saleskitstable.$el.getBoundingClientRect()
      const body = document.body.getBoundingClientRect()
      if (!el) return
      this.maxHeight = `${body.height - (el.top - body.top)}px`
    },
    mapCreator(provider) {
      return {
        core: 'uman',
        custom: 'Admin'
      }[provider]
    },
    async loadSalesKits() {
      try {
        this.loading = true
        this.saleskits = await this.getSalesKits({
          workspace_id: this.$route.params.workspace_id
        })
      } catch (error) {
        this.$console.debug('Error loading sales kits', error)
        this.$toast.error(error, 'loading the sales kits')
      } finally {
        this.loading = false
      }
    },
    closeModal() {
      this.modal = ''
      this.modalSalesKit = undefined
    },
    createSalesKit() {
      this.modal = 'create'
    },
    saleskitIndex(saleskit) {
      return this.saleskits.findIndex((t) => t.uuid === saleskit.uuid)
    },
    handleDeleteSalesKit(saleskit) {
      this.saleskits.splice(this.saleskitIndex(saleskit), 1)
      this.tableKey++
    },
    handleDuplicateSalesKit() {
      this.tableKey++
    },
    handlePublishSalesKit(saleskit) {
      this.saleskits.splice(this.saleskitIndex(saleskit), 1, saleskit)
      this.tableKey++
    },
    async handleEditSalesKit(saleskit) {
      try {
        const b = await this.getSalesKit({
          workspace_id: this.$route.params.workspace_id,
          sales_kit_id: saleskit.uuid
        })
        this.modal = 'edit'
        this.modalSalesKit = b
      } catch (error) {
        this.$console.debug('Error getting sales kit', error)
        this.$toast.error(error, 'getting the sales kit info')
      }
    },
    handleSaveSalesKit(saleskit) {
      if (this.modal === 'edit') {
        this.saleskits.splice(this.saleskitIndex(saleskit), 1, saleskit)
      }
      this.tableKey++
      this.closeModal()
    },
    handleViewSalesKit(saleskit) {
      this.inspect = saleskit
    },
    actionFromInspect(action) {
      if (action === 'edit') {
        this.handleEditSalesKit(this.inspect)
      } else if (action === 'duplicate') {
        const actions = Array.from(
          document.getElementsByClassName('sales-kits-table-item-actions')
        ).map((a) => a.__vue__)
        const index = actions.findIndex(
          (a) => a.saleskit.uuid === this.inspect.uuid
        )
        actions[index].handleDuplicate()
      }
      this.inspect = undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.sales-kits {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.75rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &-title {
      font-size: 2.25rem;
      font-weight: 700;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.75rem;

    &-search {
      width: min(30rem, 100%);
    }

    &-filters {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-loading {
      position: relative;
      min-height: 5rem;
    }
  }

  &-table-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;

    &-image {
      height: 3rem;
    }

    &-content {
      &-description {
        color: #60666b;
      }
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
