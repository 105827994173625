<template>
  <div class="general">
    <AvatarUpload
      :image="originalImageUrl || imageUrl"
      :width="512"
      :max-size="5"
      @input="
        (url, img) => {
          imageUrl = url
          image = img
        }
      "
    />
    <div class="general-block">
      <p class="general-block-header">Workspace name</p>
      <input
        v-model="name"
        type="text"
        class="general-block-input"
        placeholder="Workspace name"
      />
    </div>
    <div class="general-block">
      <p class="general-block-header">Description</p>
      <textarea
        v-model="description"
        class="general-block-input"
        placeholder="Describe your workspace"
      />
    </div>
    <p
      class="general-confirm"
      :class="{ disabled: !isChanged }"
      @click="onConfirm"
    >
      Update workspace info
    </p>
  </div>
</template>

<script>
import AvatarUpload from '@c/library/AvatarUpload.vue'
import FileToImageConvertMixin from '@c/mixins/FileToImageConvertMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WorkspaceInfoSettings',
  components: { AvatarUpload },
  mixins: [FileToImageConvertMixin],
  data() {
    return {
      name: '',
      originalName: '',
      description: '',
      originalDescription: '',
      image: '',
      imageUrl: '',
      originalImageUrl: ''
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    isChanged() {
      return (
        this.name !== this.originalName ||
        this.description !== this.originalDescription ||
        this.imageUrl !== this.originalImageUrl
      )
    }
  },
  mounted() {
    this.name = this.currentWorkspace.name
    this.originalName = this.currentWorkspace.name
    this.description = this.currentWorkspace.description
    this.originalDescription = this.currentWorkspace.description
    this.originalImageUrl = this.currentWorkspace.image
    this.imageUrl = this.currentWorkspace.image
  },
  methods: {
    ...mapActions(['updateImage', 'patchWorkspace']),
    async onConfirm() {
      try {
        await Promise.all([
          ...(this.imageUrl !== this.originalImageUrl
            ? [
                this.updateImage({
                  id: this.$route.params.workspace_id,
                  image: this.image
                })
              ]
            : []),
          ...(this.name !== this.originalName ||
          this.description !== this.originalDescription
            ? [
                this.patchWorkspace({
                  id: this.$route.params.workspace_id,
                  data: {
                    name: this.name,
                    description: this.description
                  }
                })
              ]
            : [])
        ])
        this.originalName = this.name
        this.originalDescription = this.description
        if (this.imageUrl) this.originalImageUrl = this.imageUrl
        this.$toast.success(
          'Update successful',
          'Your workspace changes have been saved successfully.'
        )
        this.$emit('close')
      } catch (e) {
        this.$console.debug('Workspace update failed', e)
        this.$toast.error(e, 'saving the workspace')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.general {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-block {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.25rem;

    &.horizontal {
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
    }

    &-header {
      font-weight: 600;
    }

    &-input {
      width: max(50vw, 20rem);
      background: #f1f2f3;
      resize: none;
      border-radius: 4px;
      border: 1px solid #e9ebed;
      padding: 0.5rem;

      &:focus,
      &:focus-within {
        background: white;
      }
    }
  }

  &-image {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 12px;
    border: 2px dotted transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      border: 2px dotted #dddfe2;
    }

    &.hasbg {
      background: #9bbbfd;

      &:hover {
        background: darken(#9bbbfd, 5%);
      }
    }

    &-icon {
      height: 4rem;
      filter: brightness(0) invert(1);
      opacity: 0.5;
    }

    &-logo {
      height: 5rem;
      width: 5rem;
      border-radius: 12px;
      object-fit: cover;
    }

    &-action {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.55rem;

      &-btn {
        color: #60666b;
        font-weight: 600;
        cursor: pointer;
        transition: 0.2s;
        padding: 0.5rem 1rem;
        border-radius: 999rem;
        border: 1px solid #dddfe2;
        width: fit-content;

        &:hover {
          color: $primary;
          border-color: $primary;
        }
      }

      &-info {
        font-size: 0.8rem;
        color: #60666b;
      }
    }

    &-upload {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  &-confirm {
    padding: 0.5rem 1rem;
    border-radius: 999rem;
    background: $primary;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;
    width: fit-content;

    &:hover {
      background: darken($primary, 10%);
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
