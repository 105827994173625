var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-preview" },
    [
      !_vm.loading
        ? _c(
            "div",
            { ref: "templatepreview", staticClass: "template-preview-content" },
            [
              _c("img", {
                staticClass: "template-preview-content-img",
                attrs: { src: _vm.slide.preview, alt: "" }
              }),
              _vm._l(_vm.elements, function(element, idx) {
                return _c("div", {
                  key: "preview-element-" + idx,
                  staticClass: "template-preview-content-element",
                  class: {
                    selected: element.uuid === _vm.selected,
                    hovered: element.uuid === _vm.hovered
                  },
                  style: _vm.computedStyle(element),
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return function() {
                        return _vm.$emit("select", element.uuid)
                      }.apply(null, arguments)
                    },
                    mouseenter: function() {
                      return _vm.$emit("hover", element.uuid)
                    },
                    mouseleave: function() {
                      return _vm.$emit("hover", "")
                    }
                  }
                })
              })
            ],
            2
          )
        : _c("b-skeleton")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }