<template>
  <b-modal v-if="saleskit" active @close="() => $emit('close')">
    <div class="sales-kit-inspect">
      <div class="sales-kit-inspect-header">
        <p class="sales-kit-inspect-header-title">{{ saleskit.name }}</p>
        <Button
          icon="close"
          size="xs"
          type="grey"
          @click="() => $emit('close')"
        />
      </div>
      <div class="sales-kit-inspect-content">
        <p
          v-if="saleskit.description"
          class="sales-kit-inspect-content-description"
        >
          {{ saleskit.description }}
        </p>
        <div class="sales-kit-inspect-content-block">
          <p class="sales-kit-inspect-content-block-header">Templates</p>
          <ul class="sales-kit-inspect-content-list">
            <li
              v-for="t in saleskitTemplates"
              :key="t.uuid"
              class="sales-kit-inspect-content-list-item"
            >
              {{ t.name }}
            </li>
          </ul>
          <img
            v-if="loading"
            src="@/assets/icons/spinner.svg"
            alt=""
            class="sales-kit-inspect-content-loading"
          />
        </div>
      </div>
      <div class="sales-kit-inspect-footer">
        <Button text="Close" type="white" @click="() => $emit('close')" />
        <Button
          v-if="isCore"
          text="Duplicate"
          icon="copy-medium"
          :icon-left="true"
          @click="() => $emit('duplicate')"
        />
        <Button
          v-else
          text="Edit"
          icon="edit"
          :icon-left="true"
          @click="() => $emit('edit')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'
export default {
  name: 'WorkspaceSalesKitInspect',
  components: { Button },
  props: {
    saleskit: {
      type: Object,
      default: () => undefined
    }
  },
  data: () => ({
    loading: '',
    fullSalesKit: undefined
  }),
  computed: {
    isCore() {
      return this.saleskit?.provider === 'core'
    },
    saleskitTemplates() {
      return this.fullSalesKit?.tools || []
    }
  },
  watch: {
    saleskit: {
      handler(val) {
        if (val) this.loadSalesKit()
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['getSalesKit']),
    async loadSalesKit() {
      try {
        this.loading = true
        this.fullSalesKit = await this.getSalesKit({
          workspace_id: this.$route.params.workspace_id,
          sales_kit_id: this.saleskit.uuid
        })
      } catch (e) {
        this.$console.debug('Error when loading saleskit', e)
        this.$toast.error(e, 'loading the saleskit')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sales-kit-inspect {
  border-radius: 4px;
  background-color: white;
  width: 25rem;

  &-header {
    padding: 1.5rem 2.25rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  &-content {
    padding: 1.5rem 2.25rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-description {
      color: #60666b;
    }

    &-block {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      background: #f9f9fa;

      &-header {
        font-weight: 700;
      }
    }

    &-list {
      list-style: disc;
      padding-left: 1.5rem;

      &-item {
        color: #60666b;
      }
    }

    &-loading {
      width: 2rem;
      padding: 0.5rem 0;
      margin: 0 auto;
      opacity: 0.3;
      animation: spin-data-v-8a01a368 1s linear infinite;
    }
  }

  &-footer {
    padding: 1.5rem 2.25rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba(#000, 0.08);
    gap: 0.5rem;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
