<template>
  <div class="template-actions">
    <Dropdown v-if="!loading" :items="dropdownItems" :position-fixed="true">
      <template #trigger>
        <Button icon="kebab" type="grey" size="xs" />
      </template>
    </Dropdown>
    <div v-else class="template-actions-loading">
      <img
        src="@/assets/icons/spinner.svg"
        alt=""
        class="template-actions-loading-icon"
      />
    </div>
    <b-modal :active="modal === 'delete'" @close="() => (modal = '')">
      <div class="confirm-remove">
        <div class="confirm-remove-header">Remove template</div>
        <div class="confirm-remove-body">
          <p class="confirm-remove-body-header">
            Remove "{{ template.name }}"?
          </p>
          <div class="confirm-remove-body-text-wrapper">
            <p class="confirm-remove-body-text">
              Are you sure you want to remove this template? This action cannot
              be undone.
            </p>
            <p v-if="published" class="confirm-remove-body-text warning">
              Removing this template will also remove the content it has
              generated in your company portfolio.
            </p>
            <p v-if="published" class="confirm-remove-body-text">
              To confirm this action, please type the name of the template
              below.
            </p>
            <TextInput
              v-if="published"
              v-model="confirmRemove"
              placeholder="Type the template name to confirm removal"
            />
          </div>
        </div>
        <div class="confirm-remove-btns">
          <Button
            text="Cancel"
            type="white"
            :disabled="loading"
            @click="() => (modal = '')"
          />
          <Button
            text="Remove template"
            :loading="loading"
            :disabled="confirmDisabled"
            @click="removeTemplate"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import Dropdown from '@c/library/Dropdown.vue'
import Button from '@c/library/Button.vue'
import {
  createKeyContentTemplate,
  removeKeyContentTemplate
} from '@/services/keyContentService'

export default {
  name: 'PortfolioTemplateActions',
  components: {
    TextInput,
    Dropdown,
    Button
  },
  props: {
    template: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    modal: '',
    loading: false,
    confirmRemove: ''
  }),
  computed: {
    published() {
      return this.template.status === 'published'
    },
    confirmDisabled() {
      if (!this.published) return false
      return (
        this.confirmRemove.toLowerCase() !==
        (this.template?.name || '').toLowerCase()
      )
    },
    dropdownItems() {
      return [
        ...(this.template.status !== 'published'
          ? [
              {
                text: 'Edit & test',
                icon: 'edit',
                callback: () => this.$emit('edit')
              },
              {
                text: 'Publish',
                icon: 'status_green',
                callback: () => this.$emit('publish')
              }
            ]
          : [
            {
              text: 'Inspect',
              icon: 'eye-open',
              callback: () => this.$emit('inspect')
            }
          ]),
        {
          text: 'Duplicate',
          icon: 'copy-medium',
          callback: () => this.duplicateTemplate()
        },
        {
          text: 'Remove',
          icon: 'bin',
          filter: 'red',
          callback: () => (this.modal = 'delete')
        }
      ]
    }
  },
  methods: {
    async duplicateTemplate() {
      try {
        this.loading = true
        const res = await createKeyContentTemplate({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid
        })
        this.$emit('duplicate', res)
      } catch (e) {
        this.$toast.error(e, 'duplicating the template')
      } finally {
        this.loading = false
      }
    },
    async removeTemplate() {
      try {
        this.loading = true
        await removeKeyContentTemplate({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid
        })
        this.$toast.success('Template removed')
        this.$emit('remove')
        this.modal = ''
      } catch (e) {
        this.$toast.error(e, 'removing the template')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-actions {
  max-width: fit-content;

  &-loading {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 1.2rem;
      height: 1.2rem;
      animation: spin 1s linear infinite;
    }
  }
}

.confirm-remove {
  background: white;
  border: 1px solid rgba(#000, 8%);
  border-radius: 4px;
  width: min(45rem, 90vw);

  &-header {
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(#000, 8%);
    font-size: 1.2rem;
    font-weight: 700;
    color: #fc0d1b;
  }

  &-body {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1rem 2rem;

    &-header {
      font-size: 1.2rem;
      font-weight: 700;
    }

    &-text {
      color: #9098ae;

      &.warning {
        color: #303032;
        font-weight: 600;
      }

      &-wrapper {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
      }
    }
  }

  &-btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem 2rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
