<template>
  <div class="ambassadors">
    <div class="ambassadors-header">
      <p class="ambassadors-header-title">Prospecting ambassadors</p>
    </div>
    <div v-if="integration" class="ambassadors-add">
      <TextInput
        v-model="linkedinUrl"
        title="Add new ambassador"
        placeholder="https://www.linkedin.com/in/..."
        :disabled="ambassadorsLoading || !integration"
        class="ambassadors-add-input"
        @submit="addAmbassador"
      />
      <Button
        text="Add ambassador"
        :disabled="!linkedinUrl || !integration || ambassadorsLoading"
        :loading="addAmbassadorLoading"
        @click="addAmbassador"
      />
    </div>
    <div ref="ambassadorlist" class="ambassadors-list" :style="maxHeightStyle">
      <div
        v-for="ambassador in ambassadors"
        :key="ambassador.uuid"
        class="ambassadors-list-item"
      >
        <Avatar :user="ambassador" fallback-icon="user" size="m" />
        <div class="ambassadors-list-item-info">
          {{ $umodel.full_name(ambassador) }}
          <span
            v-if="ambassador.role"
            class="ambassadors-list-item-info-role"
            >{{ ambassador.role }}</span
          >
        </div>
        <img
          v-if="ambassador.url"
          src="@/assets/logo/linkedin.svg"
          alt=""
          class="ambassadors-list-item-linkedin"
          @click="() => gotoLinkedIn(ambassador)"
        />
        <StatusOrb
          v-if="ambassador.status !== 'unsupported'"
          :type="statusColor(ambassador)"
          :text="`Contact sync ${ambassador.status}`"
        />
        <b-tooltip
          v-else
          label="This account is private, we can't get this person's contacts"
          type="is-dark"
          :position="tooltipAlignment"
          multilined
          @mouseover.native="checkAlignment"
        >
          <img
            src="@/assets/icons/check-warning.svg"
            alt=""
            class="ambassadors-list-item-warning"
          />
        </b-tooltip>
        <div class="ambassadors-list-item-actions">
          <b-tooltip label="Remove" position="is-top" type="is-dark">
            <Button
              text="Remove"
              icon="bin"
              type="white"
              :loading="removeAmbassadorLoading === ambassador.uuid"
              @click="() => removeAmbassador(ambassador)"
            />
          </b-tooltip>
        </div>
      </div>
      <div
        v-if="!ambassadorsLoading && !ambassadors.length"
        class="ambassadors-list-status"
      >
        <div v-if="!integration">
          Please connect your LinkedIn account to uman before adding
          ambassadors. This helps us find all their valuable connections that
          can turn into warm introductions.
          <Button text="Connect LinkedIn" @click="openIntegrations" />
        </div>
        <div v-else-if="ambassadorsError">
          Something went wrong while trying to load your ambassadors. Please try
          again later or contact support.
          <Button text="Retry" @click="loadAmbassadors" />
        </div>
        <div v-else>
          No ambassadors have been added yet. Add ambassadors with their
          personal LinkedIn url to get warm introduction recommendations.
        </div>
      </div>
      <div v-else-if="ambassadorsLoading" class="ambassadors-list-loading">
        <b-loading active :is-full-page="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import Avatar from '@c/library/Avatar.vue'
import {
  createProfile,
  deleteProfile,
  getProfiles
} from '@/services/profileService'
import StatusOrb from '@c/library/StatusOrb.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'WorkspaceProspectingSettings',
  components: {
    Button,
    TextInput,
    Avatar,
    StatusOrb
  },
  mixins: [MaxHeightMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    integration: undefined,
    ambassadors: [],
    ambassadorsLoading: false,
    ambassadorsError: '',
    linkedinUrl: '',
    addAmbassadorLoading: false,
    removeAmbassadorLoading: '',
    tooltipAlignment: 'is-left'
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'integrationsByWorkspace'])
  },
  created() {
    this.loadAmbassadors()
  },
  methods: {
    ...mapActions(['getIntegrations']),
    getMaxHeightElement() {
      return this.$refs.ambassadorlist
    },
    async loadAmbassadors() {
      try {
        this.ambassadorsError = false
        this.ambassadorsLoading = true
        let integrations =
          this.integrationsByWorkspace?.[this.$route.params.workspace_id] ||
          (await this.getIntegrations({
            workspace_id: this.$route.params.workspace_id
          }))
        const linkedinIntegration = integrations.find(
          (i) => i.type === 'linkedin'
        )
        if (!linkedinIntegration) {
          this.ambassadorsLoading = false
          return
        }
        this.integration = linkedinIntegration
        this.ambassadors = await getProfiles({
          workspace_id: this.$route.params.workspace_id,
          integration_id: this.integration.id
        })
      } catch (e) {
        this.ambassadorsError = true
        this.$console.debug('Error loading ambassadors', e)
        this.$toast.error(e, 'loading the ambassadors')
      } finally {
        this.ambassadorsLoading = false
      }
    },
    close() {
      this.linkedinUrl = ''
      this.$emit('close')
    },
    statusColor(ambassador) {
      const nonPending = {
        success: 'green',
        failure_extraction: 'red'
      }
      return Object.keys(nonPending).includes(ambassador.status)
        ? nonPending[ambassador.status]
        : 'orange'
    },
    checkAlignment(e) {
      this.tooltipAlignment =
        e.clientX > window.innerWidth / 2 ? 'is-left' : 'is-right'
    },
    gotoLinkedIn(ambassador) {
      window.open(ambassador.url, '_blank')
    },
    openIntegrations() {
      this.$router.push({
        name: 'ws-settings',
        params: { workspace_id: this.$route.params.workspace_id, tab: 'apps' }
      })
      this.$modals.workspace.openSettings(this, this.currentWorkspace)
    },
    async addAmbassador() {
      try {
        this.addAmbassadorLoading = true
        const ambassador = await createProfile({
          workspace_id: this.$route.params.workspace_id,
          integration_id: this.integration.id,
          identifier: this.linkedinUrl
        })
        this.ambassadors.push(ambassador)
        this.linkedinUrl = ''
        this.$toast.success('Ambassador added successfully')
      } catch (e) {
        this.$console.debug('Error adding ambassador', e)
        this.$toast.error(e, 'adding the ambassador')
      } finally {
        this.addAmbassadorLoading = false
      }
    },
    async removeAmbassador(ambassador) {
      try {
        this.removeAmbassadorLoading = ambassador.uuid
        await deleteProfile({
          workspace_id: this.$route.params.workspace_id,
          integration_id: this.integration.id,
          profile_id: ambassador.uuid
        })
        this.ambassadors = this.ambassadors.filter(
          (a) => a.url !== ambassador.url
        )
        this.$toast.success('Ambassador removed successfully')
      } catch (e) {
        this.$console.debug('Error removing ambassador', e)
        this.$toast.error(e, 'removing the ambassador')
      } finally {
        this.removeAmbassadorLoading = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ambassadors {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.25rem;

    &-title {
      font-weight: 700;
      font-size: 2.25rem;
      color: #303032;
    }
  }

  &-add {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    gap: 0.75rem;

    &-input {
      flex: 1;
    }
  }

  &-list {
    padding: 1rem 0 1.5rem;
    overflow-y: auto;

    &-item {
      padding: 1rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.08);
      }

      &:hover {
        background: rgba(#000, 0.04);
      }

      &-info {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.25rem;

        &-role {
          color: #60666b;
        }
      }

      &-linkedin {
        cursor: pointer;
      }

      &-warning {
        height: 1.2rem;
        filter: brightness(0) saturate(100%) invert(24%) sepia(98%)
          saturate(5070%) hue-rotate(347deg) brightness(93%) contrast(113%);
      }

      &-actions {
        margin-left: auto;
      }
    }

    &-status {
      color: #60666b;
      text-align: center;
      min-height: 5rem;
      padding: 1rem;

      & > div {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 1rem;
      }
    }

    &-loading {
      position: relative;
      min-height: 5rem;
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
