var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sales-kits" },
    [
      _c(
        "div",
        { staticClass: "sales-kits-header" },
        [
          _c("p", { staticClass: "sales-kits-header-title" }, [
            _vm._v("Deal stages")
          ]),
          _c("Button", {
            attrs: { text: "New deal stage" },
            on: { click: _vm.createSalesKit }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "sales-kits-content" },
        [
          _c("TextInput", {
            staticClass: "sales-kits-content-search",
            attrs: {
              "left-icon": "search",
              placeholder: "Search...",
              disabled: !(_vm.saleskits && _vm.saleskits.length)
            },
            model: {
              value: _vm.query,
              callback: function($$v) {
                _vm.query = $$v
              },
              expression: "query"
            }
          }),
          _c(
            "div",
            { staticClass: "sales-kits-content-filters" },
            _vm._l(_vm.filterOptions, function(filter) {
              return _c("Button", {
                key: filter.value,
                attrs: {
                  text: filter.text,
                  disabled: filter.disabled,
                  type:
                    _vm.statusFilter === filter.value ? "light-solid" : "white"
                },
                on: {
                  click: function($event) {
                    _vm.statusFilter = filter.value
                  }
                }
              })
            }),
            1
          ),
          _c("Table", {
            key: _vm.tableKey,
            ref: "saleskitstable",
            attrs: {
              items: _vm.filteredSalesKits,
              headers: _vm.headers,
              "max-height": _vm.maxHeight,
              clickable: ["name"],
              "default-sort-by": "name"
            },
            on: {
              select: function(item) {
                return _vm.handleViewSalesKit(item)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var header = ref.header
                  return [
                    header.id === "name"
                      ? _c(
                          "div",
                          {
                            staticClass: "sales-kits-table-item",
                            class: { padded: !item.description }
                          },
                          [
                            item.image
                              ? _c("img", {
                                  staticClass: "sales-kits-table-item-image",
                                  attrs: { src: item.image, alt: "" }
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "sales-kits-table-item-content" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "sales-kits-table-item-content-name"
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                item.description
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "sales-kits-table-item-content-description"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.description) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      : header.id === "status"
                      ? _c("StatusOrb", {
                          attrs: {
                            type: item.status === "visible" ? "green" : "grey",
                            text:
                              item.status === "visible"
                                ? "Enabled"
                                : "Disabled",
                            "show-text": true
                          }
                        })
                      : header.id === "buttons"
                      ? _c("WorkspaceSalesKitActions", {
                          staticClass: "sales-kits-table-item-actions",
                          attrs: {
                            saleskit: item,
                            "is-core": item.provider === "core"
                          },
                          on: {
                            duplicate: _vm.handleDuplicateSalesKit,
                            delete: _vm.handleDeleteSalesKit,
                            publish: _vm.handlePublishSalesKit,
                            edit: _vm.handleEditSalesKit
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "sales-kits-content-loading" },
                [
                  _c("b-loading", {
                    attrs: { active: "", "is-full-page": false }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("WorkspaceSalesKitModal", {
        attrs: {
          visible: _vm.modal !== "",
          saleskit: _vm.modal === "edit" ? _vm.modalSalesKit : undefined
        },
        on: {
          save: function(saleskit) {
            return _vm.handleSaveSalesKit(saleskit)
          },
          close: _vm.closeModal
        }
      }),
      _vm.inspect
        ? _c("WorkspaceSalesKitInspect", {
            attrs: { saleskit: _vm.inspect },
            on: {
              close: function($event) {
                _vm.inspect = undefined
              },
              duplicate: function($event) {
                return _vm.actionFromInspect("duplicate")
              },
              edit: function($event) {
                return _vm.actionFromInspect("edit")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }