var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.saleskit
    ? _c(
        "b-modal",
        {
          attrs: { active: "" },
          on: {
            close: function() {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { staticClass: "sales-kit-inspect" }, [
            _c(
              "div",
              { staticClass: "sales-kit-inspect-header" },
              [
                _c("p", { staticClass: "sales-kit-inspect-header-title" }, [
                  _vm._v(_vm._s(_vm.saleskit.name))
                ]),
                _c("Button", {
                  attrs: { icon: "close", size: "xs", type: "grey" },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "sales-kit-inspect-content" }, [
              _vm.saleskit.description
                ? _c(
                    "p",
                    { staticClass: "sales-kit-inspect-content-description" },
                    [_vm._v(" " + _vm._s(_vm.saleskit.description) + " ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "sales-kit-inspect-content-block" }, [
                _c(
                  "p",
                  { staticClass: "sales-kit-inspect-content-block-header" },
                  [_vm._v("Templates")]
                ),
                _c(
                  "ul",
                  { staticClass: "sales-kit-inspect-content-list" },
                  _vm._l(_vm.saleskitTemplates, function(t) {
                    return _c(
                      "li",
                      {
                        key: t.uuid,
                        staticClass: "sales-kit-inspect-content-list-item"
                      },
                      [_vm._v(" " + _vm._s(t.name) + " ")]
                    )
                  }),
                  0
                ),
                _vm.loading
                  ? _c("img", {
                      staticClass: "sales-kit-inspect-content-loading",
                      attrs: {
                        src: require("@/assets/icons/spinner.svg"),
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "sales-kit-inspect-footer" },
              [
                _c("Button", {
                  attrs: { text: "Close", type: "white" },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _vm.isCore
                  ? _c("Button", {
                      attrs: {
                        text: "Duplicate",
                        icon: "copy-medium",
                        "icon-left": true
                      },
                      on: {
                        click: function() {
                          return _vm.$emit("duplicate")
                        }
                      }
                    })
                  : _c("Button", {
                      attrs: { text: "Edit", icon: "edit", "icon-left": true },
                      on: {
                        click: function() {
                          return _vm.$emit("edit")
                        }
                      }
                    })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }