var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "template-sidebar" }, [
    _c("div", { staticClass: "template-sidebar-section" }, [
      _c("p", { staticClass: "template-sidebar-section-title" }, [
        _vm._v("Elements")
      ]),
      _c(
        "div",
        { staticClass: "template-sidebar-section-list" },
        _vm._l(4, function(idx) {
          return _c("b-skeleton", {
            key: "template-list-loading-" + idx,
            attrs: { height: "4rem", width: "100%" }
          })
        }),
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "template-sidebar-section" },
      [
        _c("p", { staticClass: "template-sidebar-section-title" }, [
          _vm._v("Template info")
        ]),
        _c("TextInput", {
          attrs: {
            title: "Name",
            disabled: true,
            placeholder: "Template name"
          },
          model: {
            value: _vm.currentName,
            callback: function($$v) {
              _vm.currentName = $$v
            },
            expression: "currentName"
          }
        }),
        _c("TextInput", {
          attrs: {
            title: "Description",
            disabled: true,
            placeholder: "Describe your template"
          },
          model: {
            value: _vm.currentDescription,
            callback: function($$v) {
              _vm.currentDescription = $$v
            },
            expression: "currentDescription"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }