var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "b-modal",
        {
          attrs: { active: "" },
          on: {
            close: function() {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { staticClass: "sales-kit-modal" }, [
            _c(
              "div",
              { staticClass: "sales-kit-modal-header" },
              [
                _c("p", { staticClass: "sales-kit-modal-header-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _c("Button", {
                  attrs: { icon: "close", size: "xs", type: "grey" },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "sales-kit-modal-content" },
              [
                _c("TextInput", {
                  attrs: { title: "Name", placeholder: "Deal stage name" },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                }),
                _c("div", { staticClass: "sales-kit-modal-content-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "sales-kit-modal-content-header" },
                    [
                      _c(
                        "p",
                        { staticClass: "sales-kit-modal-content-header-title" },
                        [_vm._v(" Add sales templates to this deal stage ")]
                      ),
                      _c("TextInput", {
                        attrs: {
                          placeholder: "Search...",
                          "left-icon": "search",
                          "button-icon": _vm.query ? "close" : ""
                        },
                        on: {
                          submit: function() {
                            return (_vm.query = "")
                          }
                        },
                        model: {
                          value: _vm.query,
                          callback: function($$v) {
                            _vm.query = $$v
                          },
                          expression: "query"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "sales-kit-modal-content-filters" },
                    _vm._l(_vm.filterOptions, function(f) {
                      return _c("Button", {
                        key: f.value,
                        attrs: {
                          text: f.label,
                          type: _vm.filter === f.value ? "light-solid" : "white"
                        },
                        on: {
                          click: function() {
                            return (_vm.filter = f.value)
                          }
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "sales-kit-modal-content-list" },
                    [
                      _vm._l(_vm.filteredTemplates, function(template) {
                        return _c(
                          "div",
                          {
                            key: template.uuid,
                            staticClass: "sales-kit-modal-content-list-item",
                            class: {
                              selected: _vm.selected.includes(template.uuid)
                            },
                            on: {
                              click: function() {
                                return _vm.handleSelect(template)
                              }
                            }
                          },
                          [
                            _c("Checkbox", {
                              staticClass:
                                "sales-kit-modal-content-list-item-checkbox",
                              attrs: {
                                value: _vm.selected.includes(template.uuid)
                              },
                              on: {
                                input: function() {
                                  return _vm.handleSelect(template)
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "sales-kit-modal-content-list-item-content"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "sales-kit-modal-content-list-item-name"
                                  },
                                  [_vm._v(" " + _vm._s(template.name) + " ")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "sales-kit-modal-content-list-item-description"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(template.description) + " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      _vm.templatesLoading
                        ? _c(
                            "div",
                            { staticClass: "sales-kit-modal-content-loading" },
                            [
                              _c("b-loading", {
                                attrs: { active: "", "is-full-page": false }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "sales-kit-modal-footer" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    loading: _vm.loading
                  },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("Button", {
                  attrs: {
                    text: "Save",
                    disabled: !_vm.name,
                    loading: _vm.loading
                  },
                  on: { click: _vm.save }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }