var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c(
        "div",
        { staticClass: "key-content-status" },
        [
          _vm._m(0),
          _vm._m(1),
          _c("Button", {
            attrs: { text: "Retry" },
            on: {
              click: function() {
                return _vm.$emit("retry")
              }
            }
          })
        ],
        1
      )
    : _vm.empty
    ? _c("div", { staticClass: "key-content-status" }, [
        _vm._m(2),
        _c("div", { staticClass: "key-content-status-content" }, [
          _c("span", [
            _vm._v(
              "No templates for " +
                _vm._s(_vm.tab.name.toLowerCase()) +
                " key content have been added yet"
            )
          ]),
          _c("span", [
            _vm._v(
              "We will generate the content specified here for all " +
                _vm._s(_vm.tab.name.toLowerCase())
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-content-status-img" }, [
      _c("img", {
        staticClass: "key-content-status-img-icon",
        attrs: { src: require("@/assets/icons/check-warning.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-content-status-content" }, [
      _c("span", [
        _vm._v("Something went wrong while loading your key content templates")
      ]),
      _c("span", [_vm._v("Please try again later or contact support")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "key-content-status-img" }, [
      _c("img", {
        staticClass: "key-content-status-img-icon",
        attrs: { src: require("@/assets/icons/key.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }