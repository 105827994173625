<template>
  <div v-if="header.id === 'name'" class="template-item-name">
    <img
      :src="
        require(`@/assets/file_type/${
          template.file_type === 'pptx' ? 'powerpoint' : 'word'
        }.svg`)
      "
      alt=""
      class="template-item-name-icon"
    />
    {{ header.key(template) }}
  </div>
  <StatusOrb
    v-else-if="header.id === 'status'"
    :type="header.key(template) === 'published' ? 'green' : 'orange'"
    :text="header.key(template).capitalize()"
    :show-text="true"
  />
  <div v-else-if="header.id === 'content'" class="template-item-content">
    <Button
      v-if="template.status === 'published'"
      :text="`${template.files_count} ${oriTypeName}s`"
      icon="arrow-right"
      type="light-solid"
      @click="() => $emit('content', template)"
    />
    <span v-else>-</span>
  </div>
  <PortfolioTemplateActions
    v-else-if="tab.key !== 'default' && header.id === 'actions'"
    :template="template"
    class="template-item-actions"
    v-on="$listeners"
  />
  <p v-else class="template-item-default">
    {{ header.key(template) }}
  </p>
</template>

<script>
import PortfolioTemplateActions from './PortfolioTemplateActions.vue'
import StatusOrb from '@c/library/StatusOrb.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'PortfolioTemplateItem',
  components: { PortfolioTemplateActions, StatusOrb, Button },
  props: {
    header: {
      type: Object,
      required: true
    },
    template: {
      type: Object,
      required: true
    },
    tab: {
      type: Object,
      required: true
    }
  },
  computed: {
    oriTypeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspiration'
      }[this.tab.key]
    }
  }
}
</script>

<style lang="scss" scoped>
.template-item {
  &-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0.25rem 0;

    &-icon {
      height: 1.5rem;
    }
  }

  &-actions {
    margin-left: auto;
  }
}
</style>
