var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "story-template" },
    [
      _c("EditPresentationTemplate", {
        attrs: { "template-id": _vm.templateUuid, "is-edit": _vm.isEdit },
        on: {
          update: function(t) {
            return _vm.$emit("update", t)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }