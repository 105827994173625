var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "template-add" }, [
      _c(
        "div",
        { staticClass: "template-add-header" },
        [
          _c("p", { staticClass: "template-add-header-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? "Edit portfolio content section"
                    : "Add content from portfolio"
                ) +
                " "
            )
          ]),
          _c("Button", {
            attrs: { icon: "close", type: "grey", size: "xs" },
            on: {
              click: function() {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      ),
      !_vm.step
        ? _c("div", { staticClass: "template-add-content first" }, [
            _c("p", { staticClass: "template-add-content-header" }, [
              _vm._v("Content type")
            ]),
            _c(
              "div",
              { staticClass: "template-add-content-options" },
              _vm._l(_vm.ori_types, function(t) {
                return _c(
                  "div",
                  {
                    key: t.key,
                    staticClass: "template-add-content-options-option",
                    class: { selected: t.key === _vm.type },
                    on: {
                      click: function() {
                        return _vm.selectType(t)
                      }
                    }
                  },
                  [
                    _c("Checkbox", {
                      staticClass: "template-add-content-options-option-radio",
                      attrs: { value: t.key === _vm.type, type: "radio" },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "template-add-content-options-option-info"
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-add-content-options-option-title"
                          },
                          [_vm._v(" " + _vm._s(t.title) + " ")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "template-add-content-options-option-description"
                          },
                          [_vm._v(" " + _vm._s(t.description) + " ")]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _c(
            "div",
            { staticClass: "template-add-content" },
            [
              _c("p", { staticClass: "template-add-content-text" }, [
                _vm._v(
                  " Select one or more portfolio templates to add to this meeting template. Reorder them as needed. We will insert the first available template in the list for the selected portfolio item in the meeting outputs. "
                )
              ]),
              _vm.selected.length
                ? _c("DraggableList", {
                    staticClass: "template-add-content-selected",
                    attrs: { items: _vm.selected },
                    on: { reorder: _vm.handleReorderSelected },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "template-add-content-selected-item"
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "template-add-content-selected-item-icon",
                                    attrs: {
                                      src: require("@/assets/file_type/" +
                                        (_vm.isPresentation(item)
                                          ? "powerpoint"
                                          : "word") +
                                        ".svg"),
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "template-add-content-selected-item-name"
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                  _c("Button", {
                                    attrs: { icon: "bin", type: "white" },
                                    on: {
                                      click: function() {
                                        return _vm.removeSelected(index)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1573327782
                    )
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "template-add-content-list" },
                [
                  _vm._l(_vm.filteredTemplates, function(template) {
                    return _c("TemplateCard", {
                      key: template.uuid,
                      attrs: { template: template, "show-source": false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "action",
                            fn: function() {
                              return [
                                _c("Button", {
                                  attrs: {
                                    text: "Add",
                                    size: "xs",
                                    icon: "plus-medium"
                                  },
                                  on: {
                                    click: function() {
                                      return _vm.selectTemplate(template)
                                    }
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "template-add-content-list-loading" },
                        [
                          _c("img", {
                            staticClass:
                              "template-add-content-list-loading-icon",
                            attrs: {
                              src: require("@/assets/icons/spinner.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v(" Loading templates ")
                        ]
                      )
                    : !_vm.filteredTemplates || !_vm.filteredTemplates.length
                    ? _c(
                        "p",
                        { staticClass: "template-add-content-list-empty" },
                        [
                          _vm._v(
                            " No" +
                              _vm._s(
                                _vm.selectableTemplates.length ? " more" : ""
                              ) +
                              " templates available "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "template-add-footer" },
        [
          !_vm.isEdit
            ? _c("Button", {
                attrs: {
                  text: _vm.step ? "Change type" : "Cancel",
                  type: "white"
                },
                on: { click: _vm.close }
              })
            : _vm._e(),
          _c("Button", {
            attrs: { text: _vm.submitButton, disabled: _vm.submitDisabled },
            on: { click: _vm.submit }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }