var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element-edit" }, [
    _c(
      "div",
      { staticClass: "element-edit-header" },
      [
        _vm._v(" " + _vm._s(_vm.isEdit ? "Edit" : "Inspect") + " element "),
        _c("Button", {
          attrs: { icon: "close", type: "grey", size: "xs" },
          on: {
            click: function() {
              return _vm.$emit("close")
            }
          }
        })
      ],
      1
    ),
    _vm.uuid && (_vm.outputType === "reference" || _vm.isStoryTemplate)
      ? _c(
          "div",
          { staticClass: "element-edit-section" },
          [
            _c("p", { staticClass: "element-edit-section-header" }, [
              _vm._v("Element type")
            ]),
            _c("SegmentedButton", {
              attrs: {
                "text-left":
                  _vm.element.content_type === "text" ? "Text" : "Static image",
                "text-right": "Client logo",
                "type-left": _vm.isLogo ? "white" : "light-solid",
                "type-right": _vm.isLogo ? "light-solid" : "white",
                disabled: !_vm.isEdit
              },
              on: {
                "click-left": function() {
                  return (_vm.isLogo = false)
                },
                "click-right": function() {
                  return (_vm.isLogo = true)
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.uuid && _vm.element.content_type === "text"
      ? _c(
          "div",
          { staticClass: "element-edit-section" },
          [
            _vm.isEdit
              ? _c("TextArea", {
                  attrs: {
                    placeholder: _vm.promptPlaceholder,
                    disabled: _vm.isLogo,
                    "min-height": "15vh",
                    "max-height": "40vh",
                    "max-length": 1500
                  },
                  model: {
                    value: _vm.prompt,
                    callback: function($$v) {
                      _vm.prompt = $$v
                    },
                    expression: "prompt"
                  }
                })
              : _c("div", { staticClass: "element-edit-section-text" }, [
                  _c("span", { staticClass: "bold" }, [_vm._v("Prompt")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.prompt ||
                          "No prompt was configured for this element"
                      ) +
                      " "
                  )
                ])
          ],
          1
        )
      : _vm._e(),
    _vm.uuid && _vm.element.content_type !== "text" && !_vm.isEdit
      ? _c("div", { staticClass: "element-edit-section" }, [
          _c("div", { staticClass: "element-edit-section-text" }, [
            _c("span", { staticClass: "bold" }, [_vm._v("Image")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.elementChanged
                    ? ""
                    : "- No changes were made to this image"
                ) +
                " "
            )
          ])
        ])
      : _vm._e(),
    _vm.isEdit
      ? _c(
          "div",
          { staticClass: "element-edit-footer" },
          [
            _c("p", { staticClass: "element-edit-sub" }, [
              _vm._v(" " + _vm._s(_vm.imageFooter) + " ")
            ]),
            _c("Button", {
              attrs: {
                text: "Save",
                loading: _vm.loading,
                disabled: !_vm.elementChanged
              },
              on: { click: _vm.handleSave }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }