<template>
  <div class="template-slides-add" @click="() => $emit('click')">
    <div class="template-slides-add-line"></div>
    <div class="template-slides-add-icon">
      <img
        src="@/assets/icons/plus-medium.svg"
        alt=""
        class="template-slides-add-icon-img"
      />
      <p class="template-slides-add-icon-text">Insert portfolio templates</p>
    </div>
    <div class="template-slides-add-line"></div>
  </div>
</template>

<script>
export default {
  name: 'TemplateSlideBlockAdd'
}
</script>

<style lang="scss" scoped>
.template-slides-add {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0;

  &:hover {
    & .template-slides-add-line {
      background: #6999fc;
    }

    & .template-slides-add-icon {
      &-img {
        filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
          saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
      }

      &-text {
        color: $primary;
        max-width: 25ch;
        padding-right: 0.5rem;
      }
    }
  }

  &-line {
    height: 1px;
    background: #dddfe2;
    flex: 1;
  }

  &-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0 0 0.5rem;

    &-img {
      height: 1rem;
      filter: brightness(0) saturate(100%) invert(71%) sepia(3%) saturate(643%)
        hue-rotate(177deg) brightness(83%) contrast(82%);
    }

    &-text {
      font-weight: 600;
      color: #8f9399;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      transition: max-width 0.3s;
    }
  }
}
</style>
