<template>
  <div class="members">
    <p class="members-header">
      <span class="members-header-title"> Members </span>
      <Button text="Invite members" @click="openInviteModal" />
    </p>
    <div class="members-search-wrapper">
      <input
        v-model="memberQuery"
        type="text"
        class="members-search"
        placeholder="Search..."
        @keyup="handleSearch()"
      />
      <img
        v-if="memberQuery"
        src="@/assets/icons/close.svg"
        alt=""
        class="members-search-clear"
        @click="handleClear"
      />
    </div>
    <b-tabs v-model="currentTab" class="members-tabs">
      <b-tab-item v-for="tab in tabs" :key="tab.key" :value="tab.key">
        <template #header>
          <div class="members-tabs-tab-label">
            {{ tab.name }} ({{ filteredCountsByTab[tab.key] }})
          </div>
        </template>
        <div class="members-tabs-tab">
          <MembersTable
            v-if="tab.key === 'active'"
            :amount-of-members="totalCountsByTab[tab.key]"
            :data="membersByTab[tab.key]"
            :loading="loadingMembers"
            :workspace="currentWorkspace"
            @loadMore="handleLoadMore"
            @removeMember="handleRemoveMember"
            @updateRoles="updateRoles"
            @filter="handleFilter"
          />
          <PendingEmailInvites
            v-else
            :invites="membersByTab[tab.key]"
            @updateInvite="updateInvite"
            @deleteInvite="deleteInvite"
          />
        </div>
      </b-tab-item>
    </b-tabs>
    <b-modal v-model="inviteModalOpen">
      <WorkspaceMemberInviteModal
        @close="closeInviteModal"
        @sendInvites="sendInvites"
      />
    </b-modal>
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import {
  deleteInvite,
  getInvites,
  postInvite
} from '@/services/inviteService.js'
import PendingEmailInvites from '@c/features/invite-modal/organisms/PendingEmailInvites.vue'
import MembersTable from '@c/features/workspace-settings-modal/organisms/MembersTable.vue'
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import WorkspaceMemberInviteModal from './WorkspaceMemberInviteModal.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'WorkspaceMemberSettings',
  components: {
    MembersTable,
    PendingEmailInvites,
    WorkspaceMemberInviteModal,
    Button
  },
  mixins: [PaginatorConsumer],
  data: () => ({
    activeMembers: [],
    totalActiveMemberCount: 0,
    activeMemberCount: 0,
    pendingMembers: [],
    seats: 0,
    currentTab: 'active',
    tabs: [
      {
        name: 'Active',
        key: 'active'
      },
      {
        name: 'Invited',
        key: 'pending'
      }
    ],
    loadingMembers: false,
    memberQuery: '',
    memberRoleFilter: '',
    inviteModalOpen: false
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'currentWorkspaceMember']),
    filteredPendingMembers() {
      return this.pendingMembers.filter((m) =>
        m.email.includes(this.memberQuery)
      )
    },
    membersByTab() {
      return {
        active: this.activeMembers,
        pending: this.filteredPendingMembers
      }
    },
    totalCountsByTab() {
      return {
        active: this.totalActiveMemberCount,
        pending: this.pendingMembers.length
      }
    },
    filteredCountsByTab() {
      return {
        active: this.activeMemberCount,
        pending: this.filteredPendingMembers.length
      }
    }
  },
  watch: {
    paginatorData: {
      handler(newVal) {
        this.activeMembers = newVal || []
      },
      deep: true
    },
    paginatorDataCount(newVal) {
      if (this.totalActiveMemberCount === 0) {
        this.totalActiveMemberCount = newVal || 0
      }
      this.activeMemberCount = newVal || 0
    }
  },
  async created() {
    this.seats = this.currentWorkspace.seats
    this.loadInvites()
    this.loadRoles()
  },
  methods: {
    ...mapActions([
      'getWorkspaceMembersPaginator',
      'setRoles',
      'getRoles',
      'setCurrentWorkspaceMember'
    ]),
    async handleLoadMore() {
      this.loadingMembers = true
      await this.loadPage()
      this.loadingMembers = false
    },
    async getPaginator() {
      this.loadingMembers = true
      const members = await this.getWorkspaceMembersPaginator({
        workspace_id: this.$route.params.workspace_id,
        extended: true,
        ...(this.memberQuery || this.memberRoleFilter
          ? {
              params: {
                ...(this.memberQuery ? { search: this.memberQuery } : {}),
                ...(this.memberRoleFilter
                  ? { role_id: this.memberRoleFilter }
                  : {})
              }
            }
          : {})
      })
      this.loadingMembers = false
      return members
    },
    async loadInvites() {
      try {
        this.pendingMembers = await getInvites(this.$route.params.workspace_id)
      } catch (e) {
        this.$console.debug('Error loading pending invites', e)
        this.$toast.error(e, 'loading pending invites')
      }
    },
    async loadRoles() {
      try {
        await this.getRoles({ workspace_id: this.$route.params.workspace_id })
      } catch (e) {
        this.$console.debug('Error loading roles', e)
        this.$toast.error(e, 'loading possible user roles')
      }
    },
    handleRemoveMember(member) {
      this.$dialogs.workspace.removeWorkspaceMemberDialog(
        this,
        this.currentWorkspace,
        member,
        () => {
          this.$toast.success(
            'Delete Successful',
            'The member has been deleted'
          )
          this.activeMembers = this.activeMembers.filter(
            (x) => x.uuid !== member.uuid
          )
          this.paginatorDataCount -= 1
          this.totalActiveMemberCount -= 1
        }
      )
    },
    async updateRoles({ roles, member }) {
      this.$console.debug('Updating roles of member', member, 'to role', roles)

      try {
        const newMember = await this.setRoles({
          workspace_id: this.$route.params.workspace_id,
          member_id: member.uuid,
          role_ids: roles.map((r) => r.uuid)
        })
        const memberIdx = this.activeMembers.findIndex(
          (m) => m.uuid === member.uuid
        )
        this.activeMembers[memberIdx] = newMember
        if (member.uuid === this.currentWorkspaceMember.uuid) {
          this.setCurrentWorkspaceMember({
            workspace_id: this.$route.params.workspace_id,
            member: newMember
          })
        }
        this.$toast.success(
          'Role updated',
          "The member's role has been updated"
        )
      } catch (e) {
        this.$console.debug('Error updating role', e)
        this.$toast.error(e, "updating the member's role")
      }
    },
    openInviteModal() {
      this.inviteModalOpen = true
    },
    closeInviteModal() {
      this.inviteModalOpen = false
    },
    sendInvites(invites) {
      this.closeInviteModal()
      this.pendingMembers = [
        ...this.pendingMembers.filter(
          (member) => !invites.some((i) => i.email === member.email)
        ),
        ...invites
      ]
    },
    async updateInvite({ roles, invite, reminder = false }) {
      try {
        await postInvite(
          this.$route.params.workspace_id,
          invite.email,
          roles.map((r) => r.uuid)
        )
        const idx = this.pendingMembers.findIndex((i) => i.id === invite.id)
        this.pendingMembers[idx] = {
          ...invite,
          roles
        }
        this.$toast.success(
          reminder ? 'Reminder sent' : 'Invite updated',
          reminder
            ? 'This person has been reminded of their pending uman invite.'
            : "The member's invite has been updated"
        )
      } catch (e) {
        this.$toast.error(
          e,
          `${reminder ? 'sending invite reminder' : 'updating reminder invite'}`
        )
      }
    },
    async deleteInvite(id) {
      try {
        await deleteInvite(this.$route.params.workspace_id, id)
        this.$toast.success('Invite revoked')
        this.pendingMembers = this.pendingMembers.filter(
          (invite) => invite.id !== id
        )
      } catch (e) {
        this.$toast.error(e, 'revoking the invite')
      }
    },
    handleSearch: debounce(function () {
      this.resetPaginatorConsumer()
    }, 500),
    handleFilter(roleId) {
      this.memberRoleFilter = roleId
      this.resetPaginatorConsumer()
    },
    handleClear() {
      this.memberQuery = ''
      this.resetPaginatorConsumer()
    }
  }
}
</script>

<style scoped lang="scss">
.members {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.25rem;

    &-title {
      font-weight: 700;
      font-size: 2.25rem;
      color: #303032;
    }

    &-add {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background: $primary;
      border-radius: 999rem;
      color: #fff;
      font-weight: 600;

      &:hover {
        background: darken($primary, 10%);
      }
    }
  }

  &-search {
    padding: 0.25rem 0.5rem;
    background: #f1f2f3;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);
    width: 50ch;

    &:focus,
    &:active,
    &:focus-visible,
    &:focus-within {
      border: 1px solid $primary;
      background: white;
    }

    &-clear {
      cursor: pointer;
      padding: 0.25rem;
      border-radius: 999rem;
      height: 1.5rem;
      width: 1.5rem;
      background: rgba(#000, 8%);

      &:hover {
        background: rgba(#000, 16%);
      }
    }

    &-wrapper {
      margin-top: 2.25rem;
      margin-bottom: 1rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-tabs {
    &-tab {
      &-label {
        font-weight: 700;
        color: #303032;
        padding-bottom: 0.5rem;
      }
    }
  }
}

::v-deep li.is-active * {
  color: $primary !important;
}

::v-deep .modal-content {
  width: min(90vw, 55rem);
}
</style>
