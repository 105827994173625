var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "general" },
    [
      _c("AvatarUpload", {
        attrs: {
          image: _vm.originalImageUrl || _vm.imageUrl,
          width: 512,
          "max-size": 5
        },
        on: {
          input: function(url, img) {
            _vm.imageUrl = url
            _vm.image = img
          }
        }
      }),
      _c("div", { staticClass: "general-block" }, [
        _c("p", { staticClass: "general-block-header" }, [
          _vm._v("Workspace name")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          staticClass: "general-block-input",
          attrs: { type: "text", placeholder: "Workspace name" },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        })
      ]),
      _c("div", { staticClass: "general-block" }, [
        _c("p", { staticClass: "general-block-header" }, [
          _vm._v("Description")
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.description,
              expression: "description"
            }
          ],
          staticClass: "general-block-input",
          attrs: { placeholder: "Describe your workspace" },
          domProps: { value: _vm.description },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.description = $event.target.value
            }
          }
        })
      ]),
      _c(
        "p",
        {
          staticClass: "general-confirm",
          class: { disabled: !_vm.isChanged },
          on: { click: _vm.onConfirm }
        },
        [_vm._v(" Update workspace info ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }