var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "results-wrapper" },
    [
      _c(
        "div",
        { staticClass: "results-header" },
        [
          _c("Button", {
            attrs: { icon: "arrow-left", type: "grey" },
            on: { click: _vm.handleBackClick }
          }),
          _c("PitchTemplatePublish", {
            attrs: { template: _vm.template },
            on: {
              publish: function() {
                return _vm.$emit("publish")
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "resultcontent",
          staticClass: "results",
          style: _vm.maxHeightStyle
        },
        [
          _c(
            "div",
            { staticClass: "results-content" },
            [
              _vm.loading && !_vm.error
                ? _c("MeetingContentLoading", {
                    staticClass: "results-loading",
                    attrs: { "custom-stages": ["Finishing up your results"] }
                  })
                : _vm.error
                ? _c("MeetingContentError", {
                    attrs: {
                      "custom-message":
                        "Something went wrong while generating output. Please try again."
                    },
                    on: { retry: _vm.handleRetry }
                  })
                : _c("div", { staticClass: "results-block" }, [
                    _c(
                      "div",
                      { staticClass: "results-block-text" },
                      [_c("HTMLRenderer", { attrs: { value: _vm.content } })],
                      1
                    )
                  ])
            ],
            1
          ),
          _c("CustomResultSidebar", {
            attrs: {
              "prev-template": _vm.template,
              meetings: _vm.meetings,
              "meetings-loading": _vm.meetingsLoading,
              "selected-meeting": _vm.selectedMeeting,
              disabled: _vm.loading
            },
            on: { meeting: _vm.selectMeeting, generate: _vm.loadResults }
          })
        ],
        1
      ),
      _c("ResourceModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }