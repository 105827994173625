<template>
  <div class="template-slides">
    <b-skeleton v-for="idx in 3" :key="`slide-loading-${idx}`" />
  </div>
</template>

<script>
export default {
  name: 'TemplateEditSlidesLoading',
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSelect(idx) {
      this.$emit('select', idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.template-slides {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1rem;
  border-left: 1px solid rgba(#000, 0.08);
  border-right: 1px solid rgba(#000, 0.08);
}

::v-deep .b-skeleton {
  aspect-ratio: 16 / 9;
  margin-top: 0;
  min-width: 100%;
  width: 100%;

  &-item {
    height: 100%;
  }
}
</style>
