<template>
  <Button
    :text="`${block.selections.length} ${typeName} template${
      block.selections.length === 1 ? '' : 's'
    }`"
    icon="slides"
    :icon-left="true"
    type="light-solid"
    size="xs"
    :full-width="true"
    @click="() => $emit('edit')"
  >
    <template #left>
      <Button icon="close" size="xxs" class="block-pill-button left" />
    </template>
    <template #right>
      <Button
        icon="close"
        type="grey"
        size="xxs"
        :loading="removeLoading"
        class="block-pill-button right"
        @click.native.stop="handleRemove"
      />
    </template>
  </Button>
</template>

<script>
import Button from '@c/library/Button'
import { removeKeyContentTemplateBlock } from '@/services/keyContentService'

export default {
  name: 'TemplateSlideBlockPill',
  components: { Button },
  props: {
    block: {
      type: Object,
      default: () => ({})
    },
    templateId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    removeLoading: false
  }),
  computed: {
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspiration'
      }[this.block.output_type]
    }
  },
  methods: {
    async handleRemove() {
      try {
        this.removeLoading = true
        await removeKeyContentTemplateBlock({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.templateId,
          block_id: this.block.uuid
        })
        this.$emit('remove', this.block)
      } catch (e) {
        this.$console.debug('Error removing block', e)
        this.$toast.error(e, 'removing this block')
      } finally {
        this.removeLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.block-pill {
  &-button {
    &.left {
      margin-right: auto;
      opacity: 0;
      pointer-events: none;
    }

    &.right {
      margin-left: auto;
    }
  }
}
</style>
