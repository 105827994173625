var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible, "can-cancel": ["escape", "outside"] },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "portfolio-resources" }, [
        _c(
          "div",
          { staticClass: "portfolio-resources-header" },
          [
            _c("p", { staticClass: "portfolio-resources-header-title" }, [
              _vm._v(
                " Generated content for " + _vm._s(_vm.template.name) + " "
              )
            ]),
            _c("Button", {
              attrs: { icon: "close", size: "xs", type: "grey" },
              on: { click: _vm.close }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "portfolio-resources-content" },
          [
            !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "portfolio-resources-content-filters" },
                  [
                    _vm._v(" Applied filters "),
                    !_vm.selectedFilters.length
                      ? _c("Tag", {
                          attrs: {
                            text: "All " + _vm.oriTypeName + "s",
                            type: "light"
                          }
                        })
                      : _vm._e(),
                    _vm._l(_vm.selectedFilters, function(filter, idx) {
                      return _c("Tag", {
                        key: "portfolio-publish-" + idx,
                        staticClass: "portfolio-resources-content-filters-tag",
                        class: { hidden: !_vm.filterText(filter) },
                        attrs: { text: _vm.filterText(filter), type: "light" }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            !_vm.loading && !_vm.error && _vm.resources.length
              ? _c("Table", {
                  attrs: {
                    items: _vm.resources,
                    headers: _vm.headers,
                    "max-height": "45vh"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          var header = ref.header
                          var index = ref.index
                          return [
                            header.id === "name"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "portfolio-resources-content-item resource-name"
                                  },
                                  [
                                    _vm.slidePreview(item)
                                      ? _c("img", {
                                          staticClass: "resource-name-preview",
                                          attrs: {
                                            src: _vm.slidePreview(item),
                                            alt: ""
                                          },
                                          on: {
                                            click: function() {
                                              return _vm.openPreview(
                                                item,
                                                index
                                              )
                                            }
                                          }
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "resource-name-generating"
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "resource-name-generating-icon",
                                              attrs: {
                                                src: require("@/assets/icons/ai.svg"),
                                                alt: ""
                                              }
                                            }),
                                            _vm._v(" Generating... ")
                                          ]
                                        ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.itemUrl(item),
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "resource-name-text" },
                                          [_vm._v(_vm._s(header.key(item)))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : header.id === "status"
                              ? _c("StatusOrb", {
                                  attrs: {
                                    type:
                                      header.key(item) === "published"
                                        ? "green"
                                        : "orange",
                                    text: header.key(item).capitalize(),
                                    "show-text": true
                                  }
                                })
                              : header.id === "reviewer"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "portfolio-resources-content-item resource-reviewer"
                                  },
                                  [
                                    _c("Avatar", {
                                      attrs: { user: item.reviewer }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "resource-reviewer-name" },
                                      [_vm._v(_vm._s(header.key(item)))]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "portfolio-resources-content-item resource-default"
                                  },
                                  [_vm._v(" " + _vm._s(header.key(item)) + " ")]
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2241948863
                  )
                })
              : _vm._e(),
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "portfolio-resources-content-loading" },
                  [
                    _c("b-loading", {
                      attrs: { active: "", "is-full-page": false }
                    })
                  ],
                  1
                )
              : _vm.error
              ? _c(
                  "div",
                  { staticClass: "portfolio-resources-content-error" },
                  [
                    _vm._v(
                      " Something went wrong while loading the content, please try again later "
                    ),
                    _c("Button", {
                      attrs: { text: "Retry", icon: "refresh", type: "white" },
                      on: { click: _vm.initState }
                    })
                  ],
                  1
                )
              : !_vm.resources.length
              ? _c(
                  "div",
                  { staticClass: "portfolio-resources-content-error" },
                  [_vm._v(" No content available ")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }