var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "template-sidebar" }, [
    _c("div", { staticClass: "template-sidebar-section" }, [
      _c("p", { staticClass: "template-sidebar-section-title" }, [
        _vm._v("Elements")
      ]),
      _c(
        "div",
        { staticClass: "template-sidebar-section-list" },
        _vm._l(_vm.sortedElements, function(element, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "template-sidebar-section-list-item",
              class: {
                selected: element.uuid === _vm.selected,
                hovered: element.uuid === _vm.hovered
              },
              on: {
                click: function() {
                  return _vm.$emit("select", element.uuid)
                },
                mouseenter: function() {
                  return _vm.$emit("hover", element.uuid)
                },
                mouseleave: function() {
                  return _vm.$emit("hover", "")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "template-sidebar-section-list-item-header" },
                [
                  _c(
                    "p",
                    { staticClass: "template-sidebar-section-list-item-name" },
                    [
                      _vm._v(
                        " " + _vm._s(element.content_type.capitalize()) + " "
                      )
                    ]
                  ),
                  element.prompt
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: "Test prompt",
                            type: "is-dark",
                            position: "is-left"
                          }
                        },
                        [
                          _c("Button", {
                            attrs: {
                              icon: "snippet-preview",
                              type: "light-solid"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return function() {
                                  return _vm.$emit("test", element)
                                }.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              !!element.prompt
                ? _c(
                    "p",
                    { staticClass: "template-sidebar-section-list-item-text" },
                    [_vm._v(" " + _vm._s(element.prompt) + " ")]
                  )
                : _vm._e()
            ]
          )
        }),
        0
      )
    ]),
    _c(
      "div",
      { staticClass: "template-sidebar-section" },
      [
        _c("p", { staticClass: "template-sidebar-section-title" }, [
          _vm._v("Template info")
        ]),
        _c("TextInput", {
          attrs: {
            title: "Name",
            placeholder: "Template name",
            disabled: !_vm.isEdit,
            "max-length": 50
          },
          model: {
            value: _vm.currentName,
            callback: function($$v) {
              _vm.currentName = $$v
            },
            expression: "currentName"
          }
        }),
        _c("TextInput", {
          attrs: {
            title: "Description",
            placeholder: "Describe your template",
            disabled: !_vm.isEdit
          },
          model: {
            value: _vm.currentDescription,
            callback: function($$v) {
              _vm.currentDescription = $$v
            },
            expression: "currentDescription"
          }
        }),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "template-sidebar-section-btns" },
              [
                _c("Button", {
                  attrs: {
                    text: "Save",
                    loading: _vm.infoLoading,
                    disabled:
                      _vm.currentName === _vm.name &&
                      _vm.currentDescription === _vm.description
                  },
                  on: {
                    click: function() {
                      return _vm.$emit(
                        "updateInfo",
                        _vm.currentName,
                        _vm.currentDescription
                      )
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }