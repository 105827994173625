<template>
  <div class="element-edit">
    <div class="element-edit-header">
      {{ isEdit ? 'Edit' : 'Inspect' }} element
      <Button
        icon="close"
        type="grey"
        size="xs"
        @click="() => $emit('close')"
      />
    </div>
    <div
      v-if="uuid && (outputType === 'reference' || isStoryTemplate)"
      class="element-edit-section"
    >
      <p class="element-edit-section-header">Element type</p>
      <SegmentedButton
        :text-left="element.content_type === 'text' ? 'Text' : 'Static image'"
        text-right="Client logo"
        :type-left="isLogo ? 'white' : 'light-solid'"
        :type-right="isLogo ? 'light-solid' : 'white'"
        :disabled="!isEdit"
        @click-left="() => (isLogo = false)"
        @click-right="() => (isLogo = true)"
      />
    </div>
    <div
      v-if="uuid && element.content_type === 'text'"
      class="element-edit-section"
    >
      <TextArea
        v-if="isEdit"
        v-model="prompt"
        :placeholder="promptPlaceholder"
        :disabled="isLogo"
        min-height="15vh"
        max-height="40vh"
        :max-length="1500"
      />
      <div v-else class="element-edit-section-text">
        <span class="bold">Prompt</span>
        {{ prompt || 'No prompt was configured for this element' }}
      </div>
    </div>
    <div
      v-if="uuid && element.content_type !== 'text' && !isEdit"
      class="element-edit-section"
    >
      <div class="element-edit-section-text">
        <span class="bold">Image</span>
        {{
          elementChanged ? '' : '- No changes were made to this image'
        }}
      </div>
    </div>
    <div v-if="isEdit" class="element-edit-footer">
      <p class="element-edit-sub">
        {{ imageFooter }}
      </p>
      <Button
        text="Save"
        :loading="loading"
        :disabled="!elementChanged"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextArea from '@c/library/TextArea.vue'
import SegmentedButton from '@c/library/SegmentedButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TemplateElementEdit',
  components: { SegmentedButton, TextArea, Button },
  props: {
    element: {
      type: Object,
      default: () => undefined
    },
    uuid: {
      type: String,
      default: ''
    },
    outputType: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    prompt: '',
    isLogo: false
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    isStoryTemplate() {
      return this.outputType === 'story'
    },
    elementChanged() {
      return (
        this.prompt !== this.element?.prompt ||
        this.isLogo !== !!this.element?.image
      )
    },
    promptPlaceholder() {
      if (this.isStoryTemplate)
        return 'E.g. "A description of the selected offerings, in bullet points"'
      const ori = {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspiration'
      }[this.outputType]
      return `E.g. "A short description of the ${ori}, maximum 5 words"`
    },
    imageFooter() {
      return this.outputType === 'reference' || this.isStoryTemplate
        ? `This element can be changed to the logo of your client for ${
            this.outputType === 'reference' ? 'case' : 'meeting'
          } templates. If you would like to do so, please select the client logo option.`
        : 'If you would like this element to be an image, it should be a part of the original presentation. We do give the option to insert the logo of your client for case templates. For those, please add a placeholder image or textbox where we should insert it.'
    }
  },
  watch: {
    uuid(val) {
      if (val) {
        this.isLogo = !!this.element.image
        this.prompt = this.element.prompt || ''
      }
    }
  },
  methods: {
    handleSave() {
      this.$emit('input', {
        ...(this.prompt !== this.element.prompt ? { prompt: this.prompt } : {}),
        ...(this.isLogo !== !!this.element.image
          ? { image: this.isLogo ? 'organisation_logo' : '' }
          : {})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.element-edit {
  background: white;
  border-radius: 6px 6px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: none;
  padding: 1rem 1.5rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &-section {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-header {
      font-weight: 600;
    }

    &-text {
      color: #60666b;

      & .bold {
        color: #303032;
        font-weight: 600;
        padding-right: 0.5rem;
      }
    }
  }

  &-sub {
    color: #60666b;
    margin-right: auto;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.5rem;
    justify-content: flex-end;
    align-items: center;
  }

  &-image {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 0.25rem;
    padding: 8px;
    border: 1px solid transparent;
    border-radius: 6px;

    &-img {
      width: 7.5rem;
      min-width: 7.5rem;
      object-fit: contain;
    }

    &-name {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
}
</style>
