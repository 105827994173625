<template>
  <b-modal
    active
    :can-cancel="['escape', 'outside']"
    @close="() => $emit('close')"
  >
    <div class="template-test">
      <div class="template-test-header">
        <span class="template-test-header-title">{{ title }}</span>
        <Button
          icon="close"
          type="grey"
          size="xs"
          @click="() => $emit('close')"
        />
      </div>
      <div
        class="template-test-content"
        :class="{ 'is-text': !isPresentationTest }"
      >
        <div class="template-test-content-sidebar">
          <div
            v-if="outputType !== 'story'"
            class="template-test-content-sidebar-search"
          >
            <TextInput
              v-model="query"
              :placeholder="`Find ${itemType}s`"
              :button-icon="query ? 'close' : ''"
              :submit-with-button="false"
              @submit="loadItems"
              @button-click="() => loadItems('')"
            />
          </div>
          <div v-if="items.length" class="template-test-content-sidebar-list">
            <div
              v-for="item in items"
              :key="item.uuid"
              class="template-test-content-sidebar-item"
              :class="{
                selected: selectedItem && selectedItem.uuid === item.uuid
              }"
              @click="() => selectItem(item)"
            >
              <img
                :src="getImage(item)"
                alt=""
                class="template-test-content-sidebar-item-image"
              />
              <p class="template-test-content-sidebar-item-name">
                {{ item.name || item.title }}
              </p>
              <Tag
                v-if="outputType === 'reference'"
                :text="item.confidentiality.capitalize()"
                :type="
                  item.confidentiality === 'internal' ? 'black' : 'light-solid'
                "
                :icon="`eye-${
                  item.confidentiality === 'internal' ? 'close' : 'open'
                }`"
                :icon-left="true"
                size="xxs"
                class="template-test-content-sidebar-item-conf"
              />
            </div>
          </div>
          <div
            v-if="!items.length"
            class="template-test-content-sidebar-status"
          >
            <img
              v-if="loadingItems || itemsError"
              :src="
                require(`@/assets/icons/${
                  loadingItems ? 'spinner' : 'check-warning'
                }.svg`)
              "
              alt=""
              class="template-test-content-sidebar-status-icon"
              :class="{ loading: loadingItems }"
            />
            <p class="template-test-content-sidebar-status-text">
              {{
                loadingItems
                  ? `Loading ${itemType}s...`
                  : itemsError
                  ? `Error loading ${itemType}s`
                  : `No ${itemType}s found, ${
                      outputType === 'story' || !query
                        ? 'please'
                        : 'try searching for something else or'
                    } create a new ${itemType} first.`
              }}
            </p>
            <Button
              v-if="itemsError"
              text="Retry"
              icon="refresh"
              :icon-left="true"
              type="white"
              @click="loadItems"
            />
          </div>
        </div>
        <div class="template-test-content-preview">
          <PresentationOutputPreview
            v-if="isPresentationTest"
            :output="preview"
            :loading="!!loadingPreview || loadingItems"
            :hide-regenerate="true"
          />
          <div
            v-else-if="
              preview && !loadingItems && !loadingPreview && !previewError
            "
            class="template-test-content-preview-text"
          >
            <p class="template-test-content-preview-text-title">
              Element content preview
            </p>
            <MarkdownEdit
              :value="preview"
              class="template-test-content-preview-text-preview"
            />
          </div>
          <div
            v-else-if="!!loadingPreview || loadingItems"
            class="template-test-content-preview-status"
          >
            <img
              :src="
                require(`@/assets/icons/${
                  isPresentationTest ? 'presentation' : 'snippet'
                }.svg`)
              "
              alt=""
              class="template-test-content-preview-status-icon"
            />
            <p class="template-test-content-preview-status-text">
              {{
                `Loading ${
                  isPresentationTest ? 'presentation' : 'element'
                } preview${loadingItems ? '' : ' for'}`
              }}
            </p>
            <p
              v-if="!loadingItems"
              class="template-test-content-preview-status-text bold"
            >
              {{ selectedItem.name || selectedItem.title }}
            </p>
            <LoadingDots animation="flicker" />
          </div>
          <div
            v-else-if="previewError"
            class="template-test-content-preview-status"
          >
            <img
              src="@/assets/icons/check-warning.svg"
              alt=""
              class="template-test-content-preview-status-icon"
            />
            <p class="template-test-content-preview-status-text">
              Error generating preview
            </p>
            <Button
              text="Retry"
              icon="refresh"
              :icon-left="true"
              type="white"
              @click="loadPreview"
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  testKeyContentTemplateShape,
  testKeyContentTemplate
} from '@/services/keyContentService'
import { getMeetings } from '@/services/meetingService'
import { mapActions } from 'vuex'
import Tag from '@c/library/Tag.vue'
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import LoadingDots from '@c/library/LoadingDots.vue'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'
import PresentationOutputPreview from '@/views-v2/meeting/outputs/preview/PresentationOutputPreview.vue'

export default {
  name: 'TemplateEditTest',
  components: {
    Tag,
    Button,
    TextInput,
    LoadingDots,
    MarkdownEdit,
    PresentationOutputPreview
  },
  props: {
    templateId: {
      type: String,
      default: ''
    },
    slideId: {
      type: String,
      default: ''
    },
    shapeId: {
      type: String,
      default: ''
    },
    prompt: {
      type: String,
      default: ''
    },
    outputType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loadingItems: false,
    itemsError: false,
    query: '',
    items: [],
    selectedItem: undefined,
    loadingPreview: '',
    previewError: false,
    preview: undefined
  }),
  computed: {
    isPresentationTest() {
      return !this.shapeId
    },
    itemType() {
      return (
        {
          offering: 'offering',
          reference: 'case',
          inspiration: 'inspiration',
          story: 'meeting'
        }[this.outputType] || ''
      )
    },
    title() {
      return `Test ${
        this.isPresentationTest ? 'presentation' : 'element'
      } on existing ${this.itemType}`
    }
  },
  async created() {
    this.preview = this.isPresentationTest ? {} : ''
    await this.loadItems()
    this.selectItem(this.items[0])
  },
  methods: {
    ...mapActions([
      'searchOfferings',
      'searchReferences',
      'searchInspirations'
    ]),
    getImage(item) {
      return (
        item.account?.organisation?.icon ||
        item.image ||
        require('@/assets/icons/globe.svg')
      )
    },
    async loadItems(query = undefined) {
      if (query !== undefined) this.query = query
      try {
        this.items = []
        this.itemsError = false
        this.loadingItems = true
        const searchFunction = {
          offering: this.searchOfferings,
          reference: this.searchReferences,
          inspiration: this.searchInspirations,
          story: getMeetings
        }[this.outputType]
        this.items = await searchFunction({
          workspace_id: this.$route.params.workspace_id,
          ...(this.outputType === 'story' ? {} : { search: this.query })
        })
      } catch (e) {
        this.itemsError = true
        this.$console.debug(
          `Error loading ${this.itemType}s for template preview`,
          e
        )
        this.$toast.error(e, `loading ${this.itemType}s`)
      } finally {
        this.loadingItems = false
      }
    },
    selectItem(item) {
      if (this.selectedItem?.uuid === item.uuid) return
      this.selectedItem = item
      this.loadPreview()
    },
    async loadPreview() {
      try {
        this.preview = this.isPresentationTest ? {} : ''
        this.previewError = false
        this.loadingPreview = this.selectedItem.uuid
        const testFunction = this.isPresentationTest
          ? testKeyContentTemplate
          : testKeyContentTemplateShape
        const res = await testFunction({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.templateId,
          output_type: this.outputType,
          output_id: this.selectedItem.uuid,
          ...(this.isPresentationTest
            ? {}
            : { slide_id: this.slideId, shape_id: this.shapeId })
        })
        if (this.loadingPreview !== this.selectedItem.uuid) return
        this.preview = this.isPresentationTest
          ? { presentation: res }
          : res.text
      } catch (e) {
        this.previewError = true
        this.$console.debug('Error generating preview', e)
        this.$toast.error(e, 'generating preview')
      } finally {
        this.loadingPreview = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-test {
  background: white;
  border-radius: 6px;
  width: min(100rem, 95vw);

  &-header {
    padding: 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    height: 70vh;
    max-height: 70vh;

    &.is-text {
      height: 45vh;
      max-height: 45vh;

      & .template-test-content-preview {
        overflow: auto;
        padding: 1.5rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
      }
    }

    &-sidebar {
      flex: 1;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-flow: column nowrap;
      border-right: 1px solid rgba(#000, 0.08);

      &-search {
        padding: 1rem;
      }

      &-list {
        flex: 1;
        overflow: auto;
      }

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.75rem;
        padding: 0.5rem 0.75rem;
        cursor: pointer;

        &.selected {
          background: rgba(#000, 0.06);
        }

        &:hover {
          background: rgba(#000, 0.04);
        }

        &-image {
          max-height: 1.75rem;
          max-width: 1.75rem;
          border-radius: 2px;
        }

        &-conf {
          margin-left: auto;
        }
      }

      &-status {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;

        &-icon {
          height: 1.75rem;
          filter: invert(41%) sepia(4%) saturate(760%) hue-rotate(165deg)
            brightness(93%) contrast(91%);

          &.loading {
            animation: spin 1s linear infinite;
          }
        }

        &-text {
          color: #60666b;
        }
      }
    }

    &-preview {
      flex: 3;
      height: 100%;
      max-height: 100%;
      background: #f1f2f3;

      &-text {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        width: min(100%, max(50%, 50rem));

        &.bold {
          font-weight: 600;
        }

        &-title {
          font-size: 1.25rem;
          font-weight: 600;
        }

        &-preview {
          padding: 0.5rem 1rem;
          border-radius: 6px;
          border: 1px dashed rgba(#000, 0.16);
          background: white;
        }
      }

      &-img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 4px;
        box-shadow: 0 0 4px 1px rgba(#000, 0.24);
      }

      &-status {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        &-icon {
          height: 1.75rem;
          filter: invert(41%) sepia(4%) saturate(760%) hue-rotate(165deg)
            brightness(93%) contrast(91%);
        }

        &-text {
          color: #60666b;
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
