<template>
  <b-modal v-if="template" active @close="() => $emit('close')">
    <div class="template-inspect">
      <div class="template-inspect-header">
        <p class="template-inspect-header-title">{{ template.name }}</p>
        <Button
          icon="close"
          size="xs"
          type="grey"
          @click="() => $emit('close')"
        />
      </div>
      <div class="template-inspect-content">
        <p
          v-if="template.description"
          class="template-inspect-content-description"
        >
          {{ template.description }}
        </p>
        <TextArea
          v-if="hasContent && fullTemplate"
          :value="fullTemplate.content"
          :disabled="true"
          min-height="15vh"
          max-height="40vh"
        />
        <div v-if="!hasContent" class="template-inspect-presentation">
          No prompt available for a presentation.
        </div>
        <div v-if="loading" class="template-inspect-loading">
          <b-loading :active="loading" :is-full-page="false" />
        </div>
      </div>
      <div class="template-inspect-footer">
        <Button text="Close" type="white" @click="() => $emit('close')" />
        <Button
          v-if="isCore"
          text="Duplicate"
          icon="copy-medium"
          :disabled="!hasContent"
          :icon-left="true"
          @click="() => $emit('duplicate')"
        />
        <Button
          v-else
          text="Edit"
          icon="edit"
          :icon-left="true"
          @click="() => $emit('edit')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextArea from '@c/library/TextArea.vue'
import { mapActions } from 'vuex'

export default {
  name: 'WorkspaceTemplateInspect',
  components: { Button, TextArea },
  props: {
    template: {
      type: Object,
      default: () => undefined
    }
  },
  data: () => ({
    loading: '',
    fullTemplate: undefined
  }),
  computed: {
    hasContent() {
      return this.template?.type !== 'presentation'
    },
    isCore() {
      return this.template?.provider === 'core'
    }
  },
  watch: {
    template: {
      handler(val) {
        if (val) this.loadTemplate()
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['getTemplate']),
    async loadTemplate() {
      if (!this.hasContent) return
      try {
        this.loading = true
        this.fullTemplate = await this.getTemplate({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid
        })
      } catch (e) {
        this.$console.debug('Error when loading template', e)
        this.$toast.error(e, 'loading the template')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-inspect {
  border-radius: 4px;
  background-color: white;
  width: min(35rem, 90vw);

  &-header {
    padding: 1.5rem 2.25rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  &-content {
    padding: 1.5rem 2.25rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &-description {
      color: #60666b;
    }
  }

  &-presentation {
    text-align: center;
    color: #60666b;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    background: rgba(#000, 0.02);
  }

  &-loading {
    position: relative;
    min-height: 10rem;
  }

  &-footer {
    padding: 1.5rem 2.25rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    border-top: 1px solid rgba(#000, 0.08);
    gap: 0.5rem;
  }
}
</style>
