<template>
  <div class="pitch-template">
    <div v-if="!loading && !error" class="pitch-template-content">
      <EditPresentationTemplate
        v-if="tool && tool.template_id"
        :template-id="tool.template_id"
        :tool="tool"
        @publish="handlePublish"
        @update="(t) => $emit('update', t)"
      />
      <EditDocumentTemplate v-else :template="tool" @publish="handlePublish" />
    </div>
    <div v-else-if="loading" class="pitch-template-status">
      <img
        src="@/assets/icons/spinner.svg"
        alt=""
        class="pitch-template-status-icon spinning"
      />
      Loading template info...
    </div>
    <div v-else class="pitch-template-status">
      <img
        src="@/assets/icons/check-warning.svg"
        alt=""
        class="pitch-template-status-icon"
      />
      Something went wrong while loading the template
      <Button text="Retry" type="white" @click="initTool" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { setRoutePadding } from '@/util'
import Button from '@c/library/Button.vue'
import EditDocumentTemplate from './EditDocumentTemplate.vue'
import EditPresentationTemplate from './EditPresentationTemplate.vue'

export default {
  name: 'EditPitchTemplate',
  components: {
    Button,
    EditDocumentTemplate,
    EditPresentationTemplate
  },
  data: () => ({
    loading: false,
    error: false,
    tool: undefined
  }),
  computed: {
    toolUuid() {
      let tabs = this.$route.params.tab
      tabs = Array.isArray(tabs) ? tabs : tabs?.split('/') || []
      return tabs[1] || undefined
    },
    isPresentation() {
      return !!this?.tool?.template_id
    }
  },
  created() {
    setRoutePadding(this.$router, this.$route, false)
    this.initTool()
  },
  beforeDestroy() {
    setRoutePadding(this.$router, this.$route, true)
  },
  methods: {
    ...mapActions(['getTemplate']),
    async initTool() {
      this.loading = true
      this.error = false
      this.tool = { uuid: this.toolUuid, loading: true }
      this.getTemplate({
        workspace_id: this.$route.params.workspace_id,
        template_id: this.toolUuid
      })
        .then((template) => {
          this.tool = template
        })
        .catch((e) => {
          this.$console.debug('Error while loading template', e)
          this.$toast.error(e, 'loading your template')
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePublish() {
      this.tool = {
        ...this.tool,
        status: this.tool.status === 'visible' ? 'hidden' : 'visible'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pitch-template {
  &-status {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 2rem;
    gap: 0.5rem;
    font-size: 1.5rem;
    color: #60666b;

    &-icon {
      width: 1.75rem;
      height: 1.75rem;
      opacity: 0.75;

      &.spinning {
        animation: spin 1s linear infinite;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
