<template>
  <Button
    :text="`${un}publish template`.capitalize()"
    :loading="loading"
    @click="handlePublish"
  />
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PitchTemplatePublish',
  components: { Button },
  props: {
    template: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    published() {
      return this.template?.status === 'visible'
    },
    un() {
      return this.published ? 'un' : ''
    }
  },
  methods: {
    ...mapActions(['editTemplate']),
    async handlePublish() {
      try {
        this.loading = true
        const status = this.published ? 'hidden' : 'visible'
        const res = await this.editTemplate({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.template.uuid,
          status
        })
        this.$toast.success(
          `${this.un}published template`,
          `Template has been ${this.un}published successfully.`
        )
        this.$emit('publish', res)
      } catch (e) {
        this.$console.debug('Error changing template status', e)
        this.$toast.error(e, `${this.un}publishing the template`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
