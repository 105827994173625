var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pitch-template" }, [
    !_vm.loading && !_vm.error
      ? _c(
          "div",
          { staticClass: "pitch-template-content" },
          [
            _vm.tool && _vm.tool.template_id
              ? _c("EditPresentationTemplate", {
                  attrs: {
                    "template-id": _vm.tool.template_id,
                    tool: _vm.tool
                  },
                  on: {
                    publish: _vm.handlePublish,
                    update: function(t) {
                      return _vm.$emit("update", t)
                    }
                  }
                })
              : _c("EditDocumentTemplate", {
                  attrs: { template: _vm.tool },
                  on: { publish: _vm.handlePublish }
                })
          ],
          1
        )
      : _vm.loading
      ? _c("div", { staticClass: "pitch-template-status" }, [
          _c("img", {
            staticClass: "pitch-template-status-icon spinning",
            attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
          }),
          _vm._v(" Loading template info... ")
        ])
      : _c(
          "div",
          { staticClass: "pitch-template-status" },
          [
            _c("img", {
              staticClass: "pitch-template-status-icon",
              attrs: {
                src: require("@/assets/icons/check-warning.svg"),
                alt: ""
              }
            }),
            _vm._v(" Something went wrong while loading the template "),
            _c("Button", {
              attrs: { text: "Retry", type: "white" },
              on: { click: _vm.initTool }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }